import React, { useEffect, useState } from "react";
import { Box, Button, Checkbox, FormControlLabel } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import "./UserPermissions.css";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SuccessIcon from "@mui/icons-material/CheckCircleOutline";
import { Get, Post } from "../../../../../../Common/WebRequest";
import { getStorageItem, getValue } from "../../../../../../Common/Utility";

const Region = ({
  regionPermission,
  onRegionChange,
  onUsersChange,
  onPaymentsChange,
  GroupId,
}) => {
  const allPropertiesSelected =
    regionPermission.usersEnabled && regionPermission.paymentsEnabled;
  const isIndeterminate =
    regionPermission.usersEnabled !== regionPermission.paymentsEnabled;
  //const adminGroupId = 90;

  return (
    <Box
      className={`RegionCard ${allPropertiesSelected ? "selected" : ""} ${
        isIndeterminate ? "indeterminate" : ""
      }`}
    >
      <fieldset style={{ border: "none" }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={allPropertiesSelected || isIndeterminate}
              onChange={(e) =>
                onRegionChange(
                  regionPermission.regionId,
                  e.target.checked,
                  "region"
                )
              }
              indeterminate={isIndeterminate}
              className="RegionCheckbox"
            />
          }
          label={<strong>{regionPermission.region.regionName}</strong>}
          className="RegionLabel"
        />
        <br />
        <FormControlLabel
          control={
            <Checkbox
              checked={regionPermission.usersEnabled}
              onChange={(e) =>
                onUsersChange(regionPermission.regionId, e.target.checked)
              }
              className={`PermissionCheckbox ${
                regionPermission.usersEnabled ? "selected" : ""
              }`}
            />
          }
          label="Users"
          className="PermissionLabel"
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={regionPermission.paymentsEnabled}
              onChange={(e) =>
                onPaymentsChange(regionPermission.regionId, e.target.checked)
              }
              className={`PermissionCheckbox ${
                regionPermission.usersEnabled ? "selected" : ""
              }`}
            />
          }
          label="Payments"
          className="PermissionLabel"
        />
      </fieldset>
    </Box>
  );
};

export default function UserPermissions(props) {
  const [permissions, setPermissions] = useState([]);
  const [error, setError] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [userIdCreate, setUserIdCreate] = useState(0);
  const [reload, setReload] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const AdminGroupId = 90;
  const [isDisabled, setIsDisabled] = useState(true);

  const disabledStyle = {
    pointerEvents: "none", // Prevents clicking
    opacity: 0.5, // Visually indicates the element is disabled
  };
  useEffect(() => {
    setError("");
    setSuccessMsg("");
    fetchPermissions(props.UserId, props.GroupId);
  }, [props.UserId, props.GroupId]);

  useEffect(() => {
    fetchPermissions(props.UserId, props.GroupId);
  }, [props.UserId, props.GroupId, reload, props.ReloadUserPermission]);

  //Show Enabled record
  useEffect(() => {
    setError("");
    setSuccessMsg("");
    setIsDisabled(props.GroupId === AdminGroupId || !props.IsActive);
  }, [props.IsActive, props.GroupId]);

  const fetchPermissions = async (userId, groupId) => {
    if (userId === 0 || groupId === 0) return;

    try {
      let userIdCreateValue = await getStorageItem("login", "userId");
      if (getValue(userIdCreateValue) === 0) {
        setError("User create Id = 0");
        return;
      }
      setUserIdCreate(userIdCreateValue);

      let res = await Get("UserPermission/" + userId);

      if (res && res.isSuccess && res.result) {
        setPermissions(res.result);
      } else {
        setError(res.Error);
      }
    } catch (error) {
      console.error(error); // Logging the error to the console for debugging
      if (error.Status === 403) {
        setError("Access Denied (403).");
      } else {
        setError(error.message || "An unexpected error occurred.");
      }
      setPermissions([]);
    }
  };

  const handleRegionChange = (regionId, isChecked) => {
    setPermissions(
      permissions.map((permission) => {
        if (permission.region.regionId === regionId) {
          return {
            ...permission,
            usersEnabled: isChecked,
            userId: props.UserId,
            userIdCreate: userIdCreate,

            paymentsEnabled: isChecked,
          };
        }
        return permission;
      })
    );
  };

  const handleUsersChange = (regionId, isChecked) => {
    setPermissions(
      permissions.map((permission) => {
        if (permission.region.regionId === regionId) {
          return {
            ...permission,
            userId: props.UserId,
            userIdCreate: userIdCreate,
            usersEnabled: isChecked,
          };
        }
        return permission;
      })
    );
  };

  const handlePaymentsChange = (regionId, isChecked) => {
    setPermissions(
      permissions.map((permission) => {
        if (permission.region.regionId === regionId) {
          return {
            ...permission,
            userId: props.UserId,
            userIdCreate: userIdCreate,
            paymentsEnabled: isChecked,
          };
        }
        return permission;
      })
    );
  };

  // Assuming you have a state variable like this

  async function handleFormSubmit() {
    if (permissions === null || permissions.length === 0) {
      setError("No Permissions!");
      return;
    }

    // Disable submit button
    setIsSubmitting(true);
    setError("");
    setSuccessMsg("");

    let res = await Post(
      "UserPermission/Update-Manager-Permissions/" + props.UserId,
      permissions
    );

    // Enable submit button

    if (res.isSuccess) {
      setReload(!reload);
      setSuccessMsg("Permissions successfully updated.");
      setError("");
      setIsSubmitting(false);
    } else {
      setError(res.Error);
      setSuccessMsg("");
    }
  }

  return (
    <>
      <h5 className="Title">
        User Permissions:{" "}
        <span
          style={{ color: props.GroupId === AdminGroupId ? "red" : "blue" }}
        >
          {" "}
          {props.FullName}
        </span>
      </h5>
      <div className="PermissionManager" hidden={permissions.length === 0}>
        <div
          className={`PermissionsContainer ${isDisabled ? "disabled" : ""}`}
          style={isDisabled ? disabledStyle : {}}
        >
          {permissions.length > 0 ? (
            permissions.map((permission, index) => (
              <Region
                key={index}
                regionPermission={permission}
                onRegionChange={handleRegionChange}
                onUsersChange={handleUsersChange}
                onPaymentsChange={handlePaymentsChange}
                //GroupId={props.GroupId}
              />
            ))
          ) : (
            <div></div>
          )}
        </div>
      </div>
      <div className="ButtonContainer">
        <Button
          disabled={props.FullName.length === 0 || isDisabled || isSubmitting}
          className="SaveButton"
          variant="contained"
          onClick={handleFormSubmit}
          endIcon={<ArrowForward />}
        >
          Save Permissions
        </Button>
      </div>
      <br />
      <div>
        {error && (
          <div className="alert alert-danger" role="alert">
            <HighlightOffIcon /> {error}
          </div>
        )}
        {successMsg && (
          <div className="alert alert-success" role="alert">
            <SuccessIcon /> {successMsg}
          </div>
        )}
      </div>
    </>
  );
}
