import * as React from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { Box, FormControl, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import CachedIcon from "@mui/icons-material/Cached";
import { useState } from "react";
import { useEffect } from "react";

import { Delete, Get } from "../../../../Common/WebRequest";
import {
  getStorageItem,
  getValue,
  setStorageItem,
} from "../../../../Common/Utility";
import { blue, red } from "@mui/material/colors";
import DropDownRegion from "../../../../components/DropDown/Region/DropDownRegion";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import ConfirmationDialogUI from "./ConfirmationDialogUI";
import DialogBoxWithYesNo from "../../../../components/Dialog/DialogBoxWithYesNo";
import columnsBuilder from "./columnsBuilder";
import UserDialog from "../../Management/ControlPanel/UserManagement/UserLogins/UserDialog";

export default function WaitingParents() {
  const [waitingParentsData, setWaitingParentsData] = useState([]);

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [confirmationDeleteDialogOpen, setConfirmationDeleteDialogOpen] =
    useState(false);
  const [selectedForApproval, setSelectedForApproval] = useState([]);
  const [operation, setOperation] = useState("");
  const [confTitle, setConfTitle] = useState("");
  const [confText, setConfText] = useState("");
  const [regionData, setRegionData] = useState([]);
  const [reload, setReload] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const stateWaitingUsers = 1;
  const isError = false;
  const [regionId, setRegionId] = useState(0);
  const [userId, setUserId] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  let withAll = true;

  useEffect(() => {
    async function fetchData() {
      try {
        const regionIdValue = await getStorageItem("payment", "regionId");
        const storedRegionId = getValue(regionIdValue);

        if (storedRegionId === 0) {
          setRegionId(-1);
        } else {
          setRegionId(storedRegionId);
        }
      } catch (error) {
        setError("Error by reading local storage.");
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    (async () => {
      if (getValue(regionId) === 0) {
        return;
      }
      let regions = await Get("Region/Permission-Users");
      if (regions.isSuccess) {
        setRegionData(regions.result);
      }
      let res = await Get(
        "User/UsersByState/" + regionId + "/" + stateWaitingUsers
      ); //
      setWaitingParentsData([]);
      if (res.isSuccess && regionData) {
        setWaitingParentsData(res.result);
        setError(null);
      } else {
        if (res.Status !== 404) setError(res.Status + "/" + res.Error);
      }
    })();
  }, [regionId, reload]);

  function handleRefresh() {
    setWaitingParentsData([]);

    setReload(!reload);
  }

  function DefinedToolbar() {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector>
            <GridToolbarDensitySelector />
          </GridToolbarDensitySelector>
          <GridToolbarExport
            csvOptions={{
              fileName: "Wartende Partner",
              delimiter: ";",
              utf8WithBom: true,
            }}
          />
          <GridToolbarQuickFilter />
          <Button
            color="primary"
            startIcon={<CachedIcon />}
            onClick={handleRefresh}
          >
            Refresh
          </Button>
        </GridToolbarContainer>
      </Box>
    );
  }

  async function handleRegionValue(e) {
    setRegionId((currentRegionId) => {
      return getValue(e.target.value);
    });

    setWaitingParentsData([]);
    setError("");
    await setStorageItem("payment", "regionId", e.target.value);
  }

  const handleApprove = () => {
    setSelectedForApproval(selectedForApproval);
    setConfirmationDialogOpen(true);
  };

  const handleConfirmApprove = () => {
    if (!isError) {
      setReload(!reload);
    }
    setConfirmationDialogOpen(false);
    setReload(!reload);
  };

  const handleCloseConfirmationDialog = () => {
    setConfirmationDialogOpen(false);
    setReload(!reload);
  };

  const handleDelete = () => {
    setOperation("Löschen");
    setConfTitle("Bestätigung löschen");
    setConfText("Bitte bestätigen Sie die Löschung der ausgewählten Benutzer.");
    setSelectedForApproval(selectedForApproval);
    setConfirmationDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (!isError) {
      // doDelete
      await doDeleteUserApi();
      setReload(!reload);
    }
    setConfirmationDeleteDialogOpen(false);
  };
  const handleCloseConfirmationDeleteDialog = () => {
    setConfirmationDeleteDialogOpen(false);
    setReload(!reload);
  };

  const doDeleteUserApi = async () => {
    for (const userId of selectedForApproval) {
      var res = await Delete("User/" + userId);
      if (!res.isSuccess) {
        setError(res.Error);
        return;
      } else {
        setSuccess("Der ausgewählte Benutzer wurde gelöscht.");
      }
    }
  };

  function handleOnRowClick(params) {
    const clickedRow = params.row;
    if (clickedRow) {
      //setSelectionModel(clickedRow.userId);
      // setLastSavedUserId(0);
    }
  }
  async function handleOnRowDoubleClick(params, event) {
    if (params.row) {
      setUserId(params.row.userId);
      handleAddUpdateUser(params.row.userId);
    }
  }

  /// Add Update Open Teacher Dialog
  const handleOpenDialog = () => {
    //setReload(!reload);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  async function handleAddUpdateUser(userId) {
    setError("");
    //setSuccessMsg("");
    if (userId === 0) {
      setUserId(0);
      handleOpenDialog();
    } else if (userId > 0) {
      handleOpenDialog();
    }
  }
  const handleSaveUserData = async (userIdSaved) => {
    setReload(!reload);
    //console.log("--> user id saved: " + userIdSaved);
    // setLastSavedUserId(userIdSaved);
  };
  return (
    <>
      <br />
      <Box
        display="flex"
        flexDirection="row"
        overflow="auto"
        alignItems="center"
      >
        <UserDialog
          open={isDialogOpen}
          onClose={handleCloseDialog}
          onSave={handleSaveUserData}
          reload={reload}
          IsForPermission={false}
          UserId={userId}
          isUserStateReviewOnly={true}
        />
        <Box>
          <Typography
            variant="headline"
            component="h3"
            align="left"
            paddingLeft={"10px"}
            color={blue[500]}
          >
            Wartende Partner
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          sx={{ marginRight: 2 }}
        >
          <Typography
            variant="subtitle1"
            color={blue[500]}
            fontWeight="Bold"
            sx={{ marginLeft: 7, marginRight: 2 }}
          >
            Region
          </Typography>
          <FormControl>
            <DropDownRegion
              permission="Permission-Users"
              regionID={regionId}
              onChange={handleRegionValue}
              withAll={withAll}
              // onPopulate={handleOnPopulate}
            />
          </FormControl>
        </Box>
      </Box>
      <br />
      <Typography
        variant="caption"
        component="h6"
        align="left"
        color={red.A400}
      >
        {error}
      </Typography>

      <Box
        display="block"
        flexDirection="row"
        alignItems="center"
        height="500px"
        overflow="auto"
      >
        <DataGrid
          sx={{
            "& .MuiDataGrid-row": {
              // Targeting all rows
              cursor: "pointer", // Change cursor to pointer
            },
          }}
          // apiRef={apiRef}
          columns={columnsBuilder({ regionData })}
          checkboxSelection
          //onSelectionModelChange={handleSelectionModelChange}
          // onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
          rowSelectionModel={selectedForApproval}
          onRowDoubleClick={handleOnRowDoubleClick}
          onRowSelectionModelChange={(newSelection) => {
            setSelectedForApproval(newSelection);
          }}
          disableRowSelectionOnClick
          initialState={{
            ...waitingParentsData.initialState,
            columns: {
              ...waitingParentsData.initialState?.columns,
              columnVisibilityModel: {
                userId: false,
              },
            },
            filter: {
              filterModel: {
                items: [{ field: "fullName", operator: "contains", value: "" }],
              },
            },
          }}
          slots={{ toolbar: DefinedToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          density="compact"
          rows={waitingParentsData}
          getRowId={(row) => row.userId}
        />
      </Box>
      <Box
        display="block"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-end"
        marginTop={2}
      >
        <Button
          variant="outlined"
          color="success"
          disabled={selectedForApproval.length === 0}
          onClick={handleApprove}
          sx={{ textTransform: "capitalize" }}
          endIcon={<DoneIcon />}
        >
          Approve
        </Button>

        <Button
          variant="outlined"
          color="error"
          disabled={selectedForApproval.length === 0}
          onClick={handleDelete}
          sx={{ ml: "10px", textTransform: "capitalize" }}
        >
          Delete
        </Button>
        {confirmationDialogOpen && (
          <ConfirmationDialogUI
            open={confirmationDialogOpen}
            onClose={handleCloseConfirmationDialog}
            onConfirm={handleConfirmApprove}
            selectedRows={selectedForApproval}
            isError={isError}
          />
        )}
        {confirmationDeleteDialogOpen && (
          <DialogBoxWithYesNo
            open={confirmationDeleteDialogOpen}
            onClose={handleCloseConfirmationDeleteDialog}
            onYes={handleConfirmDelete}
            title={confTitle}
            text={confText}
            operationText={operation}
          />
        )}
      </Box>
    </>
  );
}
