import { Container, Paper, Typography } from '@mui/material';
import React from 'react'
import EmailIcon from "@mui/icons-material/Email";

const RegistrationExpiredWithNewToken = () => {
    return (
        <Container component="main" maxWidth="sm" sx={{
            display: 'flex',         
            height: '70vh',         
            alignItems: 'center',    
            justifyContent: 'center', 
            backgroundColor: 'white'
        }}>
         <Paper elevation={6} sx={{ 
              p: 4, 
              bgcolor: 'background.paper', 
              color: 'text.primary', 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center', 
              justifyContent: 'center'   }}
              >

          <EmailIcon sx={{
              fontSize: 40,
              color: "gray",
              mt: 3,
              mb: 2   
            }} />
            <Typography variant="h4" component="div" gutterBottom sx={{ color: 'error.main' }}>
              Achtung
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Ihr vorheriger Bestätigungslink ist abgelaufen, da Sie ihn nicht innerhalb von <strong>5 Minuten</strong> aktiviert haben.
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Wir haben Ihnen eine neue E-Mail geschickt – bitte überprüfen Sie diese sofort und klicken Sie auf <strong>"Registrierung bestätigen"</strong>, um Ihre Registrierung abzuschließen.
            </Typography>

            <Typography variant="body1"  >
            Vielen Dank für Ihr Verständnis.
            </Typography>
          </Paper>
        </Container>
      );
}

export  default RegistrationExpiredWithNewToken;
