import React, { useEffect, useState } from "react";
import {
  FaSync,
  FaUsers,
  FaFileInvoiceDollar,
  FaFileAlt,
  FaCalendarAlt,
} from "react-icons/fa";
import "./TopHeaderPage.css";
import { useNavigate } from "react-router-dom";
import { Get } from "../../../../Common/WebRequest";
import { clearCacheData } from "../../../../Common/Utility";

const DashboardCard = ({
  value,
  label,
  icon,
  iconColor,
  backgroundColor,
  cardWidth,
  onClick,
}) => {
  return (
    <div
      className="card"
      style={{ width: cardWidth, background: backgroundColor }}
      onClick={onClick}
    >
      <div className="icon-container" style={{ color: iconColor }}>
        {icon}
      </div>
      <div className="value">{value}</div>
      <div className="labelCard">{label}</div>
    </div>
  );
};

const TopHeaderPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [summaryData, setSummaryData] = useState({
    todayDate: "-",
    todayName: "-",
    usersCount: "-",
    newUsersCount: "-",
    waitingPaymentCount: "-",
    lastPaidYearMonth: "Monat.",
    lastPaidYearMonthAmount: "-",
    recentlyPaidCount: "-",
    recentlyPaidDate: "-",
  });
  const [error, setError] = useState("");

  useEffect(() => {
    (async () => {
      try {
        setError("");
        const res = await Get("SummeryReports/summary-report");

        if (res.isSuccess) {
          const data = res.result;
          setSummaryData(data);
        } else {
          setError(res.Error);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error.Error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!summaryData) {
    if (error && error.length > 0) {
      return (
        <div
          style={{
            color: "red",
            fontWeight: "bold",
            textAlign: "center",
            marginTop: "10px",
          }}
        >
          {error}
        </div>
      );
    }
    //return <div>No data available</div>;
  }

  function handleRefresh(e) {
    clearCacheData();
    window.location.reload(true);
    //navigate(0);
  }

  return (
    <div className="dashboard-container">
      <DashboardCard
        value={summaryData.todayDate}
        label={summaryData.todayName}
        //label="Aktualisieren"

        icon={<FaSync />}
        iconColor="#00bfa0"
        cardWidth="150px"
        onClick={(e) => handleRefresh(e)}
      />

      {summaryData.newUsersCount > 0 && (
        <DashboardCard
          value={summaryData.newUsersCount}
          label="Wartende Partner"
          iconColor="green"
          cardWidth="150px"
          backgroundColor="#cfc"
          icon={<FaUsers />}
          onClick={(e) => navigate("/waitingpartners")}
        />
      )}

      <DashboardCard
        value={summaryData.usersCount}
        label="Partner"
        iconColor="orange"
        cardWidth="150px"
        icon={<FaUsers />}
        onClick={(e) => navigate("/partners")}
      />
      <DashboardCard
        value={summaryData.waitingPaymentCount}
        label="Wartende Zahlungen"
        cardWidth="180px"
        icon={<FaFileAlt />}
        iconColor="#0d88e6"
        onClick={(e) => navigate("/waitingpayments")}
      />
      <DashboardCard
        value={summaryData.lastPaidYearMonthAmount}
        label={summaryData.lastPaidYearMonth}
        iconColor="green"
        cardWidth="150px"
        icon={<FaFileInvoiceDollar />}
      />
      <DashboardCard
        value={summaryData.recentlyPaidCount}
        cardWidth="150px"
        iconColor="#0d88e6"
        label="Kürzlich bezahlt"
        icon={<FaFileAlt />}
      />
      <DashboardCard
        value={summaryData.recentlyPaidDate}
        label="Zuletzt bezahltes Datum"
        iconColor="#5ad45a"
        icon={<FaCalendarAlt />}
        cardWidth="200px"
      />
    </div>
  );
};

export default TopHeaderPage;
