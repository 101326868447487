import React from "react";
import { Typography, Box, Button, Card, CardContent } from "@mui/material";
import { blue, green } from "@mui/material/colors";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import { useNavigate } from "react-router-dom";

const RegistrationDone = () => {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate("/login");
  };
  return (
    <>
      <Box sx={{ maxWidth: 700, mx: "auto", p: 2, mt: 8 }}>
        <Card
          variant="outlined"
          sx={{
            mb: 4,
            boxShadow: 3,
            border: "1px solid #e0e0e0",
            borderRadius: 2,
          }}
        >
          <CardContent>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mb: 3,
              }}
            >
              <CheckCircleOutlineIcon
                sx={{
                  fontSize: 60,
                  color: green[500],
                  marginRight: 2,
                  marginTop: 1,
                }}
              />
              <Typography
                variant="h4"
                color={green[500]}
                gutterBottom
                sx={{ mt: 3, fontFamily: "tahoma" }}
              >
                Vielen Dank für die Bestätigung der Registrierung!
              </Typography>
            </Box>
             
            <Typography
              variant="body1"
              gutterBottom
              sx={{ ml: 5, fontFamily: "tahoma" }}
            >
              * Wir werden Ihre Informationen überprüfen. Dieser Vorgang kann
              einige Zeit dauern.
            </Typography>
            <Typography variant="body1" sx={{ ml: 5, fontFamily: "tahoma" }}>
              * Sie werden benachrichtigt, sobald die Überprüfung abgeschlossen
              ist.
            </Typography>
            <Typography
              variant="body1"
              sx={{ textAlign: "center", mt: 5, fontFamily: "tahoma" }}
            >
              Vielen Dank für Ihre Geduld!
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mt: 3,
              }}
            >
              <HourglassEmptyIcon
                sx={{ fontSize: 30, color: blue[500], marginRight: 1 }}
              />
              <Typography variant="body2" color={blue[500]} fontFamily="tahoma">
                Überprüfung in Bearbeitung...
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 6 }}>
              <Button
                variant="contained"
                sx={{ textTransform: "none" }}
                color="primary"
                onClick={handleButtonClick}
              >
                Zurück zur Anmeldung
              </Button>
            </Box>

            
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default RegistrationDone;
