export const getCoordinatesForAddress = async (
  apiKey,
  address,
  regionName,
  zip
) => {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
    address
  )}&key=${apiKey}`;

  try {
    if (
      zip === undefined ||
      zip === null ||
      zip === "" ||
      zip <= 0 ||
      zip.toString().length < 5
    ) {
      return { lat: 0, lng: 0, error: "PLZ fehlt" };
    }
    const response = await fetch(url);
    const data = await response.json();

    // Check if the API call was successful
    if (data.status !== "OK") {
      return { error: "Geokodierungs-fehler: " + data.status };
    }

    // Proceed only if there are results
    if (data.results.length === 0) {
      return { error: "Keine Ergebnisse gefunden." };
    }
    if (!data.results[0].formatted_address.includes(zip)) {
      return {
        error: "PLZ stimmt nicht? => " + data.results[0].formatted_address,
      };
    }

    // Typically, the most relevant result is returned first
    const firstResult = data.results[0];

    const { lat, lng } = firstResult.geometry.location;

    return { lat, lng };
  } catch (error) {
    console.error("Failed to fetch coordinates:", error);
    return { error: error.message };
  }
};
