import { Post } from "./Common/WebRequest";

async function handleRefreshToken() {
  try {
    const loginData = await JSON.parse(localStorage.getItem("login"));
    //TODO check if seassionId =''  then delte login from storage
    console.log(new Date().toUTCString() + ": Token expired, Refreshing......");

    const accessToken = loginData?.accessToken;
    const refreshToken = loginData?.refreshToken;
    var request = { accessToken: accessToken, refreshToken: refreshToken };
    const response = await Post("Auth/Refresh-Token", request);

    console.log(new Date().toUTCString() + ": Getting new Token: ", response);

    if (response.isSuccess) {
      localStorage.setItem(
        "login",
        JSON.stringify({
          isLogin: true,
          accessToken: response.result.accessToken,
          refreshToken: response.result.refreshToken,
          environment: response.result.environment,
          userName: response.result.fullName,
          userId: response.result.userId,
          isAdmin: response.result.isAdmin || response.result.userGroup > 10,

          //loginDataIdentifier: loginDataIdentifier,
        })
      );
    } else {
      console.log("Token..expired");
      localStorage.removeItem("login");
      window.location.href = "/LoginPage";
      return null;
    }
  } catch (error) {
    console.error(
      "Token refresh failed! Please log in again.",
      error.toString()
    );
  }
}

export default handleRefreshToken;
