import React, { useState } from "react";
import {  Container,   Paper    } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getParamValueFromURL } from "../../../Common/Utility";
import { Post } from "../../../Common/WebRequest";
 
 

const RegistrationTokenValidation = () => {
 
  const [registrationError, setRegistrationError] = useState("");
 
  const[tokenIsValid,setTokenIsValid]= useState(false)
  const[registrationIsConfirmed,setRegistrationIsConfirmed]=useState(false)

  const navigate = useNavigate();

  const token = getParamValueFromURL(window.location.search, "token");

  useEffect(() => {
    const confirmRegistration = async () => {
      if (tokenIsValid) {
        //navigate("/RegistrationDone");
        let tokenData = {
          token: token,
          
        };
        let res = await Post(`Register/Confirm-Registration`, tokenData);
  
        if (res.isSuccess) {
          setRegistrationIsConfirmed(true);
          navigate("/RegistrationDone"); 
        }
      else
         {
         
          if(res.Error.includes("Token has expired"))
            {
              navigate(`/RegistrationExpiredWithNewToken`);
             //s setRegistrationError("Token is expired. Please try again with new registration link.");
            }
            else 
            { 
             setRegistrationError("Error by Confirm-Registration:"+res.Error);
              setRegistrationIsConfirmed(false);
            }
        }
      }     
    };
  
    confirmRegistration();
  }, [tokenIsValid,token, navigate]);
  
  
  useEffect(() => {
    const fetchData = async () => {
      // Redirect to login if accessToken is not provided or empty
      if (!token || token.trim() === "" || token.length < 10) {
        navigate("/login");
      } else {
        try {
          let result = await Post("Register/ValidateToken", {
            token: token,
          });
            if(result.isSuccess)
            {
                setTokenIsValid(true);
            }
          else {
                      
            console.error(result.Error);                       
            navigate(`/RegistrationTokenInvalid/${result.Error}`); 
            setTokenIsValid(false)
            setRegistrationError(result.Error);
             
            //navigate("/RegistrationTokenInvalid/"+result.Error);
          }
        } catch (error) {
          console.error("Error validating token:", error);
        }
      }
    };

    fetchData();
  }, [token, navigate]);


  if (!token || token.trim() === "" || token.length < 10) {
    return null; // To avoid rendering anything in this case
  }
     
  return (
    <Container component="main" maxWidth="xs">
      <Paper
        elevation={3}
        style={{ padding: "2em", marginTop: "8vh", textAlign: "center" }}
      >
        <label>Verifying the link.......</label> 
        <br/>
        <label style={{color:'red'}}>{registrationError && registrationError.length>0? (registrationError):("")}</label>
      </Paper>
    </Container>
  );
};

export default RegistrationTokenValidation;
