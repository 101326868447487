import { isDate, toJsonDateTimeWithoutMilsec } from "../../../../Common/Utility";

const columnsBuilder = (props) => {
const columns = [
    //{ field: "id", headerName: "ID", width: 90 },
    { field: "userName", headerName: "User Name", width: 230 },
    {
      field: "loginTime",
      headerName: "Login Time",
      width: 150,
      type: "text",
      valueFormatter: function (params) {
        if (isDate(params.value)) {
          return toJsonDateTimeWithoutMilsec(params.value);
        }
      },
    },
   
    { field: "ipAddress", headerName: "IP Address", width: 130 },
    { field: "browser", headerName: "Browser", width: 130 },
    { field: "operatingSys", headerName: "O.S", width: 90 },
    { field: "countryName", headerName: "Location", width: 130 },
    { field: "deviceType", headerName: "Device Type", width: 120 },
    { field: "screenResolution", headerName: "Resolution", width: 100 },
    {
      field: "viewPortDimensions",
      headerName: "V-Dimensions",
      width: 180,
    },
    { field: "userLanguage", headerName: "Language", width: 100 },
    { field: "userDateFormat", headerName: "Date Format", width: 150 },
  ];
  return columns;
}
export default columnsBuilder;