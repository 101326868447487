// Ticker.js
import React, { useEffect, useState } from "react";
import "./Ticker.css";
import { getStorageItem } from "../../../../Common/Utility";
import { Get } from "../../../../Common/WebRequest";

const Ticker = () => {
  const [userName, setUserName] = useState("");
  const [headlinesData, setHeadlinesData] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        setUserName(await getStorageItem("login", "userName"));
      } catch (error) {
        console.error("Error fetching UserName: ", error);
      }
    })();
  }, []);
  useEffect(() => {
    (async () => {
      try {
        setHeadlinesData([]);
        if (!userName) return;
        //const res = await Get(`SystemUpdate/ShowOnTitle`);
        const res = await Get(`SystemUpdate/IsActive/${userName}/true`);

        if (res.isSuccess) {
          const dataAllUpdates = res.result;
          const filteredItems = dataAllUpdates.filter((item, index) => {
            return !item.seenByUsers.includes(userName.toLowerCase());
          });

          let autoNumber = 0;
          const filteredDescriptions = filteredItems.map((item) => {
            autoNumber++;
            return `(${autoNumber}) ${item.description}`;
          });

          // Check if there are more than 2 items
          if (filteredDescriptions.length > 2) {
            // Get the first two items and update them
            const limitedDescriptions = [
              `Last update: ${filteredDescriptions[0].substring(
                filteredDescriptions[0].indexOf(")") + 2
              )}`,
              `${filteredDescriptions[1].substring(
                filteredDescriptions[1].indexOf(")") + 2
              )}`,
              " ....more...",
            ];
            setHeadlinesData(limitedDescriptions);
            console.log(limitedDescriptions);
          } else {
            setHeadlinesData(filteredDescriptions);
          }
        }
      } catch (error) {
        console.error("Error fetching SystemUpdate..data:", error);
      }
    })();
  }, [userName]);

  return (
    <div className="ticker-container">
      <div
        className="ticker"
        style={{ justifyContent: "center", marginRight: "100px" }}
      >
        {headlinesData.map((headline, index) => (
          <div key={index} className="ticker-item">
            {headline}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Ticker;
