//import "./App.css";

import MainRouter from "./MainRouter";
import NavSideBar from "./components/SideBars/NavSideBar";
import TitleBar from "./components/NavBars/TitleBar";
import { useEffect, useState } from "react";
import handleRefreshToken from "./AppHandleRefreshToken";
import { clearCacheData } from "./Common/Utility";

const loginData = JSON.parse(localStorage.getItem("login"));

const TwoHour = 2 * 60 * 60 * 1000; // Refresh Token
//const TwoHour = 10 * 1000; // Refresh Token

//const Sec10 = 6 * 1000; // Refresh Token

function App() {
  const [marginLeft, setMarginLeft] = useState("295px");
  const [width, setWidth] = useState("291px");
  const [userLogout, setUserLogout] = useState(false);

  // Function to clear complete cache data

  const handleNavToggle = (isLogout) => {
    setUserLogout(isLogout);
    if (isLogout) {
      setMarginLeft("0px");
      setWidth(0);

      return;
    }
    if (marginLeft === "295px") {
      //setMarginLeft("103px");
      //setWidth("90px");

      setMarginLeft("0px");
      setWidth("0px");
    } else {
      setMarginLeft("295px");
      setWidth("291px");
    }
  };

  useEffect(() => {
    (async () => {
      clearCacheData();
      if (loginData && loginData.accessToken !== "") {
        setInterval(handleRefreshToken, TwoHour);
      }
    })();
  }, []);

  if (!loginData || loginData.token === "" || loginData.isLogin === false) {
    return (
      <div className="App">
        <div style={{ marginLeft: "1px" }}></div>
        <NavSideBar width="0px" />
        {/* <LoginPage onNavToggle={handleNavToggle} /> */}
        <div style={{ paddingLeft: "0px" }}>
          <MainRouter isLoggedIn={false} />
        </div>
      </div>
    );
  } else {
    console.log("Logged");
    return (
      <>
        <div className="App">
          <div>
            <NavSideBar width={width} />
          </div>
          <div
            style={{
              marginLeft: marginLeft,
              marginRight: "1px",
              paddingLeft: "1px",
            }}
          >
            <TitleBar onNavToggle={handleNavToggle} />
            <div style={{ paddingLeft: "15px" }}>
              <MainRouter isLoggedIn={loginData.isLogin} />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default App;
