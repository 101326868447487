import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbarContainer,   GridToolbarQuickFilter } from "@mui/x-data-grid";
import { Get } from "../../../../Common/WebRequest";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SyncIcon from "@mui/icons-material/Sync";
import  DownloadIcon from '@mui/icons-material/FileDownload';
import * as XLSX from "xlsx";
import { getBaseUrl, getFileNameWithoutExtension, getStorageItem } from "../../../../Common/Utility";
import { apiUrl } from "../../../../config";
 

const UserPayments = ({ state,handleButtonClick,currentYear}) => {
  

  const [paymentsData, setPaymentsData] = useState([]);
  const [error, setError] = useState('');
  const[userId,setUserId]=useState(0); 
   
  let navigate = useNavigate();

  // Calculate total sum of 'totalAmount' column
  const totalAmountSum = paymentsData.reduce((sum, row) => sum + row.totalAmount, 0);
  const columns = [
    
    {
      field: "paymentStateName",
      headerName: "State",
      //width: 150,
      flex: 1,
      type: "text",
      renderCell: (params) => (
        <div>
          {params.row.paymentStateName.toString().toLowerCase() === "paid" ? (
            <span style={{ minWidth: '100px',display: "inline-block", textAlign :"center" , color: "green", background: "#cfc", padding: "6px", borderRadius: "10px"  }}>Bezahlt</span>

          ) : params.row.paymentStateName.toString().toLowerCase() === "approved" ? (
            <span style={{minWidth: '100px',display: "inline-block",textAlign :"center" , color: "blue", background: "#ffd966", padding: "6px", borderRadius: "10px" }}>Genehmigt</span>
          ) : params.row.paymentStateName.toString().toLowerCase() === "waiting" ? (
            <span style={{minWidth: '100px',display: "inline-block" ,textAlign :"center", color: "white", background: "#ffaf8f", padding: "6px", borderRadius: "10px" }}>Wartend</span>
          ) : (
            <span style={{minWidth: '100px',display: "inline-block",textAlign :"center" , color: "black", background: "#cdf5fd", padding: "6px", borderRadius: "10px" }}>{params.row.paymentStateName}</span>
          )}
        </div>
      ),
    }
    ,
    { field: "paymentId", headerName: "ID",
     //width: 100,
     flex: 1,
       hide: true },
    { field: "yearMonth", headerName: "Date",  type: "text", width: 100 },     
    {
      field: "file",
      headerName: "Files",
      type: "text",
      width: 250,
      //flex: 1,
      renderCell: (params) => (
        <div>
          <Typography  >
            {params.row.calculationsFile && (
              <button
                onClick={() => openFileWindow(params.row.calculationsFile, params.row.paymentId)}
                style={{
                  textDecoration: 'none',
                  border: 'none',
                  background: 'none',
                  cursor: 'pointer',
               
                  color: 'darkslateblue',  
                }}
                onMouseEnter={(e) => e.target.style.color = 'blue'} 
                onMouseLeave={(e) => e.target.style.color = 'darkslateblue'}  
              >
                {getFileNameWithoutExtension(params.row.calculationsFile)}
              </button>
            )}
            {params.row.calculationsFile &&  <br />}
            {params.row.timesheetsFile && (
              <button
                onClick={() => openFileWindow(params.row.timesheetsFile, params.row.paymentId)}
                style={{
                  textDecoration: 'none',
                  border: 'none',
                  background: 'none',
                  cursor: 'pointer',
                 
                  color: 'darkslateblue',  
                }}
                onMouseEnter={(e) => e.target.style.color = 'blue'}  
                onMouseLeave={(e) => e.target.style.color = 'darkslateblue'}  
              >
                {getFileNameWithoutExtension(params.row.timesheetsFile)}
              </button>
            )}
             {params.row.timesheetsFile &&  <br />}
            {params.row.timesheetsFileLater && (
              <button
                onClick={() => openFileWindow(params.row.timesheetsFileLater, params.row.paymentId)}
                style={{
                  textDecoration: 'none',
                  border: 'none',
                  background: 'none',
                  cursor: 'pointer',
                 
                  color: 'darkslateblue',  
                }}
                onMouseEnter={(e) => e.target.style.color = 'blue'}  
                onMouseLeave={(e) => e.target.style.color = 'darkslateblue'}  
              >
                {getFileNameWithoutExtension(params.row.timesheetsFileLater)}
              </button>
            )}
          </Typography>
        </div>
      ),
    }        
    ,
    {
      field: "totalAmount",
      headerName: "Salary €",
      width: 80,
     //flex: 1,
      align: "center",
      renderCell: (params) => (
        <div style={{ textAlign: "center" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "-",
      headerName: "Action",
      //width: 200,
      flex: 1,
       
      renderCell: (params) => (
        <div>
          <Button
              style=
              {{ textTransform: 'capitalize',
              backgroundColor: '#3f72af',
              color: '#fff',
              }}
              onClick={(e)=>handleButtonClick("newPayment",params.row.paymentState,params.row.paymentId)}>
              {state[0] === 0 ? 'Aktualisieren' : 'View'}

          </Button>
        </div>
      ),
    },
  ];
   
  const openFileWindow = async (fileName,paymentId) => {
    var res =await Get ("Payment/"+paymentId+"/file",fileName);
    if(res?.isSuccess)
    {        
        
        var fullPdfpath = getBaseUrl(apiUrl) + "/" + res.result;
        window.open(fullPdfpath, '_blank');     
    }
    else
    {
      window.open(`file-not-found`, '_blank');
    }
  };
  function handleRefresh() {
    navigate(0);
  }

  function handleExportExcelClick() {
    const fileName = "HM-Beratung.xlsx";
    const fieldsToExport = ["paymentStateName", "yearMonth", "totalAmount"];
    const headerMap = {      
      "paymentStateName": "State",
      "yearMonth": "Date",
      "totalAmount": "Salary €",
    };
    
    const data = paymentsData.map((row) => {
      const rowData = {};
      fieldsToExport.forEach((field) => {
        if (field === "paymentStateName") {
          switch (row[field].toLowerCase()) {
            case 'paid':
              rowData[headerMap[field]] = 'Bezahlt';
              break;
            case 'waiting':
              rowData[headerMap[field]] = 'Wartend';
              break;
            case 'approved':
              rowData[headerMap[field]] = 'Genehmigt';
              break;
            default:
              rowData[headerMap[field]] = row[field];  // Keeps the original if no match
          }
        } else {
          rowData[headerMap[field]] = row[field];
        }
      });
      return rowData;
    });
    
  
    const totalRow = {
      "State": "Total",
      "Date": "",
      "Salary €": totalAmountSum +" €",
    };  
    const exportData = [...data, totalRow]; // Add the total row to the data  
    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");      
    XLSX.writeFile(wb, fileName);
  }
  
  function DefinedToolbar() {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
          //width:"600px"
        }}
      >
        <GridToolbarContainer>
         
          {/* <GridToolbarExport
            csvOptions={{
              fileName: "HM-Beratung",
              delimiter: ";",
              utf8WithBom: true,
            }}
          />
           */}
        
          <Button
            color="primary"
            startIcon={<DownloadIcon />}
            onClick={handleExportExcelClick}
            disabled={paymentsData.length === 0}
          >
            Excel
          </Button>
          <Button
            color="primary"
            startIcon={<SyncIcon />}
            onClick={handleRefresh}
          >
            Refresh
          </Button>
          <GridToolbarQuickFilter   />
        
        </GridToolbarContainer>
      </Box>
    );
  }

  useEffect(() => {
    (async () => {
      let userIdValue = await getStorageItem("login", "userId");  
      if(userIdValue<=0)
      {
        return; // log in page
      }
      setUserId(userIdValue)
      
    })();
  }, [userId]);
  useEffect(() => {
    (async () => {
       
      if(userId<=0)
      {
        return; // log in page
      }
     
      let stateQuery = "";
      if (state.length > 0) {
          stateQuery = "?paymentStates=" + state.join("&paymentStates=");
      }
      let res = await Get("Payment/user/"+currentYear+"/" + userId + stateQuery);
          

      if (res.isSuccess) {
        setPaymentsData(res.result);
        setError(null);
      } else {
        setError(res.Status + "/" + res.Error);
      }
    })();
  }, [state,userId,currentYear]);

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <DataGrid
         style={{ height: 600, width:750 }} 
          rows={paymentsData}
          columns={columns}
          editMode="cell"
          density="comfortable"
          getRowClassName={() => 'no-hover'}
          disableColumnFilter
          disableColumnMenu
          disableSelectionOnClick
          slots={{ toolbar: DefinedToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                paymentId: false,
              },
            },
            filter: {
              filterModel: {
                items: [
                  { field: "yearMonth", operator: "contains", value: "" },
                ],
              },
            },
          }}
          getRowId={(row) => row.paymentId}
         
        />
      </Grid>

      <Grid width={550}  style={{ marginTop: 16 }}>
        <Paper elevation={1}>
          {/* Display Total under the Date column */}
          <Grid container justifyContent="flex-end" alignItems="center">
            <Grid item>
            <Typography variant="subtitle1" style={{ padding: "10px", fontWeight: "bolder" }}>
              Total: {totalAmountSum.toLocaleString('en-US', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 })}
          </Typography>


            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default UserPayments;
