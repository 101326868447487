import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const ProfileMustUpdated = ({ open, handleClose }) => {
  return (
    <Dialog open={open} onClose={(event, reason) => reason !== 'backdropClick' && handleClose()} fullWidth maxWidth="sm">
      <DialogTitle>Profil aktualisieren</DialogTitle>
      <DialogContent>
        Bitte aktualisieren Sie Ihr Profil.
      </DialogContent>
      <DialogActions>
        <Button
          component={Link}
          to="/userprofile"
          onClick={handleClose}
          variant="contained"
          color="primary"
          sx={{ margin: '0 auto' }}
        >
          Aktualisieren
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProfileMustUpdated;
