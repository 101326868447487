import React, { useEffect, useState } from "react";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import Typography from "@mui/material/Typography";
import { Get } from "../../../../Common/WebRequest";
import { Paper } from "@mui/material";
import "./TopStats.css";  

const YearlyRevenueComparison = () => {
  const [loading, setLoading] = useState(true);
  const [yearlyRevenue, setYearlyRevenue] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await Get("PaymentReports/yearly-revenue-comparison");
        if (response && response.isSuccess) {
          setYearlyRevenue(response.result);
        }
      } catch (error) {
        console.error("Error fetching yearly revenue comparison:", error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!yearlyRevenue.length) {
    return null;
  }
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const formattedValue = payload[0].value.toLocaleString('en-US', {
        minimumFractionDigits: 0
      });
      return (
        <div className="custom-tooltip" style={{ backgroundColor: "#fff", padding: "5px", border: "1px solid #ccc" }}>
          <p className="label">{`${payload[0].name} : €${formattedValue}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <Paper elevation={3} className="top-container">
     <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', color: '#4b4b4b'}}>
     Jährlicher Umsatzvergleich
      </Typography>
    
      <div className="chart-container">
        <ResponsiveContainer width="100%" height={300}>
          <AreaChart
            data={yearlyRevenue}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <defs>
              <linearGradient id="colorTotalPaid" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
              </linearGradient>
            </defs>
            <XAxis dataKey="year" />
            <YAxis />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip content={<CustomTooltip />} />
            <Legend  wrapperStyle={{ fontSize: '15px', paddingBottom:'15px' }} />
            <Area type="monotone" dataKey="totalAmountPaid" name="Total Amount Paid" stroke="#8884d8" fillOpacity={1} fill="url(#colorTotalPaid)" />
          </AreaChart>
        </ResponsiveContainer>
     </div>
    </Paper>
  );
};

export default YearlyRevenueComparison;
