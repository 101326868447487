import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { HomeOutlined } from "@mui/icons-material";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import WaitingParentIcon from "@mui/icons-material/SensorOccupied";

import LoginIcon from "@mui/icons-material/Login";
import { useNavigate } from "react-router-dom";

import { getStorageItem } from "../../Common/Utility";
import { useEffect } from "react";
import { useState } from "react";
import { IconButton } from "@mui/material";
import ReorderIcon from "@mui/icons-material/Reorder";
import DialogBoxYesNo from "../Dialog/DialogBoxYesNo";
import { Post } from "../../Common/WebRequest";

function TitleBar({ onNavToggle }) {
  const [userName, setUserName] = useState("");
  const [environment, setEnvironment] = useState("");
  const [isNavOpen, setIsNavOpen] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);
  // Load userName and systemType from storage only once at component mount
  useEffect(() => {
    (async () => {
      let userNameVar = await getStorageItem("login", "userName");
      let environmentVar = await getStorageItem("login", "environment");
      let isAdmin = await getStorageItem("login", "isAdmin");
      setIsAdmin(isAdmin);
      if (userNameVar) {
        setUserName(userNameVar);
      }
      if (environmentVar) {
        setEnvironment(environmentVar);
      }
    })();
  }, []);

  // Dynamically generate pages based on userName and systemType
  const getPages = () => {
    if (isAdmin) {
      let basePages = [
        " Bewilligte Zahlungen",
        " Wartende Zahlungen",
        " Wartende Partner",
        "--",
        " Abmelden",
      ];

      if (userName) {
        basePages[3] = ` (${environment})`;
        basePages[4] += ` (${userName})`;
      }

      return basePages;
    } else {
      let basePages = ["Abmelden"];

      if (userName) {
        basePages[0] += ` (${userName})`;
      }

      return basePages;
    }
  };

  const pages = getPages(); // This will always give you the current pages array

  const loginData = JSON.parse(localStorage.getItem("login"));
  if (!loginData || loginData.length === 0) {
    return null;
  }

  const handleCloseNavMenu = (page) => {
    console.log(page);
    if (page.includes("Abmelden")) {
      handleShowDialog();
    } else if (page.toLowerCase().includes("bewilligte zahlungen")) {
      navigate("/paidpayments");
    } else if (page.toLowerCase().includes("wartende zahlungen")) {
      navigate("/waitingpayments");
    } else if (page.toLowerCase().includes("wartende partner")) {
      navigate("/waitingpartners");
    }
  };

  function handleOnNavToggle(e) {
    setIsNavOpen(!isNavOpen);
    onNavToggle();
  }

  const handleShowDialog = () => {
    setShowDialog(true);
  };

  const handleCloseDialog = (result) => {
    setShowDialog(false);
    console.log("Dialog result:", result);
    if (result === true) {
      handleOnLogout();
    }
  };

  async function handleOnLogout() {
    try {
      var refreshTokenValue = await getStorageItem("login", "refreshToken");
      var logoutDto = {
        refreshToken: refreshTokenValue,
      };
      let res = await Post("auth/Logout", logoutDto);

      localStorage.removeItem("login");
      onNavToggle(true); //  called from App to set margin-left=0px
      navigate("/login");
    } catch (error) {
      console.log("Error by logout session: ", error.toString());
    }
  }
  return (
    <AppBar position="static">
      {showDialog && (
        <DialogBoxYesNo
          title="Abmelden"
          text="Sind Sie sicher, dass Sie sich abmelden möchten?"
          onClose={handleCloseDialog}
        />
      )}

      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <IconButton onClick={handleOnNavToggle} sx={{ color: "white" }}>
            {isNavOpen ? <ReorderIcon /> : <ReorderIcon />}
          </IconButton>
        </Box>
        <Box sx={{ marginLeft: 2, marginRight: 2 }}></Box>
        {isAdmin && <HomeOutlined />}
        {isAdmin && (
          <Typography
            variant="h6"
            component="a"
            href="/"
            sx={{
              mr: 4,
              ml: 1,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              color: "inherit",
              textDecoration: "none",
              ":hover": {
                color: "white",
              },
            }}
          >
            Home
          </Typography>
        )}
        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
          {userName &&
            pages.map((page) => (
              <Button
                key={page}
                onClick={(e) => handleCloseNavMenu(page)}
                sx={{
                  textTransform: "none",
                  fontSize: "1.0em",
                  my: 2,
                  display: "block",
                  color: page.toLocaleLowerCase().includes("test")
                    ? "red"
                    : page.toLocaleLowerCase().includes("live")
                    ? "lightgreen"
                    : page.toLocaleLowerCase().includes("dev")
                    ? "orange"
                    : "white",
                }}
              >
                {page.toLowerCase().includes("bewilligte zahlungen") && (
                  <ReceiptOutlinedIcon />
                )}
                {page.toLowerCase().includes("wartende zahlungen") && (
                  <AccountBalanceWalletIcon />
                )}
                {page.toLowerCase().includes("wartende partner") && (
                  <WaitingParentIcon />
                )}
                {page.toLowerCase().includes("logout") && <LoginIcon />}

                {page}
              </Button>
            ))}
        </Box>
      </Toolbar>
    </AppBar>
  );
}
export default TitleBar;
