import TabContainer from "./TabContainer";
import "./ControlPanel.css";
import UserManagement from "./UserManagement/UserManagement.js";
import { Configuration } from "./Configuration/Configuration.jsx";
import { Typography } from "@mui/material";
import { blue } from "@mui/material/colors";

const tabs = [
  { label: "Users Management", content: <UserManagement /> },
  { label: "Configuration", content: <Configuration /> },
];

function ControlPanel() {
  return (
    <div className="control-panel">
      <div>
        <Typography
          variant="headline"
          component="h2"
          align="left"
          color={blue[500]}
          mb={1}
        >
          Control Panel
        </Typography>

        <TabContainer tabs={tabs} />
      </div>
    </div>
  );
}

export default ControlPanel;
