// Waiting Payments Table.jsx
import React, { useState, useEffect } from "react";
import "./WaitingTable.css";
import "../SearchBox.css";

import DataExportButtons from "../../../../components/Tools/DataExportButtons";
import { Get } from "../../../../Common/WebRequest";

const WaitingTable = ({ data, navigateToViewPaymentPage }) => {
  const [sortOrderName, setSortOrderName] = useState("asc");
  const [sortOrderRegionName, setSortOrderRegionName] = useState("asc");
  const [sortOrderDate, setSortOrderDate] = useState("asc");
  const [sortOrderSalary, setSortOrderSalary] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");
  const [sortedData, setSortedData] = useState(data);

  useEffect(() => {
    try {
      setSortOrderName("asc");
      setSortOrderRegionName("asc");
      setSortOrderDate("asc");
      setSortOrderSalary("asc");
      setSortedData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
    }
  }, [data]);

  useEffect(() => {
    // Filter data based on the search term
    const filteredData = data.filter((row) =>
      Object.entries(row).some(([key, value]) => {
        if (key === "amount" && typeof value === "number") {
          return value.toString().includes(searchTerm.toString());
        } else if (
          (key === "yearMonth" || key === "name") &&
          typeof value === "string"
        ) {
          return value.toLowerCase().includes(searchTerm.toLowerCase());
        }
        return false;
      })
    );
    setSortedData(filteredData);
  }, [searchTerm, data]);
  const handleSort = (column) => {
    const sortOrder = getColumnSortOrder(column) === "asc" ? "desc" : "asc";

    setSortOrderName("asc");
    setSortOrderRegionName("asc");
    setSortOrderDate("asc");
    setSortOrderSalary("asc");

    const sorted = [...sortedData];

    switch (column) {
      case "name":
        sorted.sort((a, b) =>
          sortOrder === "asc"
            ? a.name.localeCompare(b.name)
            : b.name.localeCompare(a.name)
        );
        setSortOrderName(sortOrder);
        break;

      case "regionName":
        sorted.sort((a, b) =>
          sortOrder === "asc"
            ? a.regionName.localeCompare(b.regionName)
            : b.regionName.localeCompare(a.regionName)
        );
        setSortOrderRegionName(sortOrder);
        break;

      case "date":
        sorted.sort((a, b) =>
          sortOrder === "asc"
            ? a.yearMonth.localeCompare(b.yearMonth)
            : b.yearMonth.localeCompare(a.yearMonth)
        );
        setSortOrderDate(sortOrder);
        break;

      case "salary":
        sorted.sort((a, b) =>
          sortOrder === "asc" ? a.amount - b.amount : b.amount - a.amount
        );
        setSortOrderSalary(sortOrder);
        break;

      default:
        break;
    }

    setSortedData(sorted);
  };

  const getColumnSortOrder = (column) => {
    switch (column) {
      case "name": //text
        return sortOrderName;

      case "regionName": // text
        return sortOrderRegionName;

      case "date": // text
        return sortOrderDate;

      case "salary": // number
        return sortOrderSalary;

      default:
        return "asc";
    }
  };

  const renderSortIcon = (column) => {
    console.log("renderSortIcon", column);
    if (getColumnSortOrder(column) === "asc") {
      return <span>&uarr;</span>; // Up arrow for ascending order
    } else {
      return <span>&darr;</span>; // Down arrow for descending order
    }
  };

  const calculateTotalSum = () => {
    return sortedData.reduce((sum, user) => sum + user.amount, 0);
  };

  const renderRows = () => {
    return sortedData.map((row, index) => (
      <tr key={index} className="waitingTableRow">
        <td className="waitingTableCell">{row.name}</td>
        <td className="waitingTableCell">{row.regionName}</td>
        <td
          className={`waitingTableCell dateColumn`}
          onClick={() => handleCellClick(row)}
        >
          {row.yearMonth}
        </td>
        <td className="waitingTableCell">{row.amount} </td>
      </tr>
    ));
  };

  const handleCellClick = async (row) => {
    console.log("Cell clicked:", row.yearMonth, row.userId);

    if (row.yearMonth && row.userId) {
      try {
        const res = await Get(
          "Payment/user-and-month/" + row.userId,
          row.yearMonth
        );

        if (res.isSuccess) {
          navigateToViewPaymentPage({
            paymentId: res.result.paymentId,
            reload: false,
          });

          console.log(res);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchClose = () => {
    setSearchTerm("");
  };

  return (
    <>
      <br />
      <div>
        <div className="row">
          <div className="col-sm-6 col-md-6">
            <DataExportButtons
              tableId="data-table"
              data={data}
              filename_csv="Wartende_Zahlungen"
              filename_excel="Wartende_Zahlungen"
              filename_pdf="Wartende_Zahlungen"
            />
          </div>
          <div className="col-sm-4 col-md-2">
            <div className="searchContainer">
              <input
                type="text"
                className="searchInput"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
              {searchTerm && (
                <button className="closeButton" onClick={handleSearchClose}>
                  &times;
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-8">
            <div className="waitingTableContainer">
              <table
                id="data-table"
                name="waitingPayments"
                className="waitingTable"
              >
                <thead>
                  <tr>
                    <th
                      className="waitingTableHeader"
                      onClick={() => handleSort("name")}
                    >
                      Name {renderSortIcon("name")}
                    </th>
                    <th
                      className="waitingTableHeader"
                      onClick={() => handleSort("regionName")}
                    >
                      PLZ (Region) {renderSortIcon("regionName")}
                    </th>
                    <th
                      className="waitingTableHeader"
                      onClick={() => handleSort("date")}
                    >
                      Date {renderSortIcon("date")}
                    </th>
                    <th
                      className="waitingTableHeader"
                      onClick={() => handleSort("salary")}
                    >
                      Salary € {renderSortIcon("salary")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {renderRows()}
                  <tr className="waitingTableRow">
                    <td className="waitingTableCell">Total</td>
                    <td className="waitingTableCell"></td>
                    <td className="waitingTableCell"></td>
                    <td className="waitingTableCell">
                      {calculateTotalSum()} €
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div>
          <p>Count = {data.length}</p>
        </div>
      </div>
    </>
  );
};

export default WaitingTable;
