import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Post } from "../../../Common/WebRequest";

const PasswordRecoveryForm = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };

  async function handleSubmit(e) {
    e.preventDefault();

    if (!validateEmail(email)) {
      setEmailError("Bitte geben Sie eine gültige E-Mail-Adresse ein.");
      return;
    }

    setLoading(true);

    try {
      let res = await Post(
        `PasswordRecovery/SendEmailWithLink/email=${email}`,
        {},
        "text/plain"
      );

      if (res.isSuccess) {
        setEmailError("");
        setEmailSent(true);
      } else {
        setEmailError(res.Error || "Something went wrong");
      }
    } catch (error) {
      setEmailError(error.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (emailSent) {
      navigate(`/emailsent?email=${email}`);
    }
  }, [emailSent, email, navigate]);

  return (
    <Container component="main" maxWidth="xs">
      <Paper
        elevation={3}
        style={{ padding: "2em", marginTop: "8vh", textAlign: "center" }}
      >
        <Typography component="h1" variant="h5">
          Passwort zurücksetzen
        </Typography>
        <br />
        <br />
        <Typography variant="body2" color="textSecondary" gutterBottom>
          Bitte geben Sie Ihre E-Mail-Adresse an
        </Typography>

        <form
          style={{ width: "100%", marginTop: "1em" }}
          onSubmit={handleSubmit}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={!!emailError}
                helperText={emailError}
              />
            </Grid>
          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ marginTop: "2em", backgroundColor: "#007bff", color: "#fff" }}
            disabled={loading}
          >
            {loading ? "Sending..." : "E-Mail senden"}
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default PasswordRecoveryForm;
