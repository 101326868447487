import React, { useEffect, useState } from "react";
import { Paper, Typography } from "@mui/material";
import { PieChart, Pie, Tooltip, ResponsiveContainer, Cell, Legend } from 'recharts';
import { Get } from "../../../../Common/WebRequest";

const TotalPaidPerRegion = () => {
  const [loading, setLoading] = useState(true);
  const [totalPaidData, setTotalPaidData] = useState([]);
  const [colors, setColors] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await Get("PaymentReports/total-per-region");
        if (response && response.isSuccess) {
          setTotalPaidData(response.result);
          setColors(generateColors(response.result.length));
        } 
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const generateColors = (count) => {
    // Generate dynamic colors based on the count of regions
    const dynamicColors = [];
    for (let i = 0; i < count; i++) {
      dynamicColors.push(`hsl(${350 * i / count}, 70%, 50%)`);
    }
    return dynamicColors;
  };

  if (loading) {
    return <div>Loading...</div>;
  }
  if (!totalPaidData.length) {
    return null;
  } 

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const totalAmountPaid = payload[0].value.toLocaleString('en-US', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 });
            
      return (
        <div className="custom-tooltip" style={{ backgroundColor: "#fff", padding: "5px", border: "1px solid #ccc" }}>
          <p className="label">{`${payload[0].name} : ${totalAmountPaid}`}</p>         
        </div>
      );
    }
    return null;
  }; 

  return (
    <Paper elevation={3} className="top-container">
     <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', color: '#4b4b4b'}}>
      Gesamtzahlungen pro Regionen
     </Typography>

      <div className="chart-container">
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
          <Pie
            data={totalPaidData}
            dataKey="totalAmountPaid"
            nameKey="regionName"
            cx="50%"
            cy="50%"
            outerRadius={100}
            fill="#8884d8"
            //   label={({ name, value }) => `${name}: ${value.toLocaleString('en-US')}`}
  
                label={(entry) => {
                const percentage = (entry.value / totalPaidData.reduce((acc, curr) => acc + curr.totalAmountPaid, 0) * 100).toFixed(0);
                return `${entry.name}: ${percentage}%`;
                }}
            >
            {totalPaidData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
            ))}
            </Pie>

            <Tooltip content={<CustomTooltip />} />
            <Legend wrapperStyle={{ fontSize: '15px', paddingBottom:'15px' }} />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </Paper>
  );
};

export default TotalPaidPerRegion;
