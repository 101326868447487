import { HomeOutlined } from "@mui/icons-material";
import PlaceIcon from '@mui/icons-material/Place';
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import WaitingParentIcon from '@mui/icons-material/SensorOccupied';
import ApprovedPartnerIcon from '@mui/icons-material/CheckCircleOutline';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import GridViewIcon from '@mui/icons-material/GridView';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import SystemUpdate from '@mui/icons-material/SystemUpdateAlt';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import HelpIcon from '@mui/icons-material/HelpOutline';
import MapIcon from '@mui/icons-material/Map';
 


 export const navigationItems = [
    {
      text: "Home",
      icon: <HomeOutlined />,
      link: "Home",
    },
    {
      text: "Stammdaten",
      icon: null,
      link: "",
    },   
    {
      text: "Regionen",
      icon: <PlaceIcon />,
      link: "Regions",
    },
    {
      text: "Partner",
      icon: null,
      link: "",
    },     
    {
      text: "Partner-Details",
      icon: <PeopleAltIcon />,
      link: "Partners",
    },  
    {
      text: "Bewilligte Partner",
      icon: <ApprovedPartnerIcon />,
      link: "ApprovedPartners",
             
    },
    {
      text: "Wartende Partner",
      icon: <WaitingParentIcon />,
      link: "WaitingPartners",
    },
    {
      text: "Zahlungsmanagement",
      icon: null,
      link: "",
    },
    {
      text: "Bewilligte Zahlungen",
      icon: <ReceiptOutlinedIcon />,
      link: "PaidPayments",
    },  
    {
      text: "Wartende Zahlungen",
      icon: <AccountBalanceWalletIcon />,
      link: "WaitingPayments",
    },          
    {
      text: "Management",
      icon: null,
      link: "",
    }, 
    {
      text: "Control Pannel",
      icon: <GridViewIcon />,
      link: "ControlPannel",
    }, 
    {
      text: "Users Location (Map)",
      icon: <MapIcon />,
      link: "UsersLocation",
    },
    
    {
      text: "Audit Logs",
      icon: <ScatterPlotIcon/>,
      link: "AuditLogs",
    },    
    {
      text: "User Logins",
      icon: <PermIdentityIcon/>,
      link: "UserSystemLoginsMonitor",
    }, 
    {
      text: "System Updates",
      icon: <SystemUpdate/>,
      link: "SystemUpdates",
    },

    {
      text: "System Logs/Errors",
      icon: <ErrorOutlineIcon />,
      link: "SystemLogs",
    },
   
    {
      text: "Main",
      icon: null,
      link: "",
    }, 
    {
      text: "Dashboard",
      icon: <GridViewIcon />,
      link: "Dashboard",
    }, 
    {
      text: "Profil",
      icon: <PersonPinIcon />,
      link: "UserProfile",
    }, 
    {
      text: "Help",
      icon: <HelpIcon />,
      link: "Help",
    }, 
 
    
  ];

  export function filterNavigationItems(isAdmin,passwordChanged) {
    if(typeof (isAdmin) === 'undefined')
    //return [];
    if (!passwordChanged && !isAdmin) {
      return [];
    }
    var filteredItems= navigationItems.filter(
      (item) =>
        
      !(item.link  === "Home" && !isAdmin) &&  
      !(item.text  === "Stammdaten" && !isAdmin) &&            
      !(item.link  === "Regions" && !isAdmin) &&

      !(item.link  === "Partners" && !isAdmin) &&
      !(item.text  === "Bezahlung" && !isAdmin) &&
      !(item.link  === "PaidPayments" && !isAdmin) &&
      !(item.link  === "WaitingPayments" && !isAdmin) &&
      !(item.link  === "ApprovedPartners" && !isAdmin) &&
      !(item.link  === "WaitingPartners" && !isAdmin) &&

      !(item.text  === "Management" && !isAdmin) &&
      !(item.link  === "UserManagement" && !isAdmin) &&
      !(item.link  === "ControlPannel" && !isAdmin)  &&
      !(item.link  === "AuditLogs" && !isAdmin)  &&
      !(item.link  === "SystemUpdates" && !isAdmin) &&
      !(item.link  === "SystemLogs" && !isAdmin) &&
      !(item.link  === "UserSystemLoginsMonitor" && !isAdmin) &&
      !(item.text  === "Partner" && !isAdmin)  &&
      !(item.text  === "Zahlungsmanagement" && !isAdmin)  &&
      !(item.link  === "UsersLocation" && !isAdmin)  &&
      !(item.link  === "Help" && !isAdmin)   
      
        //Normal User ------------------------------------
        && !(item.text === "Main" && isAdmin )             
        && !(item.link === "UserProfile" && isAdmin)          
         && !(item.link === "Dashboard" && (isAdmin || !passwordChanged))          
    );

    return filteredItems;
  }
  