import React from 'react';
import { Typography, Box,  Card, CardContent, Grid } from '@mui/material';
import {   green } from '@mui/material/colors';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EmailIcon from '@mui/icons-material/Email';
//import { useNavigate } from 'react-router-dom';

const RegistrationConfirmation = () => {
    //const navigate = useNavigate();
   /* const handleButtonClick = () => {
        navigate('/login');
      };*/
  return (
    <>
    <Box sx={{ maxWidth: 700, mx: 'auto', p: 2, mt: 8 }}>
      <Card variant="outlined" sx={{ mb: 4, boxShadow: 3, border: '1px solid #e0e0e0', borderRadius: 2 }}>
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 3 }}>
            <CheckCircleOutlineIcon sx={{ fontSize: 60, color: green[500], marginRight: 2, marginTop:1 }} />
            <Typography variant="h4" color={green[500]} gutterBottom sx={{ mt: 3, fontFamily: 'tahoma' }}>
            Vielen Dank für die Anmeldung!
            </Typography>
          </Box>
          <Typography variant="body1" gutterBottom sx={{ ml: 5, fontFamily: 'tahoma' }}>
            Bitte überprüfen Sie Ihr 
               <Box component="span" fontWeight="bold"> E-Mail
               </Box>-Postfach, um die Anmeldung zu bestätigen.
              </Typography>

          <Grid  item xs={12} sx={{display:'flex',justifyContent: 'center'}}>
          <EmailIcon sx={{ fontSize: 40, color: 'gray', marginTop: '30px', marginBottom: '10px' }} />        
          </Grid>
          <Typography
          paragraph
          style={{ fontStyle: "italic", color: "darkgray",marginTop: '10px', marginBottom: 'auto'}}
        >
          * Wenn E-Mail nicht im Posteingang gefunden wird, überprüfen Sie den{" "}
          <strong style={{ fontWeight: "bold", color: "black" }}>
            Spam-Ordner.
          </strong>
        </Typography>
           
          {/* <Box sx={{ display: 'flex', justifyContent: 'center', mt: 6 }}>
            <Button variant="contained" sx={{ textTransform: 'none' }} color="primary" onClick={handleButtonClick}>
            Zurück zur Anmeldung
            </Button>
          </Box> */}
         
        </CardContent>
      </Card>
    </Box>
    </>
  );
};

export default RegistrationConfirmation;
