import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import DoneIcon from "@mui/icons-material/Done";
import UndoIcon from "@mui/icons-material/Undo";
const DialogBoxWithYesNo = ({
  open,
  onClose,
  onYes,
  title,
  text,
  operationText,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          startIcon={<UndoIcon />}
          sx={{
            textTransform: "capitalize",
          }}
        >
          Abbrechen
        </Button>
        <Button
          onClick={onYes}
          variant="contained"
          endIcon={<DoneIcon />}
          autoFocus
          sx={{
            textTransform: "capitalize",
            background:
              operationText &&
              (operationText.toString().toLowerCase() === "delete" ||
                operationText.toString().toLowerCase() === "löschen")
                ? "#c04000"
                : "",
          }}
        >
          {operationText ? `${operationText}` : "Ja"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogBoxWithYesNo;
