import React, { useState } from 'react';
import { TextField, Button, FormControl, InputLabel, Select, MenuItem, Grid, Typography, Box, Card, CardContent } from '@mui/material';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom'; 
import { blue } from '@mui/material/colors';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HomeIcon from '@mui/icons-material/Home';
import IbanIcon from '@mui/icons-material/AccountBalance';
import SendIcon from '@mui/icons-material/Send';
import CheckError from "@mui/icons-material/HighlightOffRounded";
import { useEffect } from 'react';

import { Label } from 'reactstrap';
import { Get, Post } from '../../../Common/WebRequest';
import { getCoordinatesForAddress } from '../../../Common/locationHelper';
 

const RegistrationForm = () => {
  const navigate= useNavigate();
  const [apiKey, setApiKey] = useState("");
  const [selectedRegionName, setSelectedRegionName] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [firstErrorField, setFirstErrorField] = useState(null); // State to track the first error field
  const [error,setError] = useState('')
  const [isSending,setIsSending]=useState(false)
  const [userData, setUserData] = useState({
    fullName: '',
    email: '',
    password: '',
    PasswordConfirmation:'',
    regionId: '',
    zip: '',
    iban: '',
    street: '',
    phoneNumber: '',
  });

  const [regions,setRegions ]= useState([]);
  const constructAddress = (userData, selectedRegionName) => {
    return `${userData.street}, ${userData.zip} ${selectedRegionName}, Germany`;
  };
  useEffect(() => {
    const fetchApiKey = async () => {
      try {
        const googleMapInfo = await Get("CompanyInformation/GoogleMapInfo");
        if (googleMapInfo.isSuccess) {
          setApiKey(googleMapInfo.result.googleApiKey);
        } else {
          console.error('Error:', error);
        }
      } catch (error) {
       console.error('Error:', error);
      } finally {
      }
    };
    fetchApiKey();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const regionsData = await Get("region");
        if (!regionsData.isSuccess) {
          
          return;
        }
        setRegions(regionsData.result);
      } catch (error) {
         
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []); // Empty dependency array means this effect will only run once after the component mounts

  const handleChange = (event) => {
    const { name, value, type } = event.target;

    if (name === 'zip' && value !== '' && (!/^\d+$/.test(value) || value.length > 5)) {
      
      return;
    }
    setUserData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? event.target.checked : value,
    }));

    if (name === 'regionId') {
      const selectedRegion = regions.find(region => region.regionId === value).regionName;
      setSelectedRegionName(selectedRegion);
      
  }
  };

  const handleSubmit = async (event) => {
    
    try{ 
    setIsSending(true)
    setError("")
    setFormErrors("")
    event.preventDefault();
    const validationErrors = validateForm();
    setFormErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      console.log(userData);
      await DoSaveApi()
      //navigate('/registration-confirmation');
    }
    else {
      // Find the first input field with an error and set focus
      const errorKeys = Object.keys(validationErrors);
      if (errorKeys.length > 0) {
        const firstErrorKey = errorKeys[0];
        setFirstErrorField(firstErrorKey);
      }
    }
  }
  catch(error)
  {
    setError(error)
  }
  finally{
    setIsSending(false)
  }
  };

  const validateForm = () => {
    const errors = {};
    if (!userData.fullName.trim()) errors.fullName = 'Name ist erforderlich';
    if (!userData.email) {
      errors.email = 'email ist erforderlich';
    } else if (!/\S+@\S+\.\S+/.test(userData.email)) {
      errors.email = 'E-Mail-Adresse ist ungültig';
    }
    if (!userData.password) {
      errors.password = 'Passwort ist erforderlich';
    } else if (userData.password.length < 6 || !/\d/.test(userData.password)) {
      errors.password = '6 Zeichen und eine Zahl enthalten';
    }
    if (userData.password !== userData.PasswordConfirmation) {
      errors.PasswordConfirmation = 'Passwörter stimmen nicht überein';
    }
    if (!userData.zip.trim()) {
      errors.zip = 'PLZ ist erforderlich';
    } else if (!/^\d{5}$/.test(userData.zip.trim())) {
      errors.zip = '5-stellige Zahl sein';
    }
    if (!userData.regionId  ) errors.regionId = 'Region ist erforderlich';
    
    if (!userData.street.trim()) errors.street = 'Straße ist erforderlich';
    if(!userData.iban.trim()) errors.iban = 'IBAN ist erforderlich';
    return errors;
  };
  
  const fetchCoordinates = async (address) => {
    try {
      const coordinates = await getCoordinatesForAddress(
        apiKey,
        address,
        selectedRegionName,
        userData.zip
      );
      if (coordinates.error) {
        console.error('Failed to fetch coordinates:', coordinates.error);
        return {
          lat: 0,
          lng: 0,
        };
      } else if (coordinates.lat && coordinates.lng) {
        return {
          lat: coordinates.lat.toString(),
          lng: coordinates.lng.toString(),
        };
      } else {
        return {
          lat: 0,
          lng: 0,
        };
      }
    } catch (error) {
      console.error("Failed to fetch coordinates:", error);
      return {
        lat: 0,
        lng: 0,
      };
    }
  };
  async function DoSaveApi()
  {
    try{

      let currentAddress = constructAddress(userData, selectedRegionName);
    const coords = await fetchCoordinates(currentAddress);
    if (coords) {
      userData.latitude = coords.lat;
      userData.longitude = coords.lng;
    }
    
      let res = await Post("register",userData)
      if(res.isSuccess)
      {                    
        navigate('/registration-confirmation');    
      }
      else 
      {
        setError(res.Error)
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }
  return (


   
    <Box sx={{
      maxWidth: 600,
      mx: 'auto',
      p: 2,
      mt: 4,
      background: 'linear-gradient(120deg, #a1c0fd 0%, #c2e9fb 100%)',  
      borderRadius: 3,
      boxShadow: 3,
      '& .MuiCard-root': {  
        bgcolor: 'rgba(254, 255, 254, 1)',  
        backdropFilter: 'blur(10px)',  
      },
    }}>
      <Card variant="outlined" sx={{ mb: 4, boxShadow: 3, border: '1px solid #e0e0e0', borderRadius: 2 }}>
        <CardContent>
          <Typography variant="h5" gutterBottom color={blue[500]} sx={{ mb: 5 }}>
            Neue Kontoerstellung
          </Typography>

          <Label>
          {error && error.length > 0 && !error.includes("No Results") && (
            <div className="alert alert-danger" role="alert">
              <CheckError />
              {error.length > 1000 ? error.slice(0, 1000) + "..." : error}
            </div>
          )}
        </Label>

          <form onSubmit={handleSubmit}>
            <Grid container spacing={3} alignItems="flex-end">
              <Grid item xs={12}>
                <TextField
                  autoFocus 
                  label="Name"
                  name="fullName"
                  id='fullName'
                  autoComplete='not-off'
                  value={userData.fullName}
                  onChange={handleChange}
                  fullWidth
                  required
                  variant="outlined"
                  inputRef={(ref) => {
                     
                    if (firstErrorField === 'fullName') ref.focus();
                  }}
                  InputProps={{
                    autoComplete:'not-off',
                    startAdornment: ( 
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    ),
                  }}
                  error={!!formErrors.fullName}
                  helperText={formErrors.fullName ? formErrors.fullName.toLowerCase() : ''}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  autoComplete='off-not'
                  label="E-Mail"
                  name="email"
                  id='email-x'
                  value={userData.email}
                
                  onChange={handleChange}
                  fullWidth
                  required
                  type="email"
                  variant="outlined"
                  InputProps={{
                    autoComplete:'off-not',
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon />
                      </InputAdornment>
                    ),
                  }}
                  error={!!formErrors.email}
                  helperText={formErrors.email ? formErrors.email.toLowerCase() : ''}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Passwort"
                  name="password"
                  value={userData.password}
                  onChange={handleChange}
                  fullWidth
                  required
                  type="password"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon />
                      </InputAdornment>
                    ),
                  }}
                  error={!!formErrors.password}
                  helperText={formErrors.password ? formErrors.password.toLowerCase() : '6 Zeichen und eine Zahl enthalten'}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Passwort Bestätigung"
                  name="PasswordConfirmation"
                  required
                  fullWidth
                  value={userData.PasswordConfirmation}
                  onChange={handleChange}
                  type="password"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon />
                      </InputAdornment>
                    ),
                  }}
                  error={!!formErrors.PasswordConfirmation}
                  helperText={formErrors.PasswordConfirmation ? formErrors.PasswordConfirmation.toLowerCase() : '6 Zeichen und eine Zahl enthalten'}
                />
              </Grid >
              <Grid  item xs={12} container spacing={5} alignItems="flex-start">
              <Grid item xs={12} sm={6}>
                <TextField
                  label="PLZ"
                  name="zip"
                  id="zip"
                  value={userData.zip}
                  onChange={handleChange}
                  autoComplete='not-off'
                  fullWidth
                  required
                  variant="outlined"                
                  InputProps={{
                    maxLength: 5,
                     minLength: 5,
                    autoComplete:'not-off',
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationOnIcon />
                      </InputAdornment>
                    ),
                  }}
                  error={!!formErrors.zip}
                  helperText={formErrors.zip ? formErrors.zip.toLowerCase() : ''}

                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Region *</InputLabel>
                  <Select
                      name="regionId"
                      value={userData.regionId}
                      label="Region"
                      id="regionId"
                      autoComplete='regionId'
                      onChange={handleChange}
                      required
                      sx={{ borderRadius: '4px', display:'inline-block' }}
                      error={!!formErrors.regionId}
                    >
                      {regions && regions.map((region) => (
                        <MenuItem key={region.regionId} value={region.regionId}>{region.regionName}</MenuItem>
                      ))}
                    </Select>
                    {formErrors.regionId && (
                      <Typography variant="caption" color="error">
                        {formErrors.regionId.toLowerCase()}
                      </Typography>
                    )}                 
                </FormControl>
              </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Straße"
                  name="street"
                  autoComplete='strasse'
                  value={userData.street}
                  onChange={handleChange}
                  fullWidth
                  required
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <HomeIcon />
                      </InputAdornment>
                    ),
                  }}
                  error={!!formErrors.street}
                  helperText={formErrors.street ? formErrors.street.toLowerCase() : ''}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Telefon"
                  name="phoneNumber"
                  autoComplete='xoff-not0'
                  id="tel"
                  value={userData.phoneNumber}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PhoneIcon />
                      </InputAdornment>
                    ),
                    autoComplete:'xoff-not0'
                  }}
                />
              </Grid>
              <Grid item xs={12}  >
                <TextField
                  label="IBAN"
                  name="iban"
                  id="iban"
                  value={userData.iban}
                  onChange={handleChange}
                  autoComplete='not-off'
                  fullWidth
                  required
                  variant="outlined"                
                  InputProps={{
                    maxLength: 5,
                     minLength: 5,
                    autoComplete:'not-off',
                    startAdornment: (
                      <InputAdornment position="start">
                        <IbanIcon />
                      </InputAdornment>
                    ),
                  }}
                

                  onFocus={(event) => {
                    event.target.setAttribute('autocomplete', 'off');
                    event.target.name = 'iban'; // Temporarily change the name
                  }}
                  onBlur={(event) => {
                    event.target.name = 'iban'; // Revert the name
                  }}
                  error={!!formErrors.iban}
                  helperText={formErrors.iban ? formErrors.iban.toLowerCase() : ''}
                />
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
              <Button
              disabled={isSending}
                type="submit"
                variant="contained"
                color="primary"
                startIcon={<SendIcon />}
                sx={{
                  mt: 1,  
                  bgcolor: 'primary.main',  
                  '&:hover': {
                    bgcolor: 'primary.dark',  
                  },
                  padding: '10px 20px',  
                }}
              >
                Konto erstellen
              </Button>
            </Box>
          </form>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            <Link href="/login" variant="body2" sx={{ textDecoration: 'none', cursor: 'pointer' }}>
              Zurück zum Anmelden
            </Link>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default RegistrationForm;
