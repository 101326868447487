import {
  Box,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import logoImage from "../../Pages/img/abrechnungsystemLogos_internal.jpg";
import "./logo.css";
import { ChevronLeft, ChevronRightOutlined } from "@mui/icons-material";

import { useLocation, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import FlexBetween from "./FlexBetween";
import { filterNavigationItems } from "./NavigationItems";

import { getNumberFromString, getStorageItem } from "../../Common/Utility";

const SideBarMenu = ({
  isNonMobile,
  isSidebarOpen,
  setIsSidebarOpen,
  drawerWidth,
}) => {
  const [activeOption, setActiveOption] = useState("");

  const { pathname } = useLocation();
  const loginData = JSON.parse(localStorage.getItem("login"));
  const theme = useTheme();
  const [boxWidth, setBoxWidth] = useState("290px");
  const [isNavOpen, setIsNavOpen] = useState(true);
  const [filteredItems, setFilteredItems] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [reload, setReload] = useState(false);
  //const [drawerWidth, setDrawerWidth] = useState(0);
  useEffect(() => {
    (async () => {
      setIsAdmin(await getStorageItem("login", "isAdmin"));
      setPasswordChanged(await getStorageItem("login", "passwordChanged"));
    })();
  }, [isAdmin, passwordChanged]);

  useEffect(() => {
    (async () => {
      setFilteredItems(filterNavigationItems(isAdmin, passwordChanged));
    })();
  }, [isAdmin, passwordChanged]);
  useEffect(() => {
    setActiveOption(pathname.substring(1));
  }, [pathname]);

  useEffect(() => {
    setBoxWidth(drawerWidth);
    if (getNumberFromString(drawerWidth) <= 100) setIsNavOpen(false);
    else setIsNavOpen(true);
  }, [drawerWidth]);

  if (!loginData || loginData.length === 0) {
    return null;
  }

  return (
    <Box>
      {(isSidebarOpen || isNavOpen) && (
        <Drawer
          open={isSidebarOpen || isNavOpen}
          onClose={() => setIsSidebarOpen(!isSidebarOpen || !isNavOpen)}
          variant="persistent"
          anchor="left"
          sx={{
            width: drawerWidth - 1,
            overflowX: "hidden",
            "& .MuiDrawer-paper": {
              width: drawerWidth - 1,
              overflowX: "hidden",
              backgroundColor: theme.palette.background.alt,
              boxSizing: "border-box",
            },
          }}
        >
          <Box width={boxWidth}>
            {/* Title and Chevron Button */}
            <Box>
              <FlexBetween gap="0.5rem" color={theme.palette.secondary.main}>
                <Box>
                  <img
                    src={logoImage}
                    alt="Willkommen to Lehrkräfte Abrechnungsystem"
                    className="logo"
                  />
                </Box>
                {!isNonMobile && (
                  <IconButton
                    onClick={() =>
                      setIsSidebarOpen(!isSidebarOpen || !isNavOpen)
                    }
                  >
                    <ChevronLeft />
                  </IconButton>
                )}
              </FlexBetween>
            </Box>
            {/* List of Sidebar Buttons */}

            <List>
              {filteredItems.map(({ text, icon, link }) => {
                //   If Icon is not there in the navigation object

                if (!icon && isNavOpen) {
                  return (
                    <Typography
                      key={text}
                      sx={{ margin: "1.5rem 0 0.8rem 2.7rem" }}
                      fontWeight="bold"
                    >
                      {text}
                    </Typography>
                  );
                }
                // Lowercasing the Text value so that we can navigate to that page
                const lowerCaseText = link.toLowerCase();
                const linknav = link.toLowerCase();

                return (
                  <ListItem key={text} disablePadding>
                    <ListItemButton
                      component={Link}
                      to={linknav}
                      onClick={() => {
                        setActiveOption(lowerCaseText);
                      }}
                      sx={{
                        fontWeight:
                          activeOption === lowerCaseText ? "bold" : "normal",
                        color:
                          activeOption === lowerCaseText
                            ? theme.palette.primary.main
                            : link === "ControlPannel"
                            ? "red"
                            : link === "AuditLogs"
                            ? "green"
                            : link === "SystemUpdates"
                            ? "lightgreen"
                            : link === "UserProfile"
                            ? "blue"
                            : link === "UsersLocation"
                            ? "blue"
                            : theme.palette.secondary[100],
                        backgroundColor:
                          activeOption === lowerCaseText
                            ? "#B7E2FC"
                            : link === "UsersLocation"
                            ? "#ffd966"
                            : //  theme.palette.secondary[300]
                              "transparent",

                        "&:hover": {
                          backgroundColor:
                            activeOption === lowerCaseText &&
                            theme.palette.secondary[300],
                          opacity: activeOption === lowerCaseText && 0.8,
                        },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          ml: "1.8rem",
                          color:
                            activeOption === lowerCaseText
                              ? theme.palette.primary[600]
                              : theme.palette.secondary[200],
                        }}
                      >
                        {icon}
                      </ListItemIcon>
                      <ListItemText primary={text} />
                      {activeOption === lowerCaseText && (
                        <ChevronRightOutlined />
                      )}
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>

          {/* User Info and Avatar */}
        </Drawer>
      )}
    </Box>
  );
};

export default SideBarMenu;
