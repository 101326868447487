import React, { useState, useEffect } from 'react';
import { Box, Typography, Card, CardContent,  Grid } from '@mui/material';
import ProfileInformation from './ProfileInformation';
import UpdatePassword from './UpdatePassword';
import { getStorageItem } from '../../../Common/Utility';
import { blue } from '@mui/material/colors';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import { useLocation } from 'react-router-dom';

const UserProfile = ( ) => { 
  
  const [userId, setUserId] = useState(0);
  const location = useLocation();
  const [isIbanError,setIsIbanError]= useState("0");
  //const[isPasswordMustChange,setIsPasswordMustChange]=useState('0')
  const [isReadingStorage,setIsReadingStorage]= useState(true);
   
  useEffect(() => {
    try
    { 
      setIsReadingStorage(true)
    const fetchData = async () => {
      const userIdValue = await getStorageItem("login", "userId");
      if (userIdValue <= 0) {
        // Redirect to login page if userId is not valid
        return;
      }
      setUserId(userIdValue);    
    };

    fetchData();
    
  const queryParams = new URLSearchParams(location.search);
  setIsIbanError( queryParams.get('isIbanError'));
  //setIsPasswordMustChange(queryParams.get('isPasswordMustChange'));
    
  }
  catch(error)
  {
    console.error(error)
  }
  finally
  {
    setIsReadingStorage(false)
  }
  }, [location.search,isIbanError]); 
     
  if(isReadingStorage)
  {
    return(<div>Loading....</div>)
  }
 
  return (
    <Box p={2} maxWidth={800}>
      <Grid container alignItems="center" spacing={1} marginBottom={1}>
        <Grid item>
          <PersonPinIcon color="primary" fontSize="large" />
        </Grid>
        <Grid item>
          <Typography  
            variant="h5"            
            align="left"
            color={blue[500]}           
            ml={1}
          >
            Benutzerprofil
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Card variant="outlined" sx={{ borderRadius: 2, boxShadow: 5 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
              Profil Information
              </Typography>
              <ProfileInformation   userId={userId} isIbanError={isIbanError} />
                
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card variant="outlined" sx={{ borderRadius: 2, boxShadow: 5 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
              Passwort aktualisieren
              </Typography>
              <UpdatePassword  userId={userId}   />
            </CardContent>
          </Card>
        </Grid>

      </Grid>           
    </Box>
  );
};

export default UserProfile;