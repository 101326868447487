import React from "react";
import { Container, Paper, Typography, Button } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const RegistrationTokenInvalid = () => {
  const { error } = useParams();

  return (
    <Container component="main" maxWidth="sm">
      <Paper
        elevation={6}
        sx={{
          marginTop: "5vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "2em",
        }}
      >  
        <WarningAmberIcon
          sx={{
            fontSize: "4rem",
            color: "error.main",
            marginBottom: "20px",
          }}
        />
        <Typography
          variant="h4"
          component="h1"
          color="error"
          gutterBottom
        >
          Ungültige URL
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          paragraph
        >
          Entschuldigung! Der angeforderte Link ist nicht mehr gültig.
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          paragraph
        >
          The requested link is no longer valid.
        </Typography>
         {error &&  (
          <Typography
            variant="body2"
            sx={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
              color: "error.dark",
            }}
          >
            {error}
          </Typography>
        )}
      <Button
            component={Link}
            to="/register"
            variant="contained"
            color="primary"
            sx={{
              marginTop: "30px",
              ':hover': {
                bgcolor: 'primary',  
                color: '#fef',  
              },
            }}
          >              
          Konto erstellen
        </Button>
      </Paper>
    </Container>
  );
};

export default RegistrationTokenInvalid;
