import React from "react";
import * as XLSX from "xlsx";

import jsPDF from "jspdf";
import "jspdf-autotable";
import "./DataExportButtons.css"; // Import the CSS file
import { copyTableById } from "../../Common/ExportTableToXls";

const DataExportButtons = ({
  tableId,
  filename_csv,
  filename_excel,
  filename_pdf,
  //filename_bank_csv,
}) => {
  const handleCopy = () => {
    copyTableById(document, tableId);
  };

  function handleToCSV() {
    const table = document.getElementById(tableId);

    if (!table) {
      console.error(`Table with id "${tableId}" not found.`);
      return null;
    }

    const header = Array.from(table.rows[0].cells).map((cell) =>
      cell.textContent.trim()
    );
    const rows = [];

    for (let i = 1; i < table.rows.length; i++) {
      const row = Array.from(table.rows[i].cells).map((cell) =>
        cell.textContent.trim()
      );
      rows.push(row.join(","));
    }

    const csvContent = [header.join(","), ...rows].join("\n");

    const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csvContent], {
      type: "text/csv;charset=utf-8",
    });

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename_csv;

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  }

  function handleToExcel() {
    const table = document.getElementById(tableId);

    if (!table) {
      console.error(`Table with id "${tableId}" not found.`);
      return;
    }
    const header = Array.from(table.rows[0].cells).map((cell) =>
      cell.textContent.trim()
    );
    const data = [];

    for (let i = 1; i < table.rows.length; i++) {
      const row = Array.from(table.rows[i].cells).map((cell) =>
        cell.textContent.trim()
      );
      data.push(row);
    }

    // Prepare the workbook
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([header, ...data]);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");

    // Convert the workbook to an ArrayBuffer
    const arrayBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Create a Blob from the ArrayBuffer
    const blob = new Blob([arrayBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a download link and trigger a click event
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename_excel;

    document.body.appendChild(link);
    link.click();

    // Cleanup: Remove the link from the DOM
    document.body.removeChild(link);
  }

  function handleToPDF() {
    const table = document.getElementById(tableId);

    if (!table) {
      console.error(`Table with id "${tableId}" not found.`);
      return;
    }

    // Initialize jsPDF for A4 size
    const pdf = new jsPDF("p", "mm", "a4");

    // Set the table columns
    const columns = [];
    const numCols = table.rows[0].cells.length;
    for (let j = 0; j < numCols; j++) {
      columns.push({
        title: table.rows[0].cells[j].textContent.trim(),
        dataKey: j,
      });
    }

    // Set the table rows
    const data = [];
    for (let i = 1; i < table.rows.length; i++) {
      const rowData = {};
      for (let j = 0; j < numCols; j++) {
        rowData[j] = table.rows[i].cells[j].textContent.trim();
      }
      data.push(rowData);
    }

    // Adjust font size and other styles
    const styles = {
      headStyles: { fillColor: [200, 200, 200], textColor: 0, fontSize: 8 },
      bodyStyles: { textColor: 0, fontSize: 8 },
    };

    // Calculate the width of each column
    const columnWidths = columns.map((col, index) => {
      const cellWidths = data.map((row) =>
        pdf.getStringUnitWidth(row[index].toString())
      );
      return Math.max(pdf.getStringUnitWidth(col.title), ...cellWidths) + 6; // Add some padding
    });

    // Set the column widths in the styles
    styles.columnWidth = columnWidths;

    // Add table to PDF
    pdf.autoTable(columns, data, styles);

    // Save the PDF with a specified name
    pdf.save(filename_pdf);
  }

  return (
    <div className="data-export-buttons">
      <button className="export-button" onClick={handleCopy}>
        Copy
      </button>
      <button className="export-button" onClick={handleToCSV}>
        CSV
      </button>
      <button className="export-button" onClick={handleToExcel}>
        Excel
      </button>
      <button className="export-button" onClick={handleToPDF}>
        PDF
      </button>
    </div>
  );
};

export default DataExportButtons;
