import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  Marker,
  LoadScript,
  StandaloneSearchBox,
  InfoWindow,
} from "@react-google-maps/api";
import { Get } from "../../../../Common/WebRequest";
import "./search.css";
import "./map.css";
import "./result.css";

const center = {
  lat: 52.37565568071905,
  lng: 9.736750434517248,
};
// 52.4349412582634, 13.540581179217238
//52.43633863099732, 13.54150863909218

const libraries = ["places", "geometry", "drawing", "visualization", "marker"];

const UserLocationMap = () => {
  const [map, setMap] = useState(null);
  const [searchBox, setSearchBox] = useState(null);
  const [initialLocations, setInitialLocations] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [nearestLocations, setNearestLocations] = useState([]);
  const [directionsRenderers, setDirectionsRenderers] = useState([]);
  const [circles, setCircles] = useState([]);
  const [travelMode, setTravelMode] = useState("DRIVING");
  const [nearestTeacher, setNearestTeacher] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState(null);

  const [isLoaded, setIsLoaded] = useState(false);
  const [apiKey, setApiKey] = useState(null);
  const [error, setError] = useState(null);
  //const [zoom, setZoom] = useState(10);
  const maxPaths = 1;
  const nearestCount = 5;

  const mapStyles = [
    {
      featureType: "poi",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "transit",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
  ];

  useEffect(() => {
    const fetchApiKey = async () => {
      try {
        const googleMapInfo = await Get("CompanyInformation/GoogleMapInfo");
        if (googleMapInfo.isSuccess) {
          setApiKey(googleMapInfo.result.googleApiKey);
        } else {
          setError(googleMapInfo.Status + "/" + googleMapInfo.Error);
        }
      } catch (error) {
        setError("Error fetching region data: " + error.message);
      } finally {
      }
    };
    fetchApiKey();
  }, []);
  useEffect(() => {
    (async () => {
      try {
        const res = await Get("User/User-Location"); // GET all locations

        if (res.isSuccess) {
          setInitialLocations(convertLocations(res.result));
          setIsLoaded(true);
        }
      } catch (error) {
        console.error("Error fetching SystemUpdate..data:", error);
      }
    })();
  }, []);
  useEffect(() => {
    return () =>
      directionsRenderers.forEach((renderer) => renderer.setMap(null)); // Cleanup renderers on unmount
  }, [directionsRenderers]);

  useEffect(() => {
    // Load initial markers after the map has loaded
    if (map) {
      const timeout = setTimeout(() => {
        //setMarkers(initialLocations);
        setMarkers(nearestLocations);
      }, 50); // Delayed rendering to ensure map data is fully available
      return () => clearTimeout(timeout);
    }
  }, [map, nearestLocations]); // Dependency on map ensures this runs once the map is ready

  const onMapLoad = (map) => {
    if (!apiKey) return;
    setMap(map);
  };

  const onSearchBoxLoad = (searchBox) => {
    setSearchBox(searchBox);
  };

  const showThePath = (travelMode) => {
    try {
      const places = searchBox.getPlaces();
      if (places && places.length > 0) {
        const origin = places[0].geometry.location;

        const location = {
          lat: origin.lat(),
          lng: origin.lng(),
        };
        circles.forEach((circle) => circle.setMap(null));
        setCircles([]); // Reset the circles state
        const sortedLocations = getThreeClosestLocations(
          location,
          initialLocations
        );
        calculateAndDisplayRoute(origin, sortedLocations, travelMode);
        setNearestTeacher(sortedLocations[0]);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };
  const onPlacesChanged = () => {
    showThePath(travelMode);
  };
  const calculateAndDisplayRoute = (origin, sortedLocations, travelMode) => {
    const directionsService = new window.google.maps.DirectionsService();

    let routeResults = [];

    sortedLocations.forEach((destination, index) => {
      directionsService.route(
        {
          origin: origin,
          destination: destination,
          travelMode: window.google.maps.TravelMode[travelMode], //window.google.maps.TravelMode.DRIVING,

          provideRouteAlternatives: true,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            // Store result with distance for sorting
            routeResults.push({
              result: result,
              distance: result.routes[0].legs[0].distance.value, // Distance in meters
            });

            // Ensure all routes are processed before rendering
            if (routeResults.length === sortedLocations.length) {
              routeResults.sort((a, b) => a.distance - b.distance); // Sort by distance
              renderRoutes(routeResults.slice(0, maxPaths)); // Only take the one  shortest path
            }
          } else {
            console.error(`Directions request failed due to ${status}`);
          }
        }
      );
    });
  };
  function convertLocations(dbLocations) {
    return dbLocations.map((location, index) => ({
      lat: location.latitude,
      lng: location.longitude,
      name: location.fullName,
      address:
        location.street + ", " + location.zip + ", " + location.regionName,
    }));
  }
  const renderRoutes = (routeResults) => {
    // Clear previous renderers
    routeResults.reverse();
    const bestDistance = routeResults[maxPaths - 1].distance;
    directionsRenderers.forEach((renderer) => renderer.setMap(null));
    setDirectionsRenderers([]);

    // Render each route with a delay
    routeResults.forEach((routeResult, index) => {
      setTimeout(() => {
        // Delay rendering of each route
        const colors = ["#FA0000", "#bb29ba", "#459cff"]; // Green, Orange, Red from shortest to longest
        const directionsRenderer = new window.google.maps.DirectionsRenderer({
          map: map,
          directions: routeResult.result,
          /*markerOptions: {
            icon: {
              url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
              scaledSize: new window.google.maps.Size(48, 48),
            },
          },*/

          /*polylineOptions: {
            strokeColor: colors[index],
            strokeOpacity: 0.5,
            strokeWeight: 15,
          },*/
        });

        setDirectionsRenderers((prev) => [...prev, directionsRenderer]);
        if (index === 0) {
          // Draw circle for the nearest route
          drawCircle(
            routeResult.result.routes[0].legs[0].start_location,
            bestDistance
          );
        }
      }, 1500 * index); // 500 milliseconds delay for each subsequent route
    });

    //setZoom(10);
  };
  const drawCircle = (center, radius) => {
    const newCircle = new window.google.maps.Circle({
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FA0F00",
      fillOpacity: 0.1,
      map: map,
      center: center,

      radius: radius + 5, // Radius is in meters
    });

    // Add the new circle to the state
    setCircles((prevCircles) => [...prevCircles, newCircle]);

    /*map.setCenter(newCircle.getCenter());
    setTimeout(() => {
      map.setZoom(18);
    }, 100);*/
  };

  const handleTravelModeChange = (event) => {
    setTravelMode(event.target.value);
    showThePath(event.target.value);
  };

  function euclideanDistance(loc1, loc2) {
    let latDiff = loc1.lat - loc2.lat;
    let lngDiff = loc1.lng - loc2.lng;
    let distance = Math.sqrt(latDiff * latDiff + lngDiff * lngDiff);

    return distance;
  }

  function getThreeClosestLocations(targetLocation, locations) {
    // Copy the locations array to avoid mutating the original data
    let sortedLocations = locations.slice();

    // Sort the copied array based on the distance to the target location
    sortedLocations.sort((a, b) => {
      return (
        euclideanDistance(targetLocation, a) -
        euclideanDistance(targetLocation, b)
      );
    });

    // Return only the top 1 closest locations
    if (sortedLocations.length >= nearestCount)
      setNearestLocations(sortedLocations.slice(0, nearestCount));
    else setNearestLocations(sortedLocations);
    return sortedLocations.slice(0, maxPaths);
  }
  // check if isloaded is true
  if (!isLoaded || !apiKey) {
    return <div>Loading.............</div>;
  }
  return (
    <LoadScript googleMapsApiKey={apiKey} libraries={libraries} loading="async">
      <div
        style={{
          paddingTop: "5px",
          marginBottom: "50px",
          display: "flow",
          align: "center",
        }}
      >
        <br />
        {error && <p style={{ color: "red" }}>{error}</p>}
        <div className="containerSearch">
          <div className="selection-group">
            <label htmlFor="searchaddress">Ort: </label>
            <StandaloneSearchBox
              onLoad={onSearchBoxLoad}
              onPlacesChanged={onPlacesChanged}
            >
              <input type="text" placeholder="Orten suchen..." autoFocus />
            </StandaloneSearchBox>

            <select
              id="travelMode"
              name="travelMode"
              value={travelMode}
              onChange={handleTravelModeChange}
            >
              <option value="DRIVING" style={{ fontWeight: "bold" }}>
                Driving
              </option>
              <option value="WALKING">Walking</option>
              <option value="BICYCLING">Bicycling</option>
              <option value="TRANSIT">Transit</option>
            </select>
          </div>
        </div>
        <div className="containerResult">
          <div className="result-group">
            <label htmlFor="nearestTeacher">Nächster Lehrer:</label>
            <input
              type="text"
              id="nearestTeacher"
              readOnly={true}
              value={nearestTeacher ? nearestTeacher.name : ""}
            />
            <label htmlFor="nearestAddress">Adresse:</label>
            <input
              type="text"
              id="nearestAddress"
              readOnly={true}
              value={nearestTeacher ? nearestTeacher.address : ""}
            />
          </div>
        </div>

        <GoogleMap
          //   mapContainerStyle={containerStyle}
          mapContainerClassName="mapContainer"
          center={center}
          zoom={10}
          onLoad={onMapLoad}
          options={{
            styles: mapStyles,
            disableDefaultUI: true, // Disables all the default UI
            zoomControl: true, // Display zoom controls
            mapTypeControl: true, // Allow the user to change map type (roadmap, satellite, etc.)
            scaleControl: true, // Display scale control
            streetViewControl: true, // Enable Street View
            rotateControl: true, // Enables rotation controls
            fullscreenControl: true, // Allows entering fullscreen mode
          }}
        >
          {markers.map((marker, index) => (
            <Marker
              key={index}
              position={{ lat: marker.lat, lng: marker.lng }}
              label={{
                text: marker.name,
                color: "blue",
                fontWeight: "bold",
                fontSize: "14px",
              }}
              opacity={0.8}
              cursor="pointer"
              icon={
                marker.icon
                  ? {
                      url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
                      scaledSize: new window.google.maps.Size(48, 48), // Optional: adjust the size of the icon
                    }
                  : {
                      url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
                      scaledSize: new window.google.maps.Size(48, 48), // Optional: adjust the size of the icon
                    }
              }
              onClick={() => {
                setSelectedMarker(marker);
                setOpen(true);
              }}
            />
          ))}
          {open && selectedMarker && (
            <InfoWindow
              position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
              onCloseClick={() => setOpen(false)}
            >
              <div>
                <h6>{selectedMarker.name}</h6>
                <p>{selectedMarker.address}</p>{" "}
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </div>
    </LoadScript>
  );
};

export default UserLocationMap;
