import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { GridRowModes, DataGrid } from "@mui/x-data-grid";
import "./UserLoginsGrid.css";
import { useEffect, useState } from "react";

import EditToolbar from "./EditToolbar";
import columnsBuilder from "./columnsBuilder";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SuccessIcon from "@mui/icons-material/CheckCircleOutline";

import {
  getStorageItem,
  getValue,
  slice,
} from "../../../../../../Common/Utility";
import { Delete, Get, Post, Put } from "../../../../../../Common/WebRequest";
import DeleteConfirmation from "../../../../../../components/DeleteConfirmation";
import UserDialog from "./UserDialog";
EditToolbar.propTypes = {
  setRowModesModel: PropTypes.func.isRequired,
  setUserData: PropTypes.func.isRequired,
  handleAddUpdateUser: PropTypes.func.isRequired,
  fieldToFocus: PropTypes.any,
  exportFileName: PropTypes.any,
};

export default function UsersDataGrid(props) {
  const [error, setError] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [userData, setUserData] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [reload, setReload] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [UserId, setUserId] = useState(0);
  const [userIdCreate, setUserIdCreate] = useState(0);
  let fieldToFocus = "fullName";
  let exportFileName = "Managers";
  const [lastSavedUserId, setLastSavedUserId] = useState(null);
  const [selectionModel, setSelectionModel] = useState([]);

  useEffect(() => {
    if (lastSavedUserId !== null && lastSavedUserId > 0) {
      selectRowByUserId(lastSavedUserId);
      console.log("Selected row : " + lastSavedUserId);
    }
  }, [userData]);

  useEffect(() => {
    (async () => {
      try {
        let userIdCreateValue = await getStorageItem("login", "userId");
        if (getValue(userIdCreateValue) === 0) {
          setError("User create Id = 0");
          return;
        }
        setUserIdCreate(userIdCreateValue);
        let res = await Get("User/Managers");

        if (res && res.isSuccess) {
          setUserData(res.result);
        } else {
          if (res.Status === 403) {
            setError("Access Denied (403).");
            return;
          }
          throw new Error(`Failed to fetch managers:`);
        }
      } catch (error) {
        console.error(error);
        if (error.Status === 403) {
          setError("Access Denied (403).");
        } else {
          setError(error.message || "An unexpected error occurred.");
        }
      }
    })();
  }, [reload]);

  const [type, setType] = useState(null);

  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);

  const showDeleteModal = (type, userId) => {
    setType(type);
    setUserId(userId);
    setDeleteMessage(
      `Möchten Sie „${
        userData.find((x) => x.userId === userId).fullName
      }“ wirklich löschen?`
    );
    setDisplayConfirmationModal(true);
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const handleOpenDialog = () => {
    setReload(!reload);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleSaveUserData = async (userIdSaved) => {
    setReload(!reload);
    console.log("--> user id saved: " + userIdSaved);
    setLastSavedUserId(userIdSaved);
  };

  async function handleAddUpdateUser(userId) {
    setError("");
    setSuccessMsg("");
    if (userId === 0) {
      setUserId(0);
      handleOpenDialog();
    } else if (userId > 0) {
      handleOpenDialog();
    }
  }

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (userId) => () => {
    setSuccessMsg("");
    setRowModesModel({
      ...rowModesModel,
      [userId]: { mode: GridRowModes.Edit, fieldToFocus: "fullName" },
    });
  };

  // to select last edited/added row after save
  function selectRowByUserId(userId) {
    if (!userData || userData.length === 0) {
      return;
    }

    const selectedRow = userData.find((r) => r.userId === userId);
    if (selectedRow) {
      handleOnRowClick({ row: selectedRow });
    } else {
      console.log("Error: Row not found for userId:", userId);
    }
  }

  function handleOnRowClick(params) {
    const clickedRow = params.row;
    if (clickedRow) {
      setSelectionModel(clickedRow.userId);
      props.onSelectUser(
        clickedRow.userId,
        clickedRow.isActive,
        clickedRow.groupId,
        clickedRow.fullName
      );
      setLastSavedUserId(0);
    }
  }

  async function DoSaveApi(newRow, updatedRow) {
    let res = "";
    let isSuccess = false;
    setError("");
    setSuccessMsg("");
    setUserData(
      userData.map((row) => (row.userId === newRow.userId ? updatedRow : row))
    );
    if (updatedRow.userId === 0) {
      updatedRow.isNew = true;
      updatedRow.userIdCreate = userIdCreate;
      res = await Post("User", updatedRow);
      isSuccess = res.isSuccess;
    } else {
      newRow.userIdCreate = userIdCreate;
      res = await Put("User", updatedRow.userId, newRow);
      isSuccess = res.isSuccess;
    }
    if (isSuccess) {
      let params = {};
      params.row = res.result;
      setReload(!reload);
      handleOnRowClick(params);
      props.OnSave();
      updatedRow.isNew
        ? setSuccessMsg("Der neue Benutzer wurde hinzugefügt.")
        : setSuccessMsg("Der Benutzer wurde aktualisiert.");
    } else {
      setError(res.Status + "/" + res.Error);
      setReload(!reload);
      console.log("Error: ##### ", res.Error);
    }
  }

  const handleDeleteClick = (userId) => () => {
    showDeleteModal("", userId);
  };

  async function doDeleteApi() {
    setError("");
    setSuccessMsg("");
    let res = await Delete("user", UserId);
    if (!res.isSuccess) {
      setError(res.Status + "/" + res.Error);
      console.log("Error:", res.Status + "/" + res.Error);
    } else {
      setSuccessMsg("Der Benutzer wurde erfolgreich gelöscht.");
      setReload(!reload);
      setUserId(0);
      const selectedRow = userData[0];
      handleOnRowClick({ row: selectedRow });
    }
  }

  const handleAfterDeleteClick = () => {
    setDisplayConfirmationModal(false);
    doDeleteApi();
  };

  const handleCancelClick = (userId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [userId]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = userData.find((row) => row.userId === userId);
    if (editedRow.isNew) {
      setUserData(userData.filter((row) => row.userId !== userId));
    }
  };

  const handleError = (err) => {
    setError(err.message);
  };

  const handleSaveClick = (userId) => async (event) => {
    event.stopPropagation();
    setRowModesModel({
      ...rowModesModel,
      [userId]: { mode: GridRowModes.View },
    });
  };

  async function processRowUpdate(newRow) {
    newRow = {
      ...newRow,
    };
    const updatedRow = {
      ...newRow,
      isNew: false,
    };
    DoSaveApi(newRow, updatedRow);
    return updatedRow;
  }

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = columnsBuilder({
    rowModesModel,
    handleEditClick,
    handleCancelClick,
    handleSaveClick,
    handleDeleteClick,
  });

  async function handleOnRowDoubleClick(params, event) {
    if (params.row) {
      setUserId(params.row.userId);
      handleAddUpdateUser(params.row.userId);
    }
  }
  const handleSelectionModelChange = (newSelection) => {
    setSelectionModel(newSelection);
  };

  return (
    <Box
      sx={{
        height: 100,
        maxHeight: 100,
        display: "contents",
        width: "95%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      {error.length > 0 && (
        <div className="alert alert-danger" role="alert">
          <HighlightOffIcon></HighlightOffIcon> {slice(error, 180, "...")}
        </div>
      )}

      {error.length === 0 && successMsg.length > 0 && (
        <div className="alert alert-success" role="alert">
          <SuccessIcon></SuccessIcon> {slice(successMsg, 100, "...")}
        </div>
      )}

      <UserDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        onSave={handleSaveUserData}
        relaod={reload}
        IsForPermission={true}
        UserId={UserId}
      />
      <div style={{ height: 300, width: "90%" }}>
        <DataGrid
          hideFooter={true}
          rowSelection={false}
          getRowClassName={(params) =>
            params.row.userId === selectionModel && !params.row.isActive
              ? "inactive-row-selected"
              : params.row.userId === selectionModel && params.row.isAdmin
              ? "admin-row-selected"
              : params.row.userId === selectionModel
              ? "Mui-selected"
              : params.row.isAdmin
              ? "admin-row"
              : !params.row.isActive
              ? "inactive-row"
              : ""
          }
          // Other necessary props

          density="compact"
          rows={userData}
          columns={columns}
          editMode="row"
          initialState={{
            columns: {
              ...userData.initialState?.columns,
              columnVisibilityModel: {
                groupId: false,
              },
            },
            pagination: {
              paginationModel: { pageSize: 100, page: 0 },
            },
          }}
          rowModesModel={rowModesModel}
          selectionModel={selectionModel}
          onRowModesModelChange={handleRowModesModelChange}
          onSelectionModelChange={handleSelectionModelChange}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          onRowClick={handleOnRowClick}
          onRowDoubleClick={handleOnRowDoubleClick}
          onCellEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleError}
          sx={{
            cursor: "pointer", // Applies cursor: pointer to the entire DataGrid
          }}
          slots={{
            toolbar: EditToolbar,
          }}
          slotProps={{
            toolbar: {
              setUserData,
              setRowModesModel,
              handleAddUpdateUser,
              fieldToFocus,
              exportFileName,
            },
          }}
          getRowId={(row) => row.userId}
        />
      </div>
      <DeleteConfirmation
        showModal={displayConfirmationModal}
        confirmModal={handleAfterDeleteClick}
        hideModal={hideConfirmationModal}
        type={type}
        id={UserId}
        message={deleteMessage}
      />
    </Box>
  );
}
