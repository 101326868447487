import React, { useState } from "react";
import {
  Container,
  Typography,
  Paper,
  TextField,
  Button,
  Grid,
} from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import PasswordIcon from "@mui/icons-material/Password";
import { getParamValueFromURL } from "../../../Common/Utility";
import { Post } from "../../../Common/WebRequest";

const PasswordResetForm = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [tokeError, setTokenError] = useState(null);

  const navigate = useNavigate();

  const token = getParamValueFromURL(window.location.search, "token");

  const [passwordSent, setPasswordSent] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      // Redirect to login if accessToken is not provided or empty
      if (!token || token.trim() === "" || token.length < 10) {
        navigate("/login");
      } else {
        try {
          let result = await Post("PasswordRecovery/ValidateToken", {
            Token: token,
          });

          if (!result.isSuccess) {
            navigate(`/PasswordResetInvalid/${result.Error}`);
            setTokenError(result.Error);
            console.error(result.Error);
            setPasswordError(result.Error);
            // redirect
          }
        } catch (error) {
          console.error("Error validating token:", error);
        }
      }
    };

    fetchData();
  }, [token, navigate]);

  useEffect(() => {
    if (passwordSent) {
      navigate("/PasswordResetOk");
      //navigate(`/login`);
    }
  }, [passwordSent, navigate]);

  if (!token || token.trim() === "" || token.length < 10) {
    return null; // To avoid rendering anything in this case
  }

  const validatePassword = () => {
    if (password.length < 6) {
      setPasswordError("Das Passwort muss mindestens 6 Zeichen lang sein.");
      return false;
    }

    // Ensure  > 6 and at least 1 number and one letter and one special char
    const hasMinimumLength = password.length >= 6;
    const letterCount = (password.match(/[a-zA-Z]/g) || []).length;
    const digitCount = (password.match(/\d/g) || []).length;

    if (hasMinimumLength && letterCount >= 1 && digitCount >= 1) {
      setPasswordError(null);
    } else {
      const passPolicy =
        "Das Passwort muss den folgenden Kriterien entsprechen:\n" +
        "- Mindestlänge von 6 Zeichen.\n" +
        "- Mindestens ein Buchstabe.\n" +
        "- Mindestens eine Zahl.\n";

      setPasswordError(passPolicy);
      return false;
    }

    if (password !== confirmPassword) {
      setPasswordError("Passwort stimmt nicht überein.");
      return false;
    }

    setPasswordError("");
    return true;
  };

  async function handleResetPassword() {
    if (validatePassword()) {
      let passData = {
        resetToken: token,
        newPassword: password,
      };
      let res = await Post(`PasswordRecovery/PasswordReset`, passData);

      if (res.isSuccess) {
        setPasswordError("");
        setPasswordSent(true);
      } else {
        setTokenError(res.Error);
        setPasswordError(res.Error);
        setPasswordSent(false);
      }
    }
  }
  async function handleRecoverPassword() {
    navigate("/passwordrecovery");
  }

  return (
    <Container component="main" maxWidth="xs">
      <Paper
        elevation={3}
        style={{ padding: "2em", marginTop: "8vh", textAlign: "center" }}
      >
        <Typography variant="h5" gutterBottom>
          Passwort zurücksetzen
        </Typography>

        <br />
        <PasswordIcon
          fontSize="large"
          style={{
            verticalAlign: "middle",
            marginRight: "8px",
            color: "lightblue",
          }}
        />
        <br />
        <br />
        <br />
        <form>
          <Grid container spacing={1}>
            <Grid item xs={10}>
              <Typography
                variant="body2"
                color="textSecondary"
                gutterBottom
                style={{ textAlign: "left" }}
              >
                Das Passwort muss den folgenden Kriterien entsprechen:
                <br />
                <br />
                1- Mindestlänge von 6 Zeichen.
                <br />
                2- Mindestens ein Buchstabe.
                <br />
                3- Mindestens eine Zahl.
                <br />
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                type="password"
                label="Neues Kennwort"
                variant="outlined"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={!!passwordError}
                helperText={passwordError}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                type="password"
                label="Passwort Bestätigung"
                variant="outlined"
                required
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                error={!!passwordError}
              />
            </Grid>

            <Grid item xs={12} style={{ margin: 10, alignItems: "center" }}>
              <label
                style={{
                  fontSize: 20,
                  color: "red",
                  display: "block",
                  alignItems: "center",
                }}
              >
                {tokeError != null && tokeError.length > 0 ? tokeError : ""}
              </label>
            </Grid>
          </Grid>
          <br />

          {tokeError === null || tokeError.length === 0 ? (
            <Button
              fullWidth
              variant="contained"
              color="primary"
              style={{ marginTop: "1.5em" }}
              onClick={handleResetPassword}
            >
              Passwort zurücksetzen
            </Button>
          ) : (
            <Button
              fullWidth
              variant="contained"
              color="primary"
              style={{ marginTop: "1.5em" }}
              onClick={handleRecoverPassword} // Recover by E-Mail
            >
              Passwort-Wiederherstellung
            </Button>
          )}
        </form>
      </Paper>
    </Container>
  );
};

export default PasswordResetForm;
