import React, { useEffect, useState, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {  Box, Container, Typography } from "@mui/material";
import PropTypes from "prop-types";


import columnsBuilder from "./columnsBuilder";
import { blue } from "@mui/material/colors";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EditToolbar from "./EditToolbar";
import { getToDay, slice } from "../../../../Common/Utility";
import { Get } from "../../../../Common/WebRequest";
EditToolbar.propTypes = {
  setRowModesModel: PropTypes.func.isRequired,

  exportFileName: PropTypes.any,
};
const UserSystemLoginsMonitor = () => {
  const [userSystemLogins, setUserSystemLogins] = useState([]);
  const [error, setError] = useState("");
  const [rowModesModel, setRowModesModel] = useState({});
  const columns = columnsBuilder();
  let exportFileName = "UserSystemLoginData";
  const dateFromRef = useRef();
  const dateToRef = useRef();
 
  useEffect(() => {
    (async () => {
           
      await loadData();
    })();
  }, [ dateFromRef, dateToRef]);

  async function loadData() {
    try {
    setError("");
    setUserSystemLogins([]);
    
    let res = await Get(
      "UserSystemLogin/all-user-system-login/" +
      dateFromRef.current.value +
        "/" +
        dateToRef.current.value
    );
    if (res.isSuccess) {
      setUserSystemLogins(res.result);
    } else {
      if (res.result && !res.result.includes("200/No Results"))
        setError(res.Status + "/" + res.Error + "/" + res.ErrorDetail);
    }
  }
  catch (error) {
    console.error(error);
    setError(error.message || "An unexpected error occurred.");
  }  
}
  const handleFetchClick = async(event) => {
    event.preventDefault();
    const fromDate = dateFromRef.current.value;
    const toDate = dateToRef.current.value;
    await loadData(fromDate, toDate);
  };   

  if (error) {
    return <Container><Typography color="error">{error}</Typography></Container>;
  }

  return (
    <Box >
      <br></br>
      <div className="App" style={{ backgroundColor: "white" }}>
        <Typography
          variant="headline"
          component="h2"
          align="left"
          color={blue[500]}
        >
          User Logins
        </Typography>
      </div>
      <br />
      <form>
        <label>
          Von
          <input
            className="form-control"
            id="from"
            type="date"
            ref={dateFromRef}
            defaultValue={getToDay()}
          ></input>
        </label>
        <label>-</label>
        <label>
          Zum
          <input
            className="form-control"
            id="to"
            type="date"
            ref={dateToRef}
            defaultValue={getToDay()}
          ></input>
        </label>
        &nbsp;&nbsp;&nbsp;
        <button className="btn btn-primary" onClick={handleFetchClick}>
          Vorschu
        </button>
      </form>
      {error.length > 0 && (
        <div className="alert alert-danger" role="alert">
          <HighlightOffIcon></HighlightOffIcon> {slice(error, 100, "...")}
        </div>
      )}       
      <DataGrid
        rowSelection={false}
        density="compact"
        rows={ userSystemLogins}
        columns={columns}
        editMode="row"
        rowsPerPageOptions={5}
        // hideFooterPagination
        initialState={{
          pagination: {
            paginationModel: { pageSize: 100, page: 0 },
          },
          filter: {
            filterModel: {
              items: [
                { field: "userName", operator: "contains", value: "" },
              ],
            },
          },
        }}
        rowModesModel={rowModesModel}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: {
            setUserSystemLogins,
            setRowModesModel,
            exportFileName,
          },
        }}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd"
        }
      />
    </Box>
  );
};

export default UserSystemLoginsMonitor;
