import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";

import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import SyncIcon from "@mui/icons-material/Sync";

const EditToolbar = (props) => {
  const { exportFileName } = props;

  let navigate = useNavigate();

  const handleAddClick = () => {
    props.handleAddUpdateUser(0);
  };

  function handleRefresh() {
    navigate(0);
  }

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleAddClick}>
        Neu
      </Button>

      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport
        csvOptions={{
          fileName: `${exportFileName}`,
          delimiter: ";",
          utf8WithBom: true,
        }}
      />

      <GridToolbarQuickFilter />
      <Button color="primary" startIcon={<SyncIcon />} onClick={handleRefresh}>
        Refresh
      </Button>
    </GridToolbarContainer>
  );
};
export default EditToolbar;
