import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import SyncIcon from "@mui/icons-material/Sync";
import {
  GridRowModes,
  GridToolbarContainer,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarQuickFilter,
  DataGrid,
} from "@mui/x-data-grid";

import { useState } from "react";
import { useEffect } from "react";
import {
  AddErrorLog,
  Delete,
  Get,
  Post,
  Put,
} from "../../../../Common/WebRequest";
import DeleteConfirmation from "../../../../components/DeleteConfirmation";
import { useNavigate } from "react-router-dom";
import { getStorageItem, slice } from "../../../../Common/Utility";

function EditToolbar(props) {
  const { setRegionsData, setRowModesModel } = props;
  let navigate = useNavigate();

  const handleAddClick = () => {
    const regionId = 0;
    setRegionsData((oldRows) => [
      ...oldRows,
      { regionId, regionName: "", isNew: true },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [regionId]: { mode: GridRowModes.Edit, fieldToFocus: "regionName" },
    }));
  };

  const handleRefresh = () => {
    navigate(0);
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleAddClick}>
        Neu
      </Button>

      <GridToolbarColumnsButton />
      <GridToolbarFilterButton field="regionName" />
      <GridToolbarDensitySelector />
      <GridToolbarExport
        csvOptions={{
          fileName: "Regionen",
          delimiter: ";",
          utf8WithBom: true,
        }}
      />

      <GridToolbarQuickFilter />
      <Button color="primary" startIcon={<SyncIcon />} onClick={handleRefresh}>
        Refresh
      </Button>
    </GridToolbarContainer>
  );
}

EditToolbar.propTypes = {
  setRowModesModel: PropTypes.func.isRequired,
  setRegionsData: PropTypes.func.isRequired,
};

export default function RegionsGrid() {
  const [error, setError] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [regionsData, setRegionsData] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [reload, setReload] = useState(false);
  const [userId, setUserId] = useState(0);

  useEffect(() => {
    (async () => {
      let res = await Get("region/Permission-Allowed");

      if (res.isSuccess) {
        setRegionsData(res.result);
        setUserId(await getStorageItem("login", "userId"));
      } else {
        setError(res.Status + "/" + res.Error);
        // await AddErrorLog(err);
      }
    })();
  }, [reload]);

  const [type, setType] = useState(null);
  const [regionId, setRegionId] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);

  const showDeleteModal = (type, regionId) => {
    setType(type);
    setRegionId(regionId);

    setDeleteMessage(
      `Möchten Sie „${
        regionsData.find((x) => x.regionId === regionId).regionName
      }“ wirklich löschen?`
    );

    setDisplayConfirmationModal(true);
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (regionId) => () => {
    setSuccessMsg("");
    setRowModesModel({
      ...rowModesModel,
      [regionId]: { mode: GridRowModes.Edit, fieldToFocus: "regionName" },
    });
  };

  async function DoSaveApi(newRow, updatedRow) {
    try {
      let res = "";
      let isSuccess = false;
      setError("");
      setSuccessMsg("");
      setRegionsData(
        regionsData.map((row) =>
          row.regionId === newRow.regionId ? updatedRow : row
        )
      );
      if (updatedRow.regionId === 0) {
        updatedRow.isNew = true;
        updatedRow.userId = userId;
        res = await Post("region", updatedRow);
        isSuccess = res.isSuccess;
      } else {
        updatedRow.userId = userId;
        res = await Put("region", updatedRow.regionId, newRow);
        isSuccess = res.isSuccess;
      }
      if (isSuccess) {
        setReload(!reload);
        updatedRow.isNew
          ? setSuccessMsg("Die neue Region wurde hinzugefügt.")
          : setSuccessMsg("Die Region wurde aktualisiert.");
      } else {
        setError(res.Status + "/" + res.Error);
        setReload(!reload);
      }
    } catch (err) {
      console.error(err);
      await AddErrorLog(err);
    }
  }

  const handleDeleteClick = (regionId) => () => {
    showDeleteModal("", regionId);
  };

  async function doDeleteApi() {
    setError("");
    setSuccessMsg("");
    let res = await Delete("region", regionId);
    if (!res.isSuccess) {
      setError(res.Status + "/" + res.Error);
    } else {
      setSuccessMsg("Die Region wurde erfolgreich gelöscht.");
      setReload(!reload);
    }
  }

  const handleAfterDeleteClick = () => {
    setDisplayConfirmationModal(false);
    doDeleteApi();
  };

  const handleCancelClick = (regionId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [regionId]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = regionsData.find((row) => row.regionId === regionId);
    if (editedRow.isNew) {
      setRegionsData(regionsData.filter((row) => row.regionId !== regionId));
    }
  };

  const handleError = (err) => {
    setError(err.message);
  };

  const handleSaveClick = (regionId) => async (event) => {
    event.stopPropagation();
    setRowModesModel({
      ...rowModesModel,
      [regionId]: { mode: GridRowModes.View },
    });
  };

  async function processRowUpdate(newRow) {
    const updatedRow = { ...newRow, isNew: false };
    DoSaveApi(newRow, updatedRow);
    return updatedRow;
  }

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    { field: "regionId", headerName: "ID", width: 50, editable: false },
    {
      field: "regionName",
      headerName: "Region *",
      type: "text",
      editable: true,
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: 800,
        width: "95%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <br />
      <h2>Regionen</h2>
      <br />
      {error.length > 0 && (
        <div className="alert alert-danger" role="alert">
          {slice(error, 200, "...")}
        </div>
      )}

      {error.length === 0 && successMsg.length > 0 && (
        <div className="alert alert-success" role="alert">
          {slice(successMsg, 100, "...")}
        </div>
      )}
      <br />
      <DataGrid
        rowSelection={false}
        density="compact"
        hideFooterPagination
        rows={regionsData}
        columns={columns}
        getRowId={(row) => row.regionId}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleError}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { setRegionsData, setRowModesModel },
        }}
      />

      <DeleteConfirmation
        showModal={displayConfirmationModal}
        confirmModal={handleAfterDeleteClick}
        hideModal={hideConfirmationModal}
        type={type}
        id={regionId}
        message={deleteMessage}
      />
    </Box>
  );
}
