import React from 'react';
import { Modal, Paper, Grid, Typography, Button } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const ErrorMessage = ({ open, onClose, title, body }) => {
  const handleBackdropClick = (event) => {
    // Prevent clicks outside the modal from closing it
    event.stopPropagation();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="error-modal-title"
      aria-describedby="error-modal-description"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      slotProps={{
        backdrop: {
          onClick: handleBackdropClick,
        },
      }}
    >
      <Paper
        style={{
          padding: '32px',
          maxWidth: '800px',
          width: '450px',
          height: '300px',
          textAlign: 'center',
        }}
        elevation={5}
      >
        <Grid container spacing={2} justifyContent="center" alignItems="center" marginTop={1}>
          <Grid item>
            <ErrorOutlineIcon sx={{ fontSize: 50, color: '#f44336' }} />
          </Grid>
          <Grid item>
            <Typography variant="h4" style={{ color: '#f44336', fontWeight: 'bold' }}>
              {title}
            </Typography>
          </Grid>
        </Grid>
        <Typography 
            variant="h6" 
            component="pre" 
            style={{ 
              marginBottom: '20px', 
              marginTop: '10px',
              whiteSpace: 'pre-line'  // This will allow multiline rendering
            }}
          >
            {body}
        </Typography>

        <Button variant="contained" color="primary" onClick={onClose} style={{ marginTop: '20px', width: '100px' }}>
          OK
        </Button>
      </Paper>
    </Modal>
  );
};

export default ErrorMessage;
