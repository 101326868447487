import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
const columnsBuilder = (props) => {
  const {
    //   rowModesModel,
    //   handleEditClick,
    //   handleCancelClick,
    //   handleSaveClick,
    regionData,
  } = props;

  const columns = [
    { field: "userId", headerName: "ID", width: "70" },
    { field: "fullName", headerName: "Name", flex: 1 },
    { field: "street", headerName: "Straße", width: 200 },
    { field: "zip", headerName: "PLZ", width: 80 },
    {
      field: "regionId",
      headerName: "Region *",
      type: "singleSelect",
      editable: false,
      width: 150,
      getOptionValue: (region) => region?.regionId,
      getOptionLabel: (region) => region?.regionName,
      valueOptions: regionData /* loaded regions from GET API*/,

      valueFormatter: function (params) {
        if (regionData) {
          let region = regionData.find(
            (region) => region.regionId === params.value
          );
          if (typeof region?.regionName !== "undefined")
            return region.regionName;
        }
      },
    },
    { field: "email", headerName: "E-Mail", flex: 2 },
    {
      field: "latitude",
      headerName: "Address-Map",
      width: 100,
      type: "boolean",
      editable: false,
      renderCell: (params) => {
        if (!params.row.longitude || !params.row.latitude) {
          return (
            <ReportProblemOutlinedIcon color="error" /> // Yellow or red exclamation for inactive rows
          );
        } else {
          return <CheckCircleIcon color="success" />;
        }
      },
    },
  ];

  return columns;
};

export default columnsBuilder;
