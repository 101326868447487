const { Buffer } = require("buffer");
function slice(str, length, ext) {
  if (length > 0 && str) {
    if (length >= str.length) return str;
    else {
      if (!ext) {
        return str.slice(0, length);
      } else return str.slice(0, length) + "...";
    }
  }
  return str;
}
function getKey(key1, key2) {
  return key1 + "-" + key2;
}
function replaceIf(str, condition, substr) {
  if (str === null || str === undefined) return "";
  if (str.toString().toLowerCase() === condition) return substr;
  return str;
}
function isEmpty(str) {
  if (!str) {
    return true;
  } else {
    return false;
  }
}
function getValue(str) {
  if (
    typeof str === "undefined" ||
    str === undefined ||
    str === null ||
    str === ""
  ) {
    return 0;
  }

  var value = parseInt(str);
  if (Number.isNaN(value)) return 0;
  return value;
}

function hasNumber(myString) {
  return /\d/.test(myString);
}
function containsNumberAndLetter(str) {
  const hasNumber = /\d/.test(str);
  const hasLetter = /[a-zA-Z]/.test(str);
  return hasNumber && hasLetter;
}
function getNumberFromString(text) {
  try {
    if (getString(text) === "") return "";
    const matches = text.match(/\d+/); // Use a regular expression to match digits
    if (matches) {
      return parseInt(matches[0]); // Convert the matched string to an integer
    }
    return 0; // Return null if no number is found
  } catch (exception) {
    return 0;
  }
}
function RemoveComma(str) {
  var val = str.toString().replace(/,/g, "");
  //str = Number(str);

  return Number(val);
}
function getString(str) {
  if (typeof str === "undefined" || str === undefined || str === null) {
    return "";
  }
  return str.toString();
}

function isDate(str) {
  return !isNaN(Date.parse(str));
}

function showDateOnly(str) {
  return replaceIf(slice(str.toString(), 10), "0001-01-01", "");
}
function showDateOnlyFormatted(str) {
  if (str.includes("-")) {
    var currDate = showDateOnly(str);
    // 2023-10-25

    const [year, month, day] = currDate.split("-");
    return `${day}/${month}/${year}`;
  }
  return str;
}
function toUTC(date) {
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
}

export function toJsonDateTime(date) {
  let newDate = new Date(date);
  let dateFormatted =
    newDate.getFullYear() +
    "-" +
    withZero(newDate.getMonth() + 1) +
    "-" +
    withZero(newDate.getDate()) +
    "T" +
    withZero(newDate.getHours()) +
    ":" +
    withZero(newDate.getMinutes()) +
    ":" +
    withZero(newDate.getSeconds()) +
    "Z";

  return dateFormatted;
}
function toJsonDateTimeWithoutMilsec(dateTimeString) {
  const dateTime = new Date(dateTimeString);
  const year = dateTime.getFullYear();
  const month = String(dateTime.getMonth() + 1).padStart(2, "0");
  const day = String(dateTime.getDate()).padStart(2, "0");
  const hours = String(dateTime.getHours()).padStart(2, "0");
  const minutes = String(dateTime.getMinutes()).padStart(2, "0");
  const seconds = String(dateTime.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
function withZero(num) {
  if (num < 10) return "0" + num;
  return num;
}

function getFirstDayOfThisMonth() {
  let newDate = new Date();
  return newDate.getFullYear() + "-" + withZero(newDate.getMonth() + 1) + "-01";
}
function getToDay() {
  let newDate = new Date();
  return (
    newDate.getFullYear() +
    "-" +
    withZero(newDate.getMonth() + 1) +
    "-" +
    withZero(newDate.getDate())
  );
}

function getLastDayOfThisMonth() {
  var date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();
  //return new Date(y, m + 1, 0);

  let newDate = new Date(y, m + 1, 0);
  return (
    newDate.getFullYear() +
    "-" +
    withZero(newDate.getMonth() + 1) +
    "-" +
    newDate.getDate()
  );
}
function formatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

function generateUUID() {
  // Public Domain/MIT
  var d = new Date().getTime(); //Timestamp
  var d2 =
    (typeof performance !== "undefined" &&
      performance.now &&
      performance.now() * 1000) ||
    0; //Time in microseconds since page-load or 0 if unsupported
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
}
function getGermanMonth(monthNumber) {
  switch (monthNumber) {
    case "01":
      return "Januar";
    case "02":
      return "Februar";
    case "03":
      return "März";
    case "04":
      return "April";
    case "05":
      return "Mai";
    case "06":
      return "Juni";
    case "07":
      return "Juli";
    case "08":
      return "August";
    case "09":
      return "September";
    case "10":
      return "Oktober";
    case "11":
      return "November";
    case "12":
      return "Dezember";
    default:
      return "";
  }
}

export function getColumnNames(columns) {
  return columns.map((column) => column.headerName);
}
function capitalizeFirstLetter(str) {
  if (str && str.length > 0)
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  return str;
}
function base64Encode(string) {
  const buffer = Buffer.from(string);
  const base64 = buffer.toString("base64");
  return base64;
}
function reverseString(str) {
  return str.split("").reverse().join("");
}
async function getStorageItem(storageName, itemName) {
  const storageWithItem = JSON.parse(localStorage.getItem(storageName));

  let value = storageWithItem?.[itemName];

  return value;
}
async function setStorageItem(storageName, itemName, itemValue) {
  const existingValue = JSON.parse(localStorage.getItem(storageName)) || {};
  if (existingValue[itemName] !== itemValue) {
    existingValue[itemName] = itemValue;
    localStorage.setItem(storageName, JSON.stringify(existingValue));
  }
}
async function removeFromStorage(itemName) {
  localStorage.removeItem(itemName);
}

function generateRandomString(length) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let randomString = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters.charAt(randomIndex);
  }

  return randomString;
}

function getBaseUrl(url) {
  const parts = url.split("/");
  return `${parts[0]}//${parts[2]}`;
}
const clearCacheData = () => {};

function getParamValueFromURL(URLSearchParam, paramName) {
  try {
    const queryString = String(URLSearchParam);
    const paramStartIndex =
      queryString.indexOf(`${paramName}=`) + paramName.length + 1; // +1 to skip the '=' character
    const paramEndIndex =
      queryString.indexOf("&", paramStartIndex) !== -1
        ? queryString.indexOf("&", paramStartIndex)
        : undefined;

    const rawValue =
      paramEndIndex !== -1
        ? queryString.substring(paramStartIndex, paramEndIndex)
        : queryString.substring(paramStartIndex);

    return rawValue;
  } catch (error) {
    console.error("Error in getRawParameterValueFromURL:", error);
    return null;
  }
}
function readFileAsBase64(file) {
  return new Promise((resolve, reject) => {
    let blob;
    if (!file || !(file instanceof Blob)) {
      // Create a dummy Blob object with an empty array buffer
      blob = new Blob([new ArrayBuffer(1)]);
    } else {
      blob = file;
    }

    const reader = new FileReader();
    reader.onload = () => resolve(reader.result.split(",")[1]);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(blob);
  });
}
function getFileNameWithoutExtension(filePath) {
  // Extract the file name without extension
  return filePath
    .replace(/\.[^/.]+$/, "")
    .split("/")
    .pop();
}

function extractFileName(fullPath) {
  return fullPath.split("/").pop();
}

/*function getValidationErrorOnly(errorMessage) {
  const validationErrorKeyword = "One or more validation errors occurred.";
  const detailsKeyword = "Details:";

  if (errorMessage.includes(validationErrorKeyword)) {
    const startIndex = errorMessage.indexOf(detailsKeyword);

    if (startIndex !== -1) {
      return errorMessage.substring(startIndex + detailsKeyword.length).trim();
    }
  }

  // Return empty string if conditions are not met
  return "";
}*/
export {
  slice,
  getKey,
  replaceIf,
  isEmpty,
  generateUUID,
  getString,
  capitalizeFirstLetter,
  getValue,
  hasNumber,
  containsNumberAndLetter,
  getNumberFromString,
  RemoveComma,
  showDateOnly,
  showDateOnlyFormatted,
  isDate,
  getToDay,
  getFirstDayOfThisMonth,
  getLastDayOfThisMonth,
  toUTC,
  toJsonDateTimeWithoutMilsec,
  formatDate,
  base64Encode,
  reverseString,
  generateRandomString,
  getStorageItem,
  setStorageItem,
  removeFromStorage,
  clearCacheData,
  getBaseUrl,
  getParamValueFromURL,
  readFileAsBase64,
  getFileNameWithoutExtension,
  extractFileName,
  getGermanMonth,
};
