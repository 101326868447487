import {
  isDate,
  toJsonDateTimeWithoutMilsec,
} from "../../../../Common/Utility";

const columnsBuilder = (props) => {
  const columns = [
    { field: "id", headerName: "ID", width: 80, editable: false },

    {
      field: "entityType",
      headerName: "Interface",
      type: "text",
      editable: false,
      width: 120,
    },
    {
      field: "action",
      headerName: "Action",
      width: 120,
      type: "text",
      editable: false,
      renderCell: (cellValues) => {
        return renderCellColor(cellValues);
      },
    },
    {
      field: "description",
      headerName: "Description",
      width: 300,
      type: "text",
      editable: false,
    },

    {
      field: "changes",
      headerName: "Changes",
      flex: 2,
      type: "text",
      editable: false,
    },
    {
      field: "userName",
      headerName: "User Name",
      width: 120,
      type: "text",
      editable: false,
    },
    {
      field: "timeStamp",
      headerName: "Date",
      width: 200,
      valueFormatter: function (params) {
        if (isDate(params.value)) {
          return toJsonDateTimeWithoutMilsec(params.value);
        }
      },
    },
  ];

  return columns;
};

const renderCellColor = (params) => {
  const { field, value } = params;

  if (field === "action") {
    let backgroundColor = "";
    let textColor = "";

    if (value === "Created") {
      backgroundColor = "#87E12D";
      textColor = "white";
    } else if (value === "Deleted") {
      backgroundColor = "#E12D2D";
      textColor = "white";
    } else if (value === "Updated") {
      backgroundColor = "gold";
      textColor = "gray";
    } else {
      backgroundColor = "Yellow";
      textColor = "red";
    }

    return (
      <div style={{ backgroundColor, color: textColor, padding: "4px" }}>
        {value}
      </div>
    );
  }

  return value;
};

export default columnsBuilder;
