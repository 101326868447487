import {
  getBrowser,
  getCountryName,
  getDeviceType,
  getIPAddress,
  getOperatingSystem,
  getScreenResolution,
  getUserDateFormat,
  getUserLanguage,
  getViewportDimensions,
} from "../../../Common/UserSystemInformation";
async function createUserSystemLoginDto(userName) {
  try {
    const operatingSys = getOperatingSystem();
    const ipaddress = await getIPAddress();
    const browser = getBrowser();
    const countryName = await getCountryName();
    const deviceType = getDeviceType();
    const screenResolution = getScreenResolution();
    const viewportDimensions = getViewportDimensions();
    const userLanguage = getUserLanguage();
    const userDateFormat = getUserDateFormat();

    let loginDto = {
      userName,
      operatingSys,
      ipaddress,
      browser,
      countryName,
      deviceType,
      screenResolution,
      viewportDimensions,
      userLanguage,
      userDateFormat,
    };

    return loginDto;
  } catch (error) {
    console.log(error);
    return null;
  }
}
export default createUserSystemLoginDto;
