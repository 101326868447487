import React, { useEffect, useState } from "react";
import "./MonthlyTable.css";
import "../SearchBox.css";

import BankExportForm from "./BankExportForm";
import CheckError from "@mui/icons-material/HighlightOffRounded";
import { slice } from "../../../../Common/Utility";
import DataExportButtons from "../../../../components/Tools/DataExportButtons";
import { Get } from "../../../../Common/WebRequest";

const MonthlyTable = ({
  data,
  navigateToViewPaymentPage,
  currentRegionId,
  currentYear,
}) => {
  const [isExportFormOpen, setIsExportFormOpen] = useState(false);

  const stateApproved = 1;
  const statePaid = 2;
  const firstUserMonthlyAmounts = data.length > 0 ? data[0].monthlyAmounts : [];
  const uniqueMonths = Array.from(
    new Set(firstUserMonthlyAmounts.map((month) => month.formattedMonth))
  );

  const [visibleColumns, setVisibleColumns] = useState(
    Array.from({ length: uniqueMonths.length }, (_, index) => index)
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [error, setError] = useState("");
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);

  const handleMonthButtonClick = (selectedMonthIndex) => {
    setVisibleColumns((prevVisibleColumns) => {
      const isVisible = prevVisibleColumns.includes(selectedMonthIndex);
      if (isVisible) {
        return prevVisibleColumns.filter(
          (index) => index !== selectedMonthIndex
        );
      } else {
        const newVisibleColumns = [...prevVisibleColumns, selectedMonthIndex];
        return newVisibleColumns.sort((a, b) => a - b);
      }
    });
  };

  useEffect(() => {
    (async () => {
      try {
        if (uniqueMonths.length > 0) {
          const currentMonth = new Date().getMonth();

          const isCurrentMonth = (date) => {
            const dateMonth = date.getMonth();
            return dateMonth === currentMonth;
          };

          if (isCurrentMonth !== -1) {
            setVisibleColumns(
              Array.from({ length: currentMonth + 1 }, (_, index) => index)
            );
          }
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    })();
  }, [uniqueMonths.length]);
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchClose = () => {
    setSearchTerm("");
  };
  const handleSort = (columnIndex) => {
    setSortColumn(columnIndex);
    setSortOrder((prevSortOrder) => (prevSortOrder === "asc" ? "desc" : "asc"));
  };

  const renderHeaders = (handleSort) => {
    const headers = [
      "Name",
      ...visibleColumns.map((index) => uniqueMonths[index]),
      " Summe € ",
    ];

    return headers.map((header, index) => {
      const isSortable = index >= 0;
      const isSorted = sortColumn === index;

      return (
        <th
          key={index}
          className={`monthTableHeader ${isSortable ? "sortable" : ""}`}
          onClick={() => (isSortable ? handleSort(index) : null)}
        >
          {header}
          {isSortable && isSorted && (
            <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>
          )}
        </th>
      );
    });
  };

  const calculateUserSum = (monthlyAmounts) => {
    return monthlyAmounts.reduce((sum, month) => sum + month.amount, 0);
  };

  async function handleOnClick(user, columnIndex, amount) {
    if (amount === 0) return;
    const monthYear = uniqueMonths[columnIndex];
    const [month, year] = monthYear.split(" ");
    var MonthAsNumber = convertMonth(month);
    const monthIndex =
      new Date(Date.parse(MonthAsNumber + " 1, 2020")).getMonth() + 1; // +1 because getMonth returns 0-indexed
    var monthStr = String(monthIndex).padStart(2, "0");

    const formattedYearMonth = `${year}-${monthStr}`;

    try {
      const res = await Get(
        "Payment/user-and-month/" + user.userId,
        formattedYearMonth
      );

      if (res.isSuccess) {
        navigateToViewPaymentPage({
          paymentId: res.result.paymentId,
          reload: false,
        });

        console.log(res);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  // Jan --> 01
  function convertMonth(monthStr) {
    switch (monthStr) {
      case "Jan":
        return "01";
      case "Feb":
        return "02";
      case "Mrz":
        return "03";
      case "Apr":
        return "04";
      case "Mai":
        return "05";
      case "Jun":
        return "06";
      case "Jul":
        return "07";
      case "Aug":
        return "08";
      case "Sep":
        return "09";
      case "Okt":
        return "10";
      case "Nov":
        return "11";
      case "Dez":
        return "12";
      default:
        return "00";
    }
  }
  const handleRowClick = (index) => {
    setSelectedRowIndex(index === selectedRowIndex ? null : index); // Toggle selection
  };
  const renderRows = (sortedData) => {
    const filteredData = sortedData.filter(
      (user) =>
        user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.monthlyAmounts.some((month) =>
          month.amount.toString().includes(searchTerm.toString())
        )
    );

    return filteredData.map((user, index) => (
      <tr
        key={user.userId}
        className={selectedRowIndex === index ? "rowSelected" : "monthTableRow"}
        onClick={() => handleRowClick(index)}
      >
        <td className="monthTableCell">{user.name}</td>
        {visibleColumns.map((columnIndex) => {
          const monthlyAmount = user.monthlyAmounts[columnIndex];
          const cellStyle = {
            backgroundColor:
              monthlyAmount?.paymentState === statePaid
                ? "#9fe159"
                : monthlyAmount?.paymentState === stateApproved
                ? "#ff7773"
                : "inherit",
            cursor:
              monthlyAmount?.paymentState === statePaid ||
              monthlyAmount?.paymentState === stateApproved
                ? "pointer"
                : "default",
          };
          return (
            <td
              key={columnIndex}
              className="monthTableCell"
              style={cellStyle}
              onClick={() =>
                handleOnClick(user, columnIndex, monthlyAmount.amount)
              } // Pass columnIndex here
            >
              {monthlyAmount ? monthlyAmount.amount : 0}
            </td>
          );
        })}
        <td className="monthTableCell">
          {calculateUserSum(user.monthlyAmounts)}
        </td>
      </tr>
    ));
  };

  const calculateTotalSum = (monthIndex) => {
    return data.reduce(
      (sum, user) => sum + user.monthlyAmounts[monthIndex].amount,
      0
    );
  };

  const renderTotalSumRow = () => {
    const calculateGrandTotalSum = () => {
      return data.reduce(
        (sum, user) => sum + calculateUserSum(user.monthlyAmounts),
        0
      );
    };

    const totalSumRow = [
      "Summe €",
      ...visibleColumns.map((index) => calculateTotalSum(index) + " €"),
      calculateGrandTotalSum() + " €",
    ];

    return (
      <tr className="monthTableRow">
        {totalSumRow.map((sum, index) => (
          <td key={index} className="monthTableCell">
            {sum}
          </td>
        ))}
      </tr>
    );
  };

  const sortedData = () => {
    try {
      if (sortColumn !== null) {
        const sorted = [...data];
        sorted.sort((a, b) => {
          const isLastColumn = sortColumn === visibleColumns.length + 1;
          const isFirstColumn = sortColumn === 0;

          let valueA = isFirstColumn
            ? a.name.toLowerCase()
            : isLastColumn
            ? calculateUserSum(a.monthlyAmounts)
            : a.monthlyAmounts[visibleColumns[sortColumn - 1]]?.amount || 0;

          let valueB = isFirstColumn
            ? b.name.toLowerCase()
            : isLastColumn
            ? calculateUserSum(b.monthlyAmounts)
            : b.monthlyAmounts[visibleColumns[sortColumn - 1]]?.amount || 0;

          if (!isFirstColumn && !isLastColumn) {
            return sortOrder === "asc" ? valueA - valueB : valueB - valueA;
          }

          valueA = typeof valueA === "string" ? valueA : String(valueA);
          valueB = typeof valueB === "string" ? valueB : String(valueB);

          return sortOrder === "asc"
            ? valueA.localeCompare(valueB, undefined, { numeric: true })
            : valueB.localeCompare(valueA, undefined, { numeric: true });
        });
        return sorted;
      }
    } catch (exception) {
      console.error("Error by sorting data: " + exception);
    }
    return data;
  };

  const handleOpenExportForm = () => {
    if (currentRegionId <= 0) {
      setError("Please select Region");
      return;
    }
    setIsExportFormOpen(true);
  };

  const handleCloseExportForm = () => {
    setIsExportFormOpen(false);
  };

  return (
    <>
      <BankExportForm
        open={isExportFormOpen}
        onClose={handleCloseExportForm}
        currentYear={currentYear}
        currentRegionId={currentRegionId}
      />

      {error && error.length > 0 && (
        <div className="alert alert-danger" role="alert">
          <CheckError />
          {slice(error, 200, "...")}
        </div>
      )}
      <div className="">
        <div>
          {uniqueMonths.map((formattedMonth, index) => (
            <button
              className={`toggleButton ${
                visibleColumns.includes(index) ? "toggled" : ""
              }`}
              key={index}
              onClick={() => handleMonthButtonClick(index)}
            >
              {formattedMonth.substring(0, 3)}
            </button>
          ))}
        </div>
        <div>
          <br />
          <div className="row">
            <div className="col-sm-6 col-md-6">
              <DataExportButtons
                tableId="data-table"
                //data={data}
                filename_csv="Bewilligte_Zahlungen"
                filename_excel="Bewilligte_Zahlungen"
                filename_pdf="Bewilligte_Zahlungen"
              />
              <button
                className="export-bank-button"
                onClick={handleOpenExportForm}
              >
                Bank-Export
              </button>
            </div>
            <div className="col-sm-4 col-md-2">
              <div className="searchContainer">
                <input
                  type="text"
                  className="searchInput"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                {searchTerm && (
                  <button className="closeButton" onClick={handleSearchClose}>
                    &times;
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-8">
              <div className="monthTableContainer">
                <table
                  id="data-table"
                  name="paiedOrApprovedPayments"
                  className="monthTable"
                >
                  <thead>
                    <tr>{renderHeaders(handleSort)}</tr>
                  </thead>
                  <tbody>
                    {renderRows(sortedData())}
                    {renderTotalSumRow()}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p>Count = {data.length}</p>
        </div>
      </div>
    </>
  );
};

export default MonthlyTable;
