import React, { useEffect, useState } from 'react';
import { Button, Grid, TextField, Typography, Paper } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import PayIcon from '@mui/icons-material/PriceCheck';
import WaitIcon from '@mui/icons-material/HourglassBottom';  
import { blue } from '@mui/material/colors'; 
import { useNavigate, useParams } from 'react-router-dom';  
import { apiUrl } from '../../../config';
import { Delete, Get, Put } from '../../../Common/WebRequest';
import { getBaseUrl, getGermanMonth, getStorageItem } from '../../../Common/Utility';
import DialogBoxWithYesNo from '../../../components/Dialog/DialogBoxWithYesNo';
import SuccessMessage from '../../User/DashBoard/Payments/SuccessMessage';
import ErrorMessage from '../../User/DashBoard/Payments/ErrorMessage';

const monthsInGerman = [
  'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'
];
const TimeToCloseDialogOnSuccess = 2000;

const ViewPayment = () => {
  const { paymentId } = useParams();
  
  const stateWaiting=0;
  const stateApproved = 1;
  const statePaid = 2;
  
  const[state,setState]= useState(-1)
  const [userId, setUserId] = useState(0);
  const [error, setError] = useState('');
  const [reloadData, setReloadData] = useState(false)
  const [errorOpen, setErrorOpen] = useState(false);
  const [success, setSuccess] = useState('')

  const [partnerName, setPartnerName] = useState('')

  const disableColor = '#f7f7f8'
  const [isLoading, setIsloading] = useState(true);
  const [confTitle, setConfTitle] = useState('')
  const [confText, setConfText] = useState('')
  const [operation, setOperation] = useState('')
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();
  const [validationErrors, setValidationErrors] = useState({
    rejectionReason: false,

  });

  const handleInvoicePaidClick = () => {
    setOperation('paid')
    setConfTitle("Bezahlbestätigung")
    setConfText("Ist die Rechnung bezahlt?")
    setOpenDialog(true);
  }
  const handleValidatePaymentClick = () => {
    setOperation('validate')
    setConfTitle("Validierung bestätigen")
    setConfText("Möchten Sie diese Zahlung wirklich bestätigen?")
    setOpenDialog(true);
  };

  const handleDeletePaymentClick = () => {
    setValidationErrors({});
    const isValid = validateRejectionReason();
    if (!isValid) {
      setError("Bitte geben Sie den Grund für die Löschung an.");
      return;
    }
    setOperation('delete')
    setConfTitle("Löschen bestätigen")
    setConfText("Möchten Sie diese Zahlung wirklich löschen?")
    setOpenDialog(true);
  };

  const handleYesClick = async (e) => {
    await handlePaymentOperation(operation)   
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSuccessClose = () => {

    if (operation === 'paid') {
       
      setReloadData(!reloadData)
    }
    if (operation === 'validate') {
      
      setReloadData(!reloadData)

    }
    else if (operation === 'delete') {
      setReloadData(!reloadData)
      navigate("/waitingpayments")
    }
  }
  const validateRejectionReason = () => {
    const rejectionReason = formData?.Ablehnungsgrund?.trim() ?? '';

    setValidationErrors({
      rejectionReason: !rejectionReason,
    });

    return rejectionReason;
  };
  const handlePaymentOperation = async (operationText) => {
    try {
      setError("")
      setIsloading(true);
      var paymentObj = {
        paymentId: paymentId,
        userId: userId,
        rejectionReason: operationText === "delete" ? formData.Ablehnungsgrund : ''
      }

      setOpenDialog(false);
      let res;
      switch (operationText) {
        case "paid":
          res = await Put("Payment/pay", paymentId, paymentObj);
          if (res.isSuccess) {

            setSuccess("Die Rechnung wurde bezahlt!");
          } else {
            setError(res.Error);
          }
          break;
        case "validate":
          res = await Put("Payment/approve", paymentId, paymentObj);
          if (res.isSuccess) {

            setSuccess("Zahlung wurde validiert!");
          } else {
            setError(res.Error);
          }
          break;
        case "delete":
          res = await Delete("Payment/delete-payment", paymentId, paymentObj);
          if (res.isSuccess) {

            setSuccess("Zahlung wurde gelöscht!");
          } else {
            setError(res.Error);
          }
          break;
        default:
          setError("Invalid operation :" + operationText)
          throw new Error("Invalid operation " + operationText);
      }
    } catch (error) {
      setError(error);
    } finally {
       
      setIsloading(false);
    }
  }
  const [formData, setFormData] = useState({
    month: '',
    monthLabel: '',
    year: '',
    pdfAbrechnung: null,
    pdfStundennachweise: null,
    pdfStundennachweiseNachreichen: null,
    gesamtStunden: '',
    gesamtEuro: '',
    bemerkung: '',
    Ablehnungsgrund: ''
    });
    useEffect(() => {
      if (success.length > 0  ) {
        const timer = setTimeout(() => {
          handleSuccessClose();
        }, TimeToCloseDialogOnSuccess);
        return () => clearTimeout(timer);
      }
    }, [success.length ]);


  useEffect(() => {
    (async () => {
       
      handleResetClick();
      let userIdValue = await getStorageItem("login", "userId");
      if (userIdValue <= 0) {
        return; // log in page
      }
      setUserId(userIdValue)
    })();

  }, [userId ]);

  useEffect(() => {
    (async () => {

      if (paymentId > 0) {
        try {
          setIsloading(true);
          const res = await Get("Payment", paymentId);
          if (res.isSuccess) {
            setState(res.result.paymentState)
            fillPaymentData(res.result);
            setPartnerName(res.result.userName)
          }
          else 
          {
            setState(-1)
            resetForm();            
            setError(res.Error)
          }
        } catch (error) {
          console.error("Error fetching payment data:", error);
          setError("Fehler beim Abrufen der Zahlungsdaten. Bitte versuche es erneut. Error:" + error);
        }
        finally {
          setIsloading(false);
          setSuccess("")
          setError("")
        }
      }
    })();
  }, [paymentId, reloadData]);

  const fillPaymentData = (paymentData) => {
    
    const { yearMonth, totalHours, totalAmount, calculationsFile, timesheetsFile,timesheetsFileLater, comments, RejectionReason } = paymentData;
    const [year, month] = yearMonth ? yearMonth.split('-') : ['', ''];
    setFormData({
      ...formData,
      month: month,
      monthLabel: monthsInGerman[parseInt(month) - 1], // Convert month to German label
      year: year,
      yearMonth: yearMonth,
      gesamtStunden: totalHours,
      gesamtEuro: totalAmount,
      pdfAbrechnung: calculationsFile,
      pdfStundennachweise: timesheetsFile,
      pdfStundennachweiseNachreichen: timesheetsFileLater,
      bemerkung: comments,
      Ablehnungsgrund: RejectionReason
    });
  };

  const handleChange = (field, value) => {
    if (field === 'month') {
      const monthIndex = parseInt(value) - 1;
      setFormData({
        ...formData,
        [field]: value,
        [`${field}Label`]: monthsInGerman[monthIndex]
      });
    } else {
      setFormData({
        ...formData,
        [field]: value
      });
    }
  };

  const handleResetClick = () => {
    resetForm();
  };

  const resetForm=() =>{
    setError("")
    setSuccess("")
    setFormData({
      month: '',
      monthLabel: '',
      year: '',
      pdfAbrechnung: null,
      pdfStundennachweise: null,
      gesamtStunden: '',
      gesamtEuro: '',
      bemerkung: ''
    });
  }

  const openFileWindow = async (fileName) => {
    try
    { 
    var res = await Get("Payment/" + paymentId + "/file", fileName);
    if (res.isSuccess) {
      var fullPdfpath = getBaseUrl(apiUrl) + "/" + res.result;
      window.open(fullPdfpath, '_blank');
    }
    else {
      window.open("/file-not-found", '_blank');
    }
  }
  catch(error)
  {
    setError(" (X) Fehler beim Datei öffnen: "+ error);
    console.error("Error open the requested link: " + error);
  }
  };

  return (
    <>

      {isLoading && (
        <Typography variant="body2" sx={{
          color: 'gold', backgroundColor: '#022010', padding: '8px',
          borderRadius: '4px', marginBottom: '16px'
        }}>
          <WaitIcon sx={{ marginRight: '8px' }} />
          Wait.....
        </Typography>
      )}

      {error && (
        <Typography variant="body2" sx={{ color: 'red', backgroundColor: '#ffebee', padding: '8px', borderRadius: '4px', marginBottom: '16px' }}>
          <ErrorIcon sx={{ marginRight: '8px' }} />
          {error}
        </Typography>
      )}

      {error && (
        <ErrorMessage
          open={errorOpen}
          // onClose={handleErrorClose}
          title="Fehler!"
          body={error}
        />
      )}

      {success && (
        <Typography variant="body2" sx={{ color: 'green', backgroundColor: '#e8f5e9', padding: '8px', borderRadius: '4px', marginBottom: '16px' }}>
          <CheckCircleIcon sx={{ marginRight: '8px' }} />

          {success}
        </Typography>
      )}
      {success && (
        <SuccessMessage
          open={success.length > 0}
          onClose={handleSuccessClose}
          title="Erfolg!"
          body={success}
        />
      )}
      <div id="parentDiv">
        <Grid container spacing={3} justifyContent="left" marginTop={3} marginLeft={1}>
          <Grid item xs={12} md={6}>
            <Paper elevation={3} style={{ padding: '20px' }}  >

              <div style={{ position: 'relative' }}>
                <Typography
                  variant="headline"
                  component="h3"
                  align="left"
                  paddingLeft={"10px"}
                  color={blue[500]}
                >
                  Zahlung prüfen
                </Typography>
                {state === stateWaiting && (
                  <label style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    marginTop: '10px',
                    marginRight: '10px',
                    paddingLeft: '15px',
                    paddingRight: '15px',
                    color: 'white',
                    background: '#ffaf8f',
                    borderRadius: '10px',
                  }}>Wartend</label>
                )}
                {state === stateApproved && (
                  <label style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    marginTop: '10px',
                    marginRight: '10px',
                    paddingLeft: '15px',
                    paddingRight: '15px',
                    color: 'blue',
                    background: '#ffd966',
                    borderRadius: '10px',
                  }}>Genehmigt</label>
                )}
                {state === statePaid && (
                  <label style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    marginTop: '10px',
                    marginRight: '10px',
                    paddingLeft: '15px',
                    paddingRight: '15px',
                    color: 'white',
                    background: '#4CAF50',
                    borderRadius: '10px',
                  }}>Bezahlt</label>
                )}

              </div>
              <br />
              <TextField
                fullWidth
                label="Partner"
                variant="outlined"
                value={partnerName}
                readOnly={true}
                //readOnly={true}
                style={{ backgroundColor: disableColor }}
                sx={{ mb: 2 }}
              />

              <br />
              <TextField
                fullWidth
                label="Abrechnungsmonat"
                variant="outlined"
                readOnly={true}
                value={`${getGermanMonth(formData.month)} ${formData.year}`}
                style={{ backgroundColor: disableColor }}

                sx={{ mb: 2 }}
              />
              <Typography align='left' variant="body1" color="black" sx={{ marginLeft: "5px" }}>
                <label style={{ fontWeight: 'bold' }}> Files:</label>
                <br />
                {formData.pdfAbrechnung && (
                  <span
                    style={{ marginLeft: "10px", color: "blue", cursor: "pointer", textDecoration: "underline" }}
                    onClick={() => openFileWindow(formData.pdfAbrechnung)}
                  >
                    {formData.pdfAbrechnung}
                  </span>
                )}
                <br />
                {formData.pdfStundennachweise && (
                  <span
                    style={{ marginLeft: "10px", color: "blue", cursor: "pointer", textDecoration: "underline" }}
                    onClick={() => openFileWindow(formData.pdfStundennachweise)}
                  >
                    {formData.pdfStundennachweise}
                  </span>
                )}
                <br />
                {formData.pdfStundennachweiseNachreichen && (
                  <span
                    style={{ marginLeft: "10px", color: "blue", cursor: "pointer", textDecoration: "underline",backgroundColor: 'yellow'}}
                    onClick={() => openFileWindow(formData.pdfStundennachweiseNachreichen)}
                  >
                    {formData.pdfStundennachweiseNachreichen}
                  </span>
                )}
              </Typography>
              <br />
              <TextField
                fullWidth
                label="Gesamt Stunden *"
                variant="outlined"
                value={formData.gesamtStunden}
                readOnly={true}
                style={{ backgroundColor: disableColor }}
                //onChange={(e) => handleChange('gesamtStunden', e.target.value.replace(/[^0-9]/g, ''))}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Gesamt € *"
                variant="outlined"
                value={formData.gesamtEuro}
                readOnly={true}
                style={{ backgroundColor: disableColor }}
                //onChange={(e) => handleChange('gesamtEuro', e.target.value.replace(/[^0-9]/g, ''))}
                sx={{ mb: 2 }} // Adjust label font size here
              />
              <TextField
                multiline
                rows={5}
                label="Bemerkung"
                readOnly={true}
                value={formData.bemerkung}
                //onChange={(e) => handleChange('bemerkung', e.target.value)}

                style={{ width: '100%', marginBottom: '16px', backgroundColor: disableColor }}
              />
              <TextField
                name='Ablehnungsgrund'
                multiline
                rows={5}
                label="Ablehnungsgrund"
                value={formData.Ablehnungsgrund}
                hidden={state !== stateWaiting}
                onChange={(e) => handleChange('Ablehnungsgrund', e.target.value)}
                disabled={state === statePaid || state === stateApproved ? true : false}
                style={{ width: '100%', marginBottom: '16px', backgroundColor: state === statePaid || state === stateApproved ? disableColor : '' }}
                error={validationErrors.rejectionReason}
                helperText={validationErrors.rejectionReason && 'Ablehnungsgrund erforderlich.'}
              />

              <div id="parentDivButtons">

                <Button
                  variant="outlined"
                  color="success"

                  onClick={handleInvoicePaidClick}
                  fullWidth

                  hidden={state !== stateApproved}
                  disabled={isLoading}
                  startIcon={<PayIcon />}
                  sx={{ width: '170px', textTransform: 'capitalize', mr: 1 }}
                >
                  Invoice Paid
                </Button>
                <Button
                  variant="outlined"
                  color="success"
                  onClick={handleValidatePaymentClick}
                  fullWidth
                  hidden={state !== stateWaiting}
                  disabled={isLoading}
                  startIcon={<DoneIcon />}
                  sx={{ width: '170px', textTransform: 'capitalize', mr: 1 }}

                >
                  Validate Payment
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  hidden={state !== stateWaiting}
                  disabled={isLoading}
                  onClick={handleDeletePaymentClick}
                  fullWidth
                  startIcon={<ClearIcon />}
                  sx={{ width: '160px', textTransform: 'capitalize' }} // No margin here
                >
                  Delete Payment
                </Button>

                <DialogBoxWithYesNo
                  open={openDialog}
                  onClose={handleCloseDialog}
                  onYes={handleYesClick}
                  title={confTitle}
                  text={confText}
                  operationText={operation}
                />
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ViewPayment;
