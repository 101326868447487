import {
  getString,
  getValidationErrorOnly,
  getValue,
  removeFromStorage,
} from "./Utility";
import { apiUrl } from "../config.js";

async function WebRequest(props) {
  let data = [];
  let fullPath = props.path;
  let Status = 0;
  let StatusText = "";
  let contentType = "application/json; charset=utf-8";

  if (getValue(props.parameter) >= 0) {
    data = {};
  }
  if (
    typeof props.parameter !== "undefined" &&
    (getValue(props.parameter) > 0 || props.parameter.toString().length > 1)
  ) {
    fullPath = fullPath + "/" + props.parameter;
  }

  let url = apiUrl + "/" + fullPath;
  console.log("URL: ", url);
  const loginData = JSON.parse(localStorage.getItem("login"));
  const accessToken = loginData?.accessToken;

  let headers = {};

  if (getString(props.contentType) === "") {
    headers["Accept"] = "application/json, text/plain, */* ";
    headers["Content-Type"] = contentType;
    headers["Authorization"] = "Bearer " + accessToken;
  }

  const fetchOptions = {
    method: props.method, // Change to the appropriate HTTP method
    headers: headers,
    // mode: "no-cors",
    body: JSON.stringify(props.body),
  };

  await fetch(url, fetchOptions)
    .then((response) => {
      Status = response.status;
      StatusText = response.statusText;

      return response.json();
    })
    .then((actualData) => {
      data = actualData;

      if (!data.isSuccess || Status !== 200) {
        console.log("data not OK >> ", data);

        let title = "";
        let errDetails = "";
        if (getString(data.displayMessage) !== "") title = data.displayMessage;
        if (getString(data.title) !== "") title = title + " " + data.title;
        if (getString(data.errorMessages) !== "")
          errDetails = data.errorMessages;
        if (Object.hasOwn(data, "errors") && data.errors !== null)
          errDetails = errDetails + " " + Object.values(data.errors)[0];

        data = setErrorMessage(
          title,
          errDetails,
          Status,
          StatusText,
          url,
          props.method,
          props.body
        );
      }
    })

    .catch((error) => {
      data = setErrorMessage(
        error.message,
        error,
        Status,
        StatusText,
        url,
        props.method,
        props.body
      );
    });

  if (Status === 401) {
    removeFromStorage("login");
    window.location.href = "/login";
    return null;
  }
  return data;
}
async function Get(path, parameter) {
  let props = { path: path, parameter: parameter, method: "GET" };
  return await WebRequest(props);
}

async function Put(path, parameter, body) {
  let props = { path: path, parameter: parameter, method: "PUT", body: body };

  return await WebRequest(props);
}

async function Post(path, body, contentType) {
  let props = {
    path: path,
    parameter: 0,
    method: "POST",
    body: body,
    contentType: contentType,
  };

  return await WebRequest(props);
}

async function Delete(path, parameter, body, contentType) {
  let props = {
    path: path,
    parameter: parameter,
    method: "DELETE",
    body: body,
    contentType: contentType,
  };

  return await WebRequest(props);
}

async function setErrorMessage(
  errorMsg,
  errorDetail,
  Status,
  StatusText,
  url,
  method,
  body
) {
  let errObj = {};
  errObj.isSuccess = false;
  errObj.Url = url;
  errObj.Error = errorMsg;
  errObj.Status = Status;
  errObj.StatusText = StatusText;
  errObj.ErrorDetail =
    errorDetail !== "" && errorDetail !== errorMsg
      ? "Details:" + errorDetail
      : "";
  errObj.ValidationError = errorDetail;
  errObj.Method = method;
  errObj.Body = body;
  console.log("error::>> ", errObj.Status);
  console.log("errObj::>> ", errObj);
  // add log to sql server table
  // await AddErrorLog(errObj);
  return errObj;
}

async function AddErrorLog(error) {
  if (error.length === 0) return;
  const errLog = {
    id: 0,
    userName: "React_JS",
    url: error.Url,
    status: error.Status,
    statusText: error.StatusText,
    message: error.Error,
    details: error.ErrorDetail,
    method: error.Method,
    body: error.Body,
  };
  await Post("SystemLog", errLog);
}

export { Get, Put, Post, Delete, AddErrorLog };
