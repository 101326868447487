import React, { useState, useEffect } from 'react';
import { TextField, Button, FormControl, InputLabel, Select, MenuItem, Typography } from '@mui/material';
import { Get, Put } from '../../../Common/WebRequest';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { getValue } from '../../../Common/Utility';
import { getCoordinatesForAddress } from '../../../Common/locationHelper';

const ProfileInformation = ({ userId, isIbanError }) => {
    
    const[apiKey,setApiKey]=useState('');
    const [regionData, setRegionData] = useState([]);
    const [selectedRegionName, setSelectedRegionName] = useState('');
    const [lastCheckedAddress, setLastCheckedAddress] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [userInfo, setUserInfo] = useState(null);    
    const [regionLoading, setRegionLoading] = useState(true);
    const [userLoading, setUserLoading] = useState(false);
    const [regionLoaded, setRegionLoaded] = useState(false);
    const[userLoaded,setUserLoaded]=useState(false);
    const[ibanCorrected,setIbanCorrected]=useState(true);
   
    const [validationErrors, setValidationErrors] = useState({
        fullName: false,
        email: false,
        regionId: false,
        password: false,
        street: false,
        zip: false,
        iban: false,        
    });

    useEffect(() => {
        const fetchApiKey = async () => {
            try {
                const googleMapInfo = await Get('CompanyInformation/GoogleMapInfo');
                if (googleMapInfo.isSuccess) {
                    setApiKey(googleMapInfo.result.googleApiKey);
                    
                                      
                } else {
                    setError(googleMapInfo.Status + '/' + googleMapInfo.Error);                  
                }
            } catch (error) {
                setError('Error fetching region data: ' + error.message);                 
            } finally {
                 
            }
        };
        fetchApiKey();
    }, []);

    const constructAddress = (userInfo, selectedRegionName) => {
        return `${userInfo.street}, ${userInfo.zip} ${selectedRegionName}, Germany`;
    };
    useEffect(() => {
        const fetchRegionData = async () => {
            try {
                const regionRes = await Get('Region');
                if (regionRes.isSuccess) {
                    setRegionData(regionRes.result);
                    setRegionLoaded(true);                   
                } else {
                    setError(regionRes.Status + '/' + regionRes.Error);                  
                }
            } catch (error) {
                setError('Error fetching region data: ' + error.message);                 
            } finally {
                setRegionLoading(false);
                if(isIbanError === "1"){
                    setIbanCorrected(false);
                }
            }
        };
        fetchRegionData();
    }, []);
    
    useEffect(() => {
        if (!regionLoaded) return;
        const fetchUserData = async () => {
            setUserLoading(true);
            try {
                const userRes = await Get('User', userId);
                if (userRes.isSuccess) {
                    setUserInfo(userRes.result);
                    setSelectedRegionName(regionData.find(region => region.regionId === userRes.result.regionId).regionName);
                    setUserLoaded(true);            
                } else {
                    setError(userRes.Status + '/' + userRes.Error);                  
                }
            } catch (error) {
                
                setError('Error fetching User-Profile data: ' + error.message);
            } finally {
                setUserLoading(false);
            }
        };
        fetchUserData();
    }, [regionLoaded, userId, regionData]);

    useEffect(() => {
        if (!userLoaded) return;
        setLastCheckedAddress(constructAddress(userInfo, selectedRegionName));
    }, [ userLoaded]);

    if (regionLoading || userLoading) {
        return <div>Loading.....</div>;
    }

    const fetchCoordinates = async (address) => {
        try {
            if (address.toLowerCase().trim() === lastCheckedAddress.toLowerCase().trim() && userInfo.latitude && userInfo.longitude) {
                console.log('No address change detected, skipping fetchCoordinates.');
                return null;
            }
            const coordinates = await getCoordinatesForAddress(apiKey, address, selectedRegionName, userInfo.zip);
            if (coordinates.error) {
                let error = "Falsche:"+ coordinates.error;
                setError(error);
                throw new Error(error);
            } else if (coordinates.lat && coordinates.lng) { 
                setLastCheckedAddress(address);
                return { lat: coordinates.lat.toString(), lng: coordinates.lng.toString() };
            } else {
              
                let error = "Ungültige Adresse angegeben" ;
                setError(error);
                throw new Error(error);

            }
        } catch (error) {
            console.error("Failed to fetch coordinates:", error);
            throw error;
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserInfo((prevUserInfo) => ({
            ...prevUserInfo,
            [name]: name === 'zip' ? getValue(value) : value,
        }));

        if (name === 'regionId') {
            const selectedRegion = regionData.find(region => region.regionId === value).regionName;
            setSelectedRegionName(selectedRegion);
            setLastCheckedAddress(constructAddress(userInfo, selectedRegionName));
        }
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [name]: false,
        }));
    };

    const handleProfileSave = async () => {
        setError('');
        setSuccess('');
        let currentAddress = constructAddress(userInfo, selectedRegionName);
        let updatedUserInfo = { ...userInfo };
        const coords = await fetchCoordinates(currentAddress);
        if (coords) {
            updatedUserInfo.latitude = coords.lat;
            updatedUserInfo.longitude = coords.lng;
        }
        const isValid = validateUserInfo(updatedUserInfo);
        if (!isValid) {
            setError('Bitte alle Pflichtfelder korrekt ausfüllen.');
            return;
        }
        var res = await Put("User/UserProfile", userId, updatedUserInfo);
        if (res.isSuccess) {
            setUserInfo(updatedUserInfo);
            setSuccess("Profil erfolgreich aktualisiert.");
            setIbanCorrected(true);
            setValidationErrors({
                fullName: false,
                email: false,
                regionId: false,
                password: false,
                street: false,
                zip: false,
                iban: false,
                latitude: false,
                longitude: false
            });
        } else {
            setError(res.Error.includes("One or more validation errors occurred.") ? res.ErrorDetail : res.Error);
        }
    };

    const validateUserInfo = (userInfo) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const validationErrors = {
            fullName: !userInfo.fullName || userInfo.fullName.trim().length === 0,
            email: !(userInfo.email && userInfo.email.match(emailPattern)),
            iban: !userInfo.iban || userInfo.iban.trim().length === 0, 
            zip: !userInfo.zip,
            street: !userInfo.street || userInfo.street.trim().length === 0,
            latitude: !userInfo.latitude,
            longitude: !userInfo.longitude,
        };
        setValidationErrors(validationErrors);
        return Object.values(validationErrors).every((error) => !error);
    };

    return (
        <div>
            {success && (
                <Typography variant="body2" sx={{ color: 'green', backgroundColor: '#e8f5e9', padding: '8px', borderRadius: '4px', marginBottom: '16px' }}>
                    <CheckCircleIcon sx={{ marginRight: '8px' }} />
                    {success}
                </Typography>
            )}
            {error && (
                <Typography variant="body2" sx={{ color: 'red', backgroundColor: '#ffebee', padding: '8px', borderRadius: '4px', marginBottom: '16px' }}>
                    <ErrorIcon sx={{ marginRight: '8px' }} />
                    {error}
                </Typography>
            )}
            {userInfo && (
                <>
                    <TextField
                        name="fullName"
                        label="Name"
                        value={userInfo.fullName || ''}
                        onChange={handleChange}
                        required
                        fullWidth
                        margin="normal"
                        autoComplete="off"
                        error={validationErrors.fullName}
                        helpertext='Name ist erforderlich'
                    />
                    <FormControl fullWidth variant="outlined" style={{ marginTop: "1rem", marginBottom: "1rem" }} error={validationErrors.regionId} helpertext='Region ist erforderlich'>
                        <InputLabel id="region-label">Region *</InputLabel>
                        <Select labelId="region-label" name="regionId" value={userInfo.regionId || ''} onChange={handleChange} required autoComplete="off">
                            {regionData.map((region) => (
                                <MenuItem key={region.regionId} value={region.regionId}>
                                    {region.regionName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        name="zip"
                        label="PLZ"
                        value={userInfo.zip || ''}
                        onChange={handleChange}
                        fullWidth
                        required
                        margin="normal"
                        inputProps={{
                            pattern: '[0-9]*',
                            maxLength: 5,
                            minLength: 5,
                        }}
                        autoComplete="off"
                        error={validationErrors.zip}
                        helpertext='PLZ ist erforderlich'
                    />
                    <TextField
                        name="street"
                        label="Straße"
                        value={userInfo.street || ''}
                        onChange={handleChange}
                        required
                        
                        fullWidth
                        margin="normal"
                        autoComplete="off"
                        error={validationErrors.street}
                        helpertext='Straße ist erforderlich'
                    />
                    <TextField
                        name="email"
                        label="E-Mail"
                        type="email"
                        value={userInfo.email || ''}
                        onChange={handleChange}
                        required
                        fullWidth
                        margin="normal"
                        autoComplete="off"
                        error={validationErrors.email}
                        helpertext='Ungültiges E-Mail-Format'
                    />
                    <TextField
                        name="iban"
                        label="IBAN"
                        type="text"
                        autoFocus={!ibanCorrected}
                        value={userInfo.iban || ''}
                        onChange={handleChange}
                        required
                        fullWidth
                        margin="normal"
                        autoComplete="off"
                        error={validationErrors.iban || !ibanCorrected}
                        helpertext='Ungültiges IBAN'
                    />
                    <TextField
                        id="yy20"
                        name="phoneNumber"
                        label="Tel/Mobile"
                        value={userInfo.phoneNumber || ''}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        autoComplete="off"
                    />
                    <TextField
                        hidden={!validationErrors.latitude}
                        name="latitude"
                        label="Latitude"
                        value={userInfo.latitude || ''}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        autoComplete="off"
                    />
                    <TextField
                        hidden={!validationErrors.longitude}
                        name="longitude"
                        label="Longitude"
                        value={userInfo.longitude || ''}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        autoComplete="off"
                    />
                    <Button variant="contained" color="primary" style={{ marginTop: "10px" }} onClick={handleProfileSave}>
                        Speichern
                    </Button>
                    <br/>
                    <br/>
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                </>
            )}
        </div>
    );
};

export default ProfileInformation;