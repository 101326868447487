import React, { useEffect, useState } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Typography from "@mui/material/Typography";
import { Paper } from "@mui/material";
import "./TopStats.css"; // Ensure this CSS file is set up for responsive design
import { Get } from "../../../../Common/WebRequest";

const MonthlyPaymentCollection = () => {
  const [loading, setLoading] = useState(true);
  const [monthlyCollection, setMonthlyCollection] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await Get("PaymentReports/monthly-payment-collection");
        if (response && response.isSuccess) {
          setMonthlyCollection(response.result);
        } 
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);
  if (loading) {
    return <div>Loading...</div>;
  }
  if (!monthlyCollection.length) {
    return null
  } 
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const totalAmountPaid = payload[0].value.toLocaleString('en-US', { minimumFractionDigits: 0 });
            
      return (
        <div className="custom-tooltip" style={{ backgroundColor: "#fff", padding: "5px", border: "1px solid #ccc" }}>
          <p className="label">{`${payload[0].name} : €${totalAmountPaid}`}</p>         
        </div>
      );
    }
    return null;
  }; 
 

  return (
    <Paper elevation={3} className="top-container">
       <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', color: '#4b4b4b'}}>
       Monatliche Zahlungseingänge
      </Typography>
      <div className="chart-container">
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            data={monthlyCollection}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="yearMonth" />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            <Legend wrapperStyle={{ fontSize: '15px', paddingBottom:'15px' }} />
            <Line type="monotone" dataKey="totalAmountPaid" name="Total Amount Paid" stroke="#8884d8" activeDot={{ r: 8 }} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </Paper>
  );
};

export default MonthlyPaymentCollection;
