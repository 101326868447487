import React from "react";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter, getStorageItem } from "../../../Common/Utility";
import { useState, useEffect } from "react";
import HeaderButtons from "./HeaderButtons/TopHeaderPage";
import Ticker from "./HeaderTitle/Ticker";
import TopUsersByVolume from "./TopStatistics/TopUsersByVolume";

import PaymentStateSummary from "./TopStatistics/PaymentStateSummary";
import MonthlyPaymentCollection from "./TopStatistics/MonthlyPaymentCollection";
import YearlyRevenueComparison from "./TopStatistics/YearlyRevenueComparison";
import ErrorCriticalityAnalysis from "./TopStatistics/ErrorCriticalityAnalysis";
import TotalPaidPerRegion from "./TopStatistics/TotalPaidPerRegion";

const Home = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const loginData = JSON.parse(localStorage.getItem("login"));
  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(() => {
    (async () => {
      let userName = await getStorageItem("login", "userName");
      let isAdmin = await getStorageItem("login", "isAdmin");
      setIsAdmin(isAdmin);
      if (userName) setUserName(capitalizeFirstLetter(userName));
      if (
        !loginData ||
        loginData.accessToken === "" ||
        !userName ||
        userName === ""
      ) {
        console.log("invalid accessToken");

        window.location.href = "/login";

        navigate("/login");
        return null;
      }
    })();
  }, [loginData]);

  if (!isAdmin) {
    navigate("/dashboard");
  }
  return (
    <div style={{ background: "white", marginTop: 1 }}>
      <br />
      <Ticker />
      <br />
      <HeaderButtons />
      <br />
      <div
        style={{
          display: "grid",

          gridTemplateColumns: "repeat(3, 1fr)",
          gap: "10px",
          padding: "5px",
          paddingTop: "0px",
        }}
      >
        <div>
          <YearlyRevenueComparison />
        </div>
        <div>
          <MonthlyPaymentCollection />
        </div>
        <div>
          <PaymentStateSummary />
        </div>
        <div>
          <TopUsersByVolume />
        </div>
        <div>
          <TotalPaidPerRegion />
        </div>
        <div>
          <ErrorCriticalityAnalysis />
        </div>
      </div>
    </div>
  );
};

export default Home;
