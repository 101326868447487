import * as React from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { Box, FormControl, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import CachedIcon from "@mui/icons-material/Cached";
import { useState } from "react";
import { useEffect } from "react";

import { Get } from "../../../../Common/WebRequest";
import {
  getStorageItem,
  getValue,
  setStorageItem,
} from "../../../../Common/Utility";
import { blue, red } from "@mui/material/colors";
import DropDownRegion from "../../../../components/DropDown/Region/DropDownRegion";
import columnsBuilder from "./columnsBuilder";

export default function ApprovedPartners() {
  const [approvedParentsData, setApprovedParentsData] = useState([]);

  const [reload, setReload] = useState(false);
  const [regionData, setRegionData] = useState([]);
  const [error, setError] = useState("");
  const [regionId, setRegionId] = useState(0);
  const stateAprrovedUser = 2;
  let withAll = true;

  useEffect(() => {
    async function fetchData() {
      try {
        const regionIdValue = await getStorageItem("payment", "regionId");
        const fetchedRegionId = getValue(regionIdValue);

        setRegionId(fetchedRegionId === 0 ? -1 : fetchedRegionId);
      } catch (error) {
        setError("Error reading local storage.");
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    (async () => {
      if (getValue(regionId) === 0) {
        return;
      }
      let regions = await Get("Region/Permission-Users");
      if (regions.isSuccess) {
        setRegionData(regions.result);
      }
      let res = await Get(
        "User/UsersByState/" + regionId + "/" + stateAprrovedUser
      );

      if (res.isSuccess) {
        setApprovedParentsData(res.result);
        setError(null);
      } else {
        if (res.Status !== 404) setError(res.Status + "/" + res.Error);
      }
    })();
  }, [regionId, reload]);

  function handleRefresh() {
    setApprovedParentsData([]);
    setReload(!reload);
  }

  function DefinedToolbar() {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector>
            <GridToolbarDensitySelector />
          </GridToolbarDensitySelector>
          <GridToolbarExport
            csvOptions={{
              fileName: "Wartende Partner",
              delimiter: ";",
              utf8WithBom: true,
            }}
          />
          <GridToolbarQuickFilter />
          <Button
            color="primary"
            startIcon={<CachedIcon />}
            onClick={handleRefresh}
          >
            Refresh
          </Button>
        </GridToolbarContainer>
      </Box>
    );
  }

  async function handleRegionValue(e) {
    setRegionId(parseInt(e.target.value, 10));
    setApprovedParentsData([]);
    setError("");
    await setStorageItem("payment", "regionId", e.target.value);
  }

  return (
    <>
      <br />
      <Box
        display="flex"
        flexDirection="row"
        overflow="auto"
        alignItems="center"
      >
        <Box>
          <Typography
            variant="headline"
            component="h3"
            align="left"
            paddingLeft={"10px"}
            color={blue[500]}
          >
            Bewilligte Partner
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          sx={{ marginRight: 2 }}
        >
          <Typography
            variant="subtitle1"
            color={blue[500]}
            fontWeight="Bold"
            sx={{ marginLeft: 7, marginRight: 2 }}
          >
            Region
          </Typography>
          <FormControl>
            <DropDownRegion
              permission="Permission-Users"
              regionID={regionId}
              onChange={handleRegionValue}
              withAll={withAll}
              // onPopulate={handleOnPopulate}
            />
          </FormControl>
        </Box>
      </Box>
      <br />
      <Typography
        variant="caption"
        component="h6"
        align="left"
        color={red.A400}
      >
        {error}
      </Typography>

      <Box
        display="block"
        flexDirection="row"
        alignItems="center"
        height="500px"
        overflow="auto"
      >
        <DataGrid
          // apiRef={apiRef}
          columns={columnsBuilder({ regionData })}
          disableRowSelectionOnClick
          initialState={{
            ...approvedParentsData.initialState,
            columns: {
              ...approvedParentsData.initialState?.columns,
              columnVisibilityModel: { userId: false },
            },
            filter: {
              filterModel: {
                items: [{ field: "fullName", operator: "contains", value: "" }],
              },
            },
          }}
          slots={{ toolbar: DefinedToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          density="compact"
          rows={approvedParentsData}
          getRowId={(row) => row.userId}
        />
      </Box>
    </>
  );
}
