import "./DropDownRegion.css";
import { useEffect, useState } from "react";
import { Get } from "../../../Common/WebRequest";
import { getString } from "../../../Common/Utility";

function DropDownRegion(props) {
  const [error, setError] = useState("");
  const [regionsData, setRegionsData] = useState([]);

  let regionId = 0;
  let withAll = props.withAll;
  if (!withAll && props.regionID > 0) {
    regionId = props.regionID;
  } else regionId = props.regionID;

  useEffect(() => {
    (async () => {
      const permissionPath = getString(props.permission);
      const endpointMap = {
        "Permission-Users": "region/Permission-Users",
        "Permission-Payments": "region/Permission-Payments",
        "Permission-Allowed": "region/Permission-Allowed",
      };

      const endpoint = endpointMap[permissionPath] || "region";
      const res = await Get(endpoint);

      if (res.isSuccess) {
        setRegionsData(res.result);
      } else {
        setError(`${res.Status}/${res.Error}/${res.ErrorDetail}`);
      }
    })();
  }, [props.permission]); // Added props.permission as a dependency to re-run useEffect when it changes

  return (
    <>
      <select
        className="form-select"
        //defaultValue={regionId}
        value={regionId}
        id="region"
        onChange={props.onChange}
      >
        {withAll && <option id={-1} key={-1} value={-1} label="@ALL"></option>}
        {regionsData.map((region) => {
          return (
            <option
              id={region.regionId}
              key={region.regionId}
              value={region.regionId}
              label={region.regionName}
            >
              {region.regionId}
            </option>
          );
        })}
      </select>
      {/* {regionId > 0 && props.onPopulate(regionsData.map((region) => region.id))} */}
    </>
  );
}

export default DropDownRegion;
