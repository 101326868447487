import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

const CookieDialog = () => {
  const cookieAgree = JSON.parse(localStorage.getItem("cookieAgreement"));
  const [isAgree, setIsAgree] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (
      cookieAgree &&
      cookieAgree.isAgree === true &&
      cookieAgree.date_of_accept_cookies
    ) {
      setIsAgree(true);
    } else {
      setOpen(true);
      //console.log("Cookie dialog opened");
    }
  }, [isAgree, cookieAgree]);

  const handleAgree = () => {
    localStorage.setItem(
      "cookieAgreement",
      JSON.stringify({
        isAgree: true,
        //date_of_accept_cookies: new Date().toISOString().split("T")[0],
        date_of_accept_cookies: new Date(2024, 9, 25)
          .toISOString()
          .split("T")[0],
      })
    );
    setOpen(false);
    setIsAgree(true);
  };

  const handleClose = () => {
    setOpen(false);
    navigator.cookieEnabled && window.location.reload();
  };

  const dialogActionsStyle = {
    padding: "16px 24px",
    justifyContent: "space-between",
  };

  // If user has agreed, don't show the dialog
  if (isAgree) {
    return null;
  }

  const redButtonStyle = {
    fontWeight: "bold",
    fontSize: "16px",
    padding: "8px 16px",
    backgroundColor: "#e53935",
    color: "#fff",
    transition: "background-color 0.2s ease-in-out",
  };

  const blueButtonStyle = {
    fontWeight: "bold",
    fontSize: "16px",
    padding: "8px 16px",
    backgroundColor: "#1976d2",
    color: "#fff",
    transition: "background-color 0.2s ease-in-out",
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle
        style={{ color: "#fff", backgroundColor: "#1976d2", padding: "16px" }}
      >
        Cookie Zustimmung
      </DialogTitle>
      <DialogContent>
        <Typography>
          <br />
          <br />
          Einige Cookies dienen technischen Zwecken, wie beispielsweise der
          Speicherung temporärer und wichtiger Daten im System. Dadurch wird der
          Zugriff auf Listen und Daten einfacher und flexibler gestaltet.
          Cookies werden auch zur Verwaltung von Systemanmeldungen verwendet. Im
          Falle einer Verweigerung der Zustimmung ist eine vollständige Nutzung
          des Systems nicht möglich.
          <br />
        </Typography>
      </DialogContent>
      <DialogActions style={dialogActionsStyle}>
        <Button
          onClick={handleClose}
          style={redButtonStyle}
          onMouseEnter={(event) =>
            (event.currentTarget.style.backgroundColor = "#c62828")
          }
          onMouseLeave={(event) =>
            (event.currentTarget.style.backgroundColor = "#e53935")
          }
        >
          Nicht zustimmen
        </Button>
        <Button
          onClick={handleAgree}
          style={blueButtonStyle}
          onMouseEnter={(event) =>
            (event.currentTarget.style.backgroundColor = "#0d47a1")
          }
          onMouseLeave={(event) =>
            (event.currentTarget.style.backgroundColor = "#1976d2")
          }
          autoFocus
        >
          Zustimmen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CookieDialog;
