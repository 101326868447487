import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, FormControl, InputLabel, Select, MenuItem, Alert } from '@mui/material';

import CheckError from "@mui/icons-material/HighlightOffRounded";
import WaitIcon from '@mui/icons-material/HourglassEmpty';
import { Get } from '../../../../Common/WebRequest';
import { slice } from '../../../../Common/Utility';
const months = [
  { value: '01', label: 'Januar' },
  { value: '02', label: 'Februar' },
  { value: '03', label: 'März' },
  { value: '04', label: 'April' },
  { value: '05', label: 'Mai' },
  { value: '06', label: 'Juni' },
  { value: '07', label: 'Juli' },
  { value: '08', label: 'August' },
  { value: '09', label: 'September' },
  { value: '10', label: 'Oktober' },
  { value: '11', label: 'November' },
  { value: '12', label: 'Dezember' },
];

  const BankExportForm = ({ open, onClose,currentRegionId, currentYear }) => {  
  const [selectedMonth, setSelectedMonth] = useState(months[new Date().getMonth()].value);
  const [reference, setReference] = useState(`${months[new Date().getMonth()].label}.${currentYear}`);
  const[error,setError]= useState("")  
  const [isWaiting,setIsWaiting]=useState(false)
  useEffect(() => {
    if (open) {
      setError(""); // Clear error state
    }
  }, [open])
  useEffect(() => {
    setReference(`${months.find(m => m.value === selectedMonth).label}.${currentYear}`);
  }, [selectedMonth, currentYear]);

  const handleMonthChange = (event) => {
    const month = event.target.value;
    setSelectedMonth(month);
  };

    const handleSubmit = async () => {          
     try {
        setIsWaiting(true)
        if (  !selectedMonth || !reference.trim() || !currentYear) {
            setError("Please fill in all fields correctly.");
            return;
        }
        if(currentRegionId<=0)
        {
            setError("Please select a valid Region correctly.");
            return;
        }

        const selectedMonthIndex = months.findIndex(month => month.value === selectedMonth); // Ensure 'selectedMonth' is set appropriately beforehand
        const formattedMonth = (selectedMonthIndex + 1).toString().padStart(2, '0');
        const yearMonth = `${currentYear}-${formattedMonth}`;
    
        const response = await Get(`Payment/ExportBankTransfersCsv/${currentRegionId}/${yearMonth}/${reference}`);

        if (response && response.isSuccess) {
            // Specify UTF-8 encoding and include a BOM (Byte Order Mark) for UTF-8
            const utf8BOM = new Uint8Array([0xEF, 0xBB, 0xBF]);
            const blob = new Blob([utf8BOM, response.result], { type: 'text/csv;charset=utf-8' });            
            const downloadUrl = window.URL.createObjectURL(blob);            
            const downloadLink = document.createElement('a');
            downloadLink.href = downloadUrl;
            downloadLink.setAttribute('download', `BankTransfers-${yearMonth}.csv`); // Name the download file            
            document.body.appendChild(downloadLink);            
            downloadLink.click();            
            document.body.removeChild(downloadLink);
            window.URL.revokeObjectURL(downloadUrl);
            onClose();
        }
         else {
            setError(response.Error || "An unknown error occurred during the CSV download.");
        }
    } catch (error) {
        console.error('Error fetching export:', error);
        setError(`Error fetching User-Profile data: ${error.message}`);
    }
    finally{ 
        setIsWaiting(false)   
    }
};


  return (
    <Dialog open={open} onClose={onClose} sx={{ '& .MuiDialog-paper': { width: '350px' } }}>
      {isWaiting && (
         <Alert severity="info" sx={{ backgroundColor: '#f0f0f0', color: '#333' }}>
         <WaitIcon />
         Please Wait...
       </Alert>
       
        )}
      <DialogTitle>Bank Export CSV</DialogTitle>
      <DialogContent>
      {error&& error.length > 0  && (
          <div className="alert alert-danger" role="alert">
            <CheckError />
            {slice(error, 200, "...")}
          </div>
        )}
        <FormControl fullWidth margin="normal">
         
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel id="month-select-label">Monat*</InputLabel>
          <Select
            labelId="month-select-label"
            id="month-select"
            value={selectedMonth}
            label="Monats"
            required
            onChange={handleMonthChange}
          >
            {months.map((month) => (
              <MenuItem key={month.value} value={month.value}>{month.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          margin="normal"
          fullWidth
          id="reference"
          label="Referenz"
          name="reference"
          value={reference}
          onChange={(e) => setReference(e.target.value.substring(0, 50))}
          required
        />
      </DialogContent>
      <DialogActions>
        <Button  disabled={isWaiting} onClick={onClose}>Schließen</Button>
        <Button disabled={isWaiting} onClick={handleSubmit} variant="contained">Export CSV</Button>
      </DialogActions>
    </Dialog>
  );
};

export default BankExportForm;
