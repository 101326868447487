import DoneIcon from "@mui/icons-material/Done";
import UndoIcon from "@mui/icons-material/Undo";
import React, { useEffect, useState } from "react";
import CheckError from "@mui/icons-material/HighlightOffRounded";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import {
  getStorageItem,
  getValue,
  slice,
} from "../../../../../../Common/Utility";
import { Get, Post, Put } from "../../../../../../Common/WebRequest";
import { getCoordinatesForAddress } from "../../../../../../Common/locationHelper";

const UserDialog = ({
  open,
  onClose,
  onSave,
  UserId,
  IsForPermission,
  reload,
  isUserStateReviewOnly, // if true, then user can't edit user state
}) => {
  const [userData, setUserData] = useState([]);
  const [regionData, setRegionData] = useState([]);

  const [title, setTitle] = useState("");
  const [error, setError] = useState("");
  const [userIdCreate, setUserIdCreate] = useState(0);
  const [apiKey, setApiKey] = useState("");
  const [selectedRegionName, setSelectedRegionName] = useState("");
  const initialFormState = {
    userId: 0,
    fullName: "",
    email: "",
    password: "",
    street: "",
    zip: "",
    regionId: "",
    iban: "",
    groupId: 10,
    isForPermission: IsForPermission,
    /*used to show isAdmin, then UserGroupId =90 else 20, but if false =>20*/
    userIdCreate: 0,
    phoneNumber: "",
    isAdmin: false,
    isActive: true,
    latitude: 0,
    longitude: 0,
  };
  const constructAddress = (userData, selectedRegionName) => {
    return `${userData.street}, ${userData.zip} ${selectedRegionName}, Germany`;
  };
  useEffect(() => {
    const fetchApiKey = async () => {
      try {
        const googleMapInfo = await Get("CompanyInformation/GoogleMapInfo");
        if (googleMapInfo.isSuccess) {
          setApiKey(googleMapInfo.result.googleApiKey);
        } else {
          setError(googleMapInfo.Status + "/" + googleMapInfo.Error);
        }
      } catch (error) {
        setError("Error fetching region data: " + error.message);
      } finally {
      }
    };
    fetchApiKey();
  }, []);
  useEffect(() => {
    if (open) {
      setError("");
    }
  }, [open]);
  useEffect(() => {
    (async () => {
      try {
        setUserData(initialFormState);
        setError("");
        let UserIdCreate = await getStorageItem("login", "userId");
        if (getValue(UserIdCreate) === 0) {
          setError("User login error!");
          return;
        }
        setUserIdCreate(UserIdCreate);

        if (getValue(UserId) === 0) {
          setUserData(initialFormState);
          let regions = await Get("region/Permission-Users");
          if (regions && !regions.isSuccess) {
            setError(regions.Error);
            return;
          }
          setRegionData(regions.result);
          if (initialFormState.isForPermission) setTitle("Benutzer hinzufügen");
          else setTitle("Partner hinzufügen");
        } else {
          let user = await Get("user/" + UserId);
          let regions = await Get("region/Permission-Users");
          if (regions && !regions.isSuccess) {
            setError(regions.Error);
            return;
          }
          setRegionData(regions.result);
          setUserData(user.result);
          setSelectedRegionName(
            regionData.find(
              (region) => region.regionId === user.result.regionId
            ).regionName
          );
          if (initialFormState.isForPermission)
            setTitle("Benutzer aktualisieren");
          else setTitle("Partner aktualisieren");
        }
      } catch (ex) {
        setError(ex);
      }
    })();
  }, [UserId, !open]);

  const fetchCoordinates = async (address) => {
    try {
      const coordinates = await getCoordinatesForAddress(
        apiKey,
        address,
        selectedRegionName,
        userData.zip
      );
      if (coordinates.error) {
        let error = "Falsche: " + coordinates.error;
        setError(error);
        throw new Error(error);
      } else if (coordinates.lat && coordinates.lng) {
        return {
          lat: coordinates.lat.toString(),
          lng: coordinates.lng.toString(),
        };
      } else {
        let error = "Ungültige Adresse angegeben...";
        setError(error);
        throw new Error(error);
      }
    } catch (error) {
      console.error("Failed to fetch coordinates:", error);
      throw error;
    }
  };
  async function handleChange(event) {
    const { name, value, type, checked } = event.target;
    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: type === "checkbox" ? checked : value,
    }));
    //console.log("==> type: " + type + " , " + checked);
    // console.log(userData);

    if (name === "regionId") {
      const selectedRegion = regionData.find(
        (region) => region.regionId === value
      ).regionName;
      setSelectedRegionName(selectedRegion);
    }
  }

  const handleCancel = () => {
    console.log(userData);
    onClose();
    return;
  };

  async function handleSave() {
    // validate
    if (userData.zip.toString().trim() === "") userData.zip = 0;
    var result;
    if (IsForPermission) {
      if (userData.isAdmin) userData.groupId = 90;
      else userData.groupId = 20;
    }

    let currentAddress = constructAddress(userData, selectedRegionName);
    const coords = await fetchCoordinates(currentAddress);
    if (coords) {
      userData.latitude = coords.lat;
      userData.longitude = coords.lng;
    }
    userData.userIdCreate = userIdCreate; // creatorId
    userData.regionId = getValue(userData.regionId);
    userData.isForPermission = IsForPermission;
    if (isUserStateReviewOnly) userData.isActive = null;
    if (UserId > 0) {
      //Edit
      result = await Put("User", UserId, userData);
    } //Add
    else {
      result = await Post("User", userData);
    }
    if (result.isSuccess) {
      await onSave(result.result.userId);
      onClose();
      //await afterSave(result.result.userId); // select current row in UserLogins
      setError("");
    } else {
      if (result.Status === 400) {
        setError(result.ValidationError);
      } else setError(result.Status + ": " + result.Error);
    }
  }

  return (
    <Dialog
      disableRestoreFocus
      open={open}
      onClose={handleCancel}
      fullWidth
      maxWidth="xs"
      PaperProps={{
        style: {
          minHeight: "150px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        },
      }}
    >
      <DialogTitle className="dialog-title">{title}</DialogTitle>
      <DialogContent>
        {error.length > 0 && !error.includes("No Results") && (
          <div className="alert alert-danger" role="alert">
            <CheckError />
            {slice(error, 1000, "...")}
          </div>
        )}
        <TextField
          autoFocus={true}
          autoComplete="off"
          name="fullName"
          id="fullName"
          label="Name"
          required
          fullWidth
          value={userData && userData.fullName}
          onChange={handleChange}
          inputProps={{
            maxLength: 50,
            minLength: 3,

            autoComplete: "not-off",
          }}
          style={{ marginBottom: "1rem", marginTop: "1rem" }}
        />
        <TextField
          autoFocus={false} // Usually, only one element should be autoFocus, if needed.
          autoComplete="not-off"
          name="email"
          id="email-x"
          label="E-Mail"
          type="email" // Specify the type for email validation
          required
          fullWidth
          value={userData && userData.email} // Ensure you're reading from your state
          onChange={handleChange} // Re-use your existing handleChange function
          inputProps={{
            maxLength: 255, // Adjust based on your requirements
            autoComplete: "not-off",
          }}
          style={{ marginBottom: "1rem" }}
        />
        <TextField
          name="password"
          id="password"
          label="Password"
          type="password"
          //required
          fullWidth
          value={userData && userData.password}
          onChange={handleChange}
          autoComplete="new-passwords"
          inputProps={{
            minLength: 6,
          }}
          style={{ marginBottom: "1rem" }}
        />
        <FormControl
          fullWidth
          variant="outlined"
          style={{ marginBottom: "1rem" }}
        >
          <InputLabel id="regionId">ORT (Region) *</InputLabel>
          <Select
            name="regionId"
            id="regionId"
            value={userData && userData.regionId}
            onChange={handleChange}
            required
            label="ORT (Region) *"
            autoComplete="not-off"
          >
            {regionData.length > 0 &&
              regionData.map((region) => (
                <MenuItem key={region.regionId} value={region.regionId}>
                  {region.regionName}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <TextField
          name="street"
          id="street"
          label="Straße"
          autoComplete="not-off"
          fullWidth
          value={userData && userData.street}
          onChange={handleChange}
          inputProps={{
            autoComplete: "not-off",
          }}
          style={{ marginBottom: "1rem" }}
        />
        <TextField
          name="zip"
          id="zip"
          label="PLZ"
          fullWidth
          value={userData && userData.zip}
          onChange={handleChange}
          autoComplete="off"
          inputProps={{
            maxLength: 5,
            minLength: 5,
            autoComplete: "not-off",
          }}
          style={{ marginBottom: "1rem" }}
        />

        <TextField
          autoFocus={false}
          autoComplete="off"
          name="iban"
          id="iban"
          label="IBAN"
          required
          fullWidth
          value={userData && userData.iban}
          onChange={handleChange}
          inputProps={{
            maxLength: 50,
            minLength: 3,

            autoComplete: "not-off",
          }}
          style={{ marginBottom: "1rem", marginTop: "1rem" }}
        />
        <TextField
          name="phoneNumber"
          id="tel"
          label="Telefon"
          fullWidth
          value={userData && userData.phoneNumber}
          onChange={handleChange}
          autoComplete="not-off"
        />
        <TextField
          name="latitude"
          id="latitude"
          label="Latitude"
          fullWidth
          value={userData && userData.latitude}
          onChange={handleChange}
          autoComplete="not-off"
          style={{ marginBottom: "1rem", marginTop: "1rem" }}
        />
        <TextField
          name="longitude"
          id="longitude"
          label="Longitude"
          fullWidth
          value={userData && userData.longitude}
          onChange={handleChange}
          autoComplete="not-off"
          style={{ marginBottom: "1rem" }}
        />
        <FormControlLabel
          hidden={isUserStateReviewOnly}
          // hidden={!IsForPermission}
          control={
            <Checkbox
              hidden={isUserStateReviewOnly}
              checked={userData && userData.isActive}
              onChange={handleChange}
              name="isActive"
              color="primary"
            />
          }
          label="Aktiv"
        />
        <FormControlLabel
          hidden={!IsForPermission}
          control={
            <Checkbox
              checked={userData && userData.isAdmin}
              onChange={handleChange}
              name="isAdmin"
              color="primary"
            />
          }
          label={
            <div>
              <span>Admin</span>
              <span style={{ fontSize: "0.8rem", color: "red" }}>
                {" *"}
                (Admin can access control panel)
              </span>
            </div>
          }
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCancel}
          variant="outlined"
          startIcon={<UndoIcon />}
        >
          Abbrechen
        </Button>
        <Button onClick={handleSave} variant="contained" endIcon={<DoneIcon />}>
          Speichern
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserDialog;
