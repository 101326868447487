import { GridActionsCellItem, GridRowModes } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import SystemAdminIcon from "@mui/icons-material/ManageAccounts";

const columnsBuilder = (props) => {
  const {
    rowModesModel,
    handleEditClick,
    handleCancelClick,
    handleSaveClick,
    handleDeleteClick,
  } = props;
  const columns = [
    { field: "userId", headerName: "ID", width: 50, editable: false },

    {
      field: "isAdminIcon",
      headerName: "*",
      width: 0,
      sortable: false,
      filterable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        // Check if the user's full name is 'system', case-insensitively
        if (params.row.fullName?.toLowerCase() === "system") {
          return <SystemAdminIcon style={{ color: "#4caf50" }} />;
        }

        // Existing condition for admin users
        return params.row.isAdmin ? (
          <AdminPanelSettingsIcon style={{ color: "#1976d2" }} /> // Blue icon for admin users
        ) : (
          <></> // Render nothing if the user is not an admin and not the system-built-in user
        );
      },
    },

    { field: "groupId", headerName: "Group Id", width: 50, editable: false },
    {
      field: "fullName",
      headerName: "Name",
      type: "text",
      editable: true,
      width: 180,
    },

    {
      field: "isAdmin",
      headerName: "Admin",
      width: 100,
      type: "boolean",
      editable: true,
    },
    {
      field: "isActive",
      headerName: "aktiv",
      width: 100,
      type: "boolean",
      editable: true,
    },
    {
      field: "email",
      headerName: "E-Mail",
      width: 250,
      type: "text",
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return columns;
};

export default columnsBuilder;
