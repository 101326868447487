import { Box, Typography } from "@mui/material";
import {
  DataGrid,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import React from "react";
import columnsBuilder from "./columnsBuilder";
import { generateUUID, getToDay, slice } from "../../../../Common/Utility";
import { useEffect } from "react";
import { useState } from "react";
import { Get } from "../../../../Common/WebRequest";
import { blue } from "@mui/material/colors";
import { useParams, useLocation } from "react-router-dom";
import { useRef } from "react";

const SystemLogs = (props) => {
  const [logData, setLogData] = useState([]);
  const [error, setError] = useState("");
  const dateFrom = useRef();
  const dateTo = useRef();
  const location = useLocation();
  //const filter = useLocation().state;
  const searchParams = new URLSearchParams(location.search);
  const filter = searchParams.get("state");

  useEffect(() => {
    (async () => {
      await loadLogData();
      if (filter !== "") {
        console.log("Filter =>> " + filter);
      }
    })();
  }, [filter]);

  async function loadLogData() {
    setError("");
    setLogData([]);
    let res = {};
    res = await Get(
      "SystemLog/" + dateFrom.current.value + "/" + dateTo.current.value
    );

    if (res.isSuccess) {
      setLogData(res.result);
    } else {
      if (res.result && !res.result.includes("200/No Results"))
        setError(res.Status + "/" + res.Error + "/" + res.ErrorDetail);
    }
  }
  async function handleShow(e) {
    e.preventDefault();

    await loadLogData();

    console.log(dateFrom.current.value, dateTo.current.value);
  }
  return <>{getGrid()}</>;
  function DefinedToolbar() {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <br></br>
        <GridToolbarContainer>
          {/* <Button color="primary" startIcon={<AddIcon />} onClick={handleAddClick}>
        Neu
      </Button> */}

          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector>
            <GridToolbarDensitySelector />
          </GridToolbarDensitySelector>
          <GridToolbarExport
            csvOptions={{
              fileName: "SystemLogs",
              delimiter: ";",
              utf8WithBom: true,
            }}
          />

          <GridToolbarQuickFilter />
          {/* <Button color="primary" startIcon={<SyncIcon />} onClick={handleRefresh}>
        Refresh
      </Button> */}
        </GridToolbarContainer>
      </Box>
    );
  }
  function getGrid() {
    return (
      <>
        <br></br>
        {error.length > 0 && (
          <div className="alert alert-danger" role="alert">
            {slice(error, 100, "...")}
          </div>
        )}
        <div className="App" style={{ backgroundColor: "white" }}>
          <Typography
            variant="headline"
            component="h2"
            align="left"
            color={blue[500]}
          >
            System Logs
          </Typography>
        </div>
        <br />
        <form>
          <label>
            Von
            <input
              className="form-control"
              id="from"
              type="date"
              ref={dateFrom}
              defaultValue={getToDay()}
            ></input>
          </label>
          <label>
            Zum
            <input
              className="form-control"
              id="to"
              type="date"
              ref={dateTo}
              defaultValue={getToDay()}
            ></input>
          </label>
          &nbsp;&nbsp;&nbsp;
          <button className="btn btn-primary" onClick={handleShow}>
            Vorschu
          </button>
        </form>
        <br />
        <Box
          sx={{
            width: "1",
            height: "800px",
          }}
        >
          <DataGrid
            density="compact"
            rowSelection={false}
            rows={logData}
            columns={columnsBuilder()}
            getRowId={(row) => generateUUID()}
            slots={{ toolbar: DefinedToolbar }}
            // rowsPerPageOptions={[]}
            rowsPerPageOptions={100}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 100, page: 0 },
              },
              filter: {
                filterModel: {
                  items: [
                    { field: "message", operator: "contains", value: "" },
                  ],
                  quickFilterValues: [filter],
                },
              },
            }}
          />
        </Box>
      </>
    );
  }
};

export default SystemLogs;
