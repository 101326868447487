import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const PasswordUpdatedDialog = ({ open, handleClose }) => {
  return (    
          <Dialog open={open} onClose={(event, reason) => reason !== 'backdropClick' && handleClose()}  >
                <DialogTitle>Passwort aktualisieren</DialogTitle>
                <DialogContent>
                Ihr Passwort wurde aktualisiert. Sie müssen sich erneut anmelden.
                </DialogContent>
                <DialogActions>
                  <Button
                    component={Link}
                    to="/login"
                    onClick={handleClose}
                    variant="contained"
                    color="primary"
                  >
                    Anmelden
                  </Button>
                </DialogActions>
              </Dialog>
            );
          };

export default PasswordUpdatedDialog;
