import React, { useEffect, useState } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Typography from "@mui/material/Typography";
import { Get } from "../../../../Common/WebRequest";
import { Paper } from "@mui/material";
import "./TopStats.css"; // Ensure this CSS file is set up for responsive design

const TopUsersByVolume = () => {
  const [loading, setLoading] = useState(true);
  const [topUsers, setTopUsers] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await Get("PaymentReports/top-users-by-volume");
        if (response && response.isSuccess) {
          setTopUsers(response.result);
        } else {
          console.error("Failed to fetch top users by volume.");
        }
      } catch (error) {
        console.error("Error fetching top users by volume:", error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }
  if (!topUsers.length) {
    return null;
  } 
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const totalAmountPaid = payload[0].value.toLocaleString('en-US', { minimumFractionDigits: 0 });            
      return (
        <div className="custom-tooltip" style={{ backgroundColor: "#fff", padding: "5px", border: "1px solid #ccc" }}>
          <p className="label">{`${payload[0].name} : €${totalAmountPaid}`}</p>         
        </div>
      );
    }
    return null;
  };
  return (
    <Paper elevation={3} className="top-container">
       <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', color: '#4b4b4b'}}>
       Top-Benutzer nach Volumen
      </Typography>
      <div className="chart-container">
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={topUsers}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="fullName" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend wrapperStyle={{ fontSize: '15px', paddingBottom:'15px' }} />
          <Bar dataKey="totalAmountPaid" fill="#8884d8" name="Total Amount Paid" />
        </BarChart>
      </ResponsiveContainer>
      </div>
    </Paper>
  );
};

export default TopUsersByVolume;
