import React from 'react';
import { Modal, Paper, Grid, Typography, Button } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const SuccessMessage = ({ open, onClose, title, body }) => {
  const handleBackdropClick = (event) => {
    // Prevent clicks outside the modal from closing it
    event.stopPropagation();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="success-modal-title"
      aria-describedby="success-modal-description"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      slotProps={{
        backdrop: {
          onClick: handleBackdropClick,
        },
      }}
    >
      <Paper
        style={{
          padding: '32px',
          maxWidth: '800px',
          width: '450px',
          height: '300px',
          textAlign: 'center',
        }}
        elevation={5}
      >
        <Grid container spacing={2} justifyContent="center" alignItems="center" marginTop={2}>
          <Grid item>
            <CheckCircleIcon sx={{ fontSize: 60, color: '#4caf50' }} />
          </Grid>
          <Grid item>
            <Typography   variant="h4" style={{ color: '#4caf50', fontWeight: 'bold' }}>
              {title}
            </Typography>
          </Grid>
        </Grid>
        <Typography component="pre"  variant="h6" style={{ marginBottom: '20px', marginTop: '10px' }}>
          {body}
        </Typography>
        <Button variant="contained" color="primary" onClick={onClose} style={{ marginTop: '20px', width: '100px' }}>
          OK
        </Button>
      </Paper>
    </Modal>
  );
};

export default SuccessMessage;
