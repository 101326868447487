import React from "react";
import UserLoginsGrid from "./UserLogins/UserLoginsGrid";
import UserPermissions from "./UserPermissions/UserPermissions";
import { useState } from "react";

export default function UserManagement() {
  const [selectedUserId, setSelectedUserId] = useState(0);
  const [selectedIsActive, setSelectedIsActive] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState(0);
  const [selectedFullName, setSelectedFullName] = useState("");
  const [reload, setReload] = useState(false);

  const handleUserSelected = (userId, isActive, groupId, userName) => {
    setSelectedUserId(userId);
    setSelectedIsActive(isActive);
    setSelectedGroupId(groupId);
    setSelectedFullName(userName);
  };
  const handleOnSave = () => {
    setReload(!reload);
    //props.onSelectUser();
  };

  return (
    <>
      <UserLoginsGrid onSelectUser={handleUserSelected} OnSave={handleOnSave} />
      <UserPermissions
        UserId={selectedUserId}
        GroupId={selectedGroupId}
        IsActive={selectedIsActive}
        FullName={selectedFullName}
        ReloadUserPermission={reload}
        // OnNewSaved={handleOnNewSaved}
      />
    </>
  );
}
