import React, { useState, useRef, useEffect } from "react";
import {
  Checkbox,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  FormControlLabel,
  Typography,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { Post } from "../../../../Common/WebRequest";
//`{Greetings_FirstName}
const ConfirmationDialogUI = ({
  onClose,
  onConfirm,
  selectedRows,
  isError,
}) => {
  const [emailConfirmation, setEmailConfirmation] = useState(true);
  const [WithUserManual, setWithUserManual] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [subject, setSubject] = useState("Bestätigung des Benutzerkontos");
  const [emailBody, setEmailBody] = useState(
    `Sehr geehrte Damen und Herren, 
Ihr Benutzerkonto wurde erfolgreich bestätigt.
Bitte melden Sie sich mit Ihrer E-Mail-Adresse an: {LoginPage} `
  );
  const subjectInputRef = useRef(null);
  const [error, setError] = useState("");
  useEffect(() => {
    if (emailConfirmation && subjectInputRef.current) {
      subjectInputRef.current.focus();
    }
  }, [emailConfirmation]); //WithUserManual

  const handleConfirm = async () => {
    console.log("Confirm handled... sending to Web-API");
    console.log(selectedRows);
    try {
      setIsSending(true);
      var approveUsersDto = {
        withEmail: emailConfirmation,
        withUserManual: WithUserManual,
        subject: subject,
        body: emailBody,
        userIds: selectedRows,
      };
      const res = await Post("User/ApproveUsers", approveUsersDto);

      if (res.isSuccess) {
        onConfirm(selectedRows, emailConfirmation, subject, emailBody);
        isError = false;
      } else {
        isError = true;
        setError(res.Error);
      }
    } catch (error) {
      isError = true;
      setError("An error occurred while processing your request.");
    } finally {
      setIsSending(false);
    }
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <Dialog open={true} onClose={handleCancel}>
      <DialogTitle className="dialog-title-box">
        Genehmigungsbestätigung
      </DialogTitle>
      {error.length > 0 && (
        <Typography variant="body2" color="error" ml={2}>
          {error}
        </Typography>
      )}

      <DialogContent>
        <Box
          sx={{
            width: "450px",
            height: "380px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                hidden={true}
                checked={emailConfirmation}
                onChange={() => setEmailConfirmation(!emailConfirmation)}
              />
            }
            // label="E-Mail Senden"
          />
          <FormControlLabel
            control={
              <Checkbox
                hidden={true}
                checked={WithUserManual}
                onChange={() => setWithUserManual(!WithUserManual)}
              />
            }
            // label="Mit Benutzerhandbuch (PDF)"
          />

          <TextField
            type="text"
            label="Betreff"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            disabled={!emailConfirmation}
            inputRef={subjectInputRef}
          />

          {/* Empty line before TextField */}
          <Typography variant="body1" mt={2} mb={1}></Typography>

          <TextField
            multiline
            rows={8}
            label="Nachrichtentext"
            placeholder="Nachrichtentext"
            value={emailBody}
            onChange={(e) => setEmailBody(e.target.value)}
            disabled={!emailConfirmation}
            style={{ width: "100%" }}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleCancel}
          sx={{ textTransform: "capitalize" }}
        >
          Abbrechen
        </Button>
        <Button
          disabled={isSending}
          variant="contained"
          onClick={handleConfirm}
          sx={{ textTransform: "capitalize" }}
          endIcon={<DoneIcon />}
        >
          Senden
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialogUI;
