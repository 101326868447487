import React, { useEffect, useState } from "react";
import { Button, Typography, Box } from "@mui/material";
import {
  AddCircleOutline as NewIcon,
  AssignmentTurnedIn as PaidIcon,
  HourglassEmpty as WaitingIcon,
} from "@mui/icons-material";

import NewPayment from "./NewPayment";
import WaitingPayments from "./WaitingPayments";

import PaidOrApprovedPayments from "./PaidOrApprovedPayments";
import { Get } from "../../../../Common/WebRequest";
import { getStorageItem } from "../../../../Common/Utility";
import ProfileMustUpdated from "./ProfileMustUpdated";

const Dashboard = ({ initComponent }) => {
  const [selectedComponent, setSelectedComponent] = useState(initComponent);
  const [paymentId, setPaymentId] = useState(0);
  const [state, setState] = useState(-1);
  const [reload, setReload] = useState(false);
  const handleButtonClick = (component, state, paymentId) => {
    setPaymentId(paymentId);
    setState(state);
    setReload(!reload);
    setSelectedComponent(component);
  };
  const [userId, setUserId] = useState(0);
  const [isUserValidate, setUserIsValidate] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    const fetchStorageItem = async () => {
      try {
        const userIdValue = await getStorageItem("login", "userId");
        setUserId(userIdValue);
      } catch (error) {
        console.error("Error fetching storage item:", error);
      }
    };
    fetchStorageItem();
  }, [userId]);

  useEffect(() => {
    const fetchUserValidation = async () => {
      try {
        if (userId === 0) return;
        const response = await Get(`User/user-validate/${userId}`);
        if (response.isSuccess) {
          setUserIsValidate(response.result);
          if (!response.result) {
            setDialogOpen(true);
          }
        }
      } catch (error) {
        setUserIsValidate(false);
        console.error("Error fetching storage item:", error);
      }
    };
    fetchUserValidation();
  }, [userId]);

  function handleOnPaymentDeleted() {
    setState(-1);
    setPaymentId(0);
  }

  const handleClose = () => {
    setDialogOpen(false);
  };
  return (
    <div style={{ marginTop: "10px", backgroundColor: "#fff" }}>
      <Box display="flex" gap={1} marginBottom={1}>
        <Button
          onClick={() => handleButtonClick("newPayment", -1, 0)}
          variant={
            selectedComponent === "newPayment" && paymentId === 0
              ? "contained"
              : "outlined"
          }
          color="primary"
          startIcon={<NewIcon />}
          style={{
            color:
              selectedComponent === "newPayment" && paymentId === 0
                ? "white"
                : "#333",
            backgroundColor:
              selectedComponent === "newPayment" && paymentId === 0
                ? "#2196f3"
                : "white",
            textTransform: "capitalize",
          }}
        >
          <Typography variant="h6">Neue Zahlung</Typography>
        </Button>
        <Button
          onClick={() => handleButtonClick("PaidOrApprovedPayments", 2, 0)}
          variant={
            selectedComponent === "PaidOrApprovedPayment"
              ? "contained"
              : "outlined"
          }
          color="primary"
          startIcon={<PaidIcon />}
          style={{
            color:
              selectedComponent === "PaidOrApprovedPayments" ? "white" : "#333",
            backgroundColor:
              selectedComponent === "PaidOrApprovedPayments"
                ? "#2196f3"
                : "white",
            textTransform: "capitalize",
          }}
        >
          <Typography variant="h6">Bewilligte Zahlungen</Typography>
        </Button>
        <Button
          onClick={() => handleButtonClick("WaitingPayments", 1, 0)}
          variant={
            selectedComponent === "WaitingPayments" ? "contained" : "outlined"
          }
          color="primary"
          startIcon={<WaitingIcon />}
          style={{
            color: selectedComponent === "WaitingPayments" ? "white" : "#333",
            backgroundColor:
              selectedComponent === "WaitingPayments" ? "#2196f3" : "white",
            textTransform: "capitalize",
          }}
        >
          <Typography variant="h6">Wartende Zahlungen</Typography>
        </Button>
      </Box>

      <div style={{ marginTop: "1px" }}>
        {/* Render the appropriate component based on selectedComponent */}
        {selectedComponent === "newPayment" && (
          <NewPayment
            state={state}
            paymentId={paymentId}
            reload={reload}
            onDelete={handleOnPaymentDeleted}
          />
        )}
        {selectedComponent === "PaidOrApprovedPayments" && (
          <PaidOrApprovedPayments handleButtonClick={handleButtonClick} />
        )}
        {selectedComponent === "WaitingPayments" && (
          <WaitingPayments handleButtonClick={handleButtonClick} />
        )}
      </div>
      <div>
        <ProfileMustUpdated open={dialogOpen} handleClose={handleClose} />
      </div>
    </div>
  );
};

export default Dashboard;
