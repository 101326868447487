import { Box, TextField, Typography, Button, Grid, Paper, Alert } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import React, { useEffect, useState } from 'react';
import { Get, Put } from '../../../../../Common/WebRequest';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { a } from '@react-spring/web';
    //   these are the DTO shapes
    const initialCompanyInfoState = {
        companyId: 0,
        webSite: '',
        companyName: '',
        address: '',
        environment: '',
        homeLink: '',
        loginPage: '',
        resetTokenEndpoint: '',
        registerTokenEndpoint: '',
        expirationInMinutes: 0,
      };
    const initialEmailConfigState = {
        emailId: 0,
        senderEmail: '',
        senderPassword: '',
        smtpServer: '',
        smtpPort: 0,       
      };      
export const Configuration = () => {
    const [emailConfig, setEmailConfig] = useState(initialEmailConfigState);
    const [companyInfo, setCompanyInfo] = useState(initialCompanyInfoState);
    const[success,setSuccess]= useState('')
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const[isSaving,setIsSaving]=useState(false)
  
    useEffect(() => {
      (async () => {
        try {
            setLoading(true);
          // Load Email Configuration
          const emailConfigData = await Get('EmailConfiguration/Default');
          if (emailConfigData && emailConfigData.isSuccess) {
            setEmailConfig(emailConfigData.result);
          } else {
            setError(emailConfigData.Error || 'Failed to load email configuration');
            return;
          }
  
          // Load Company Information
          const companyInfoData = await Get('CompanyInformation');
          if (companyInfoData && companyInfoData.isSuccess) {
            setCompanyInfo(companyInfoData.result);
          } else {
            setError(companyInfoData.Error || 'Failed to load company information');
          }
        } catch (ex) {
          setError('An error occurred while fetching data.');
        }
        finally {
            setLoading(false);
        }
      })();
    }, []);

    const handleInputChange = (e, type) => {
        const { name, value } = e.target;
        if (type === 'companyInfo') {
          setCompanyInfo({ ...companyInfo, [name]: value });
        } else {
          setEmailConfig({ ...emailConfig, [name]: value });
        }
      };
    
      const saveConfigurations = async () => {
        try {
            setIsSaving(true)
            setError('');
            setSuccess('');

               // Validate Company Information
        if (!companyInfo.webSite || !companyInfo.companyName || !companyInfo.environment||
          !companyInfo.address || 
           !companyInfo.homeLink  || !companyInfo.loginPage || !companyInfo.resetTokenEndpoint ||
             !companyInfo.registerTokenEndpoint || !companyInfo.expirationInMinutes ||
              !companyInfo.googleApiKey || !companyInfo.googleMapID) {
            setError('Please fill in all company information fields.');
            return;
        }

        // Validate Email Configuration
        if (!emailConfig.senderEmail || !emailConfig.smtpServer || !emailConfig.senderPassword || !emailConfig.smtpPort ) {
            setError('Please fill in all email configuration fields.');
            return;
        }
    
            let res = await Put('CompanyInformation/Update-CompanyInformation', companyInfo.companyId, companyInfo);
            if (!res.isSuccess) {
                setError(res.Error || 'Failed to save company information');
                return;
            }
    
            res = await Put('EmailConfiguration/Update-EmailConfiguration', emailConfig.emailId, emailConfig);
            if (!res.isSuccess) {
                setError(res.Error || 'Failed to save email configuration');
                return;
            }
    
            setSuccess('Configurations saved successfully.');
        } catch (ex) {
            setError('An error occurred while saving data.');
        }
        finally {
            setIsSaving(false);
        }
    };
    
    if (loading) {
        return <div>Loading...</div>;
      }

  return (
    
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', minHeight: '80vh', p: 1 }}>
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: '16px' }}>
        {success && <Alert icon={<CheckCircleIcon fontSize="inherit" />} severity="success" sx={{ width: '100%' }}>{success}</Alert>}
        {error && <Alert icon={<ErrorIcon fontSize="inherit" />} severity="error" sx={{ width: '100%' }}>{error}</Alert>}
      </Box>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} md={6}>      
          <Paper elevation={3} sx={{ p: 2 }}>        
            <Typography variant="h5" gutterBottom>Company Information</Typography>            
            <Box display="flex" flexDirection="column">
            <Box sx={{ mb: 1 }}>                
                <Typography variant="caption">Company Id</Typography>
                <TextField disabled variant="outlined" size="small" fullWidth value={companyInfo.companyId || ''} />
              </Box>
              <Box sx={{ mb: 1 }}>
                <Typography variant="caption">Web Site</Typography>
                <TextField name="webSite"  variant="outlined" size="small" fullWidth
                 value={companyInfo.webSite || ''} onChange={(e) => handleInputChange(e, 'companyInfo')} />
              </Box>
              <Box sx={{ mb: 1 }}>
                <Typography variant="caption">Company Name</Typography>
                <TextField name="companyName"  variant="outlined" size="small" fullWidth 
                value={companyInfo.companyName || ''} onChange={(e) => handleInputChange(e, 'companyInfo')} />
              </Box>
              <Box sx={{ mb: 1 }}>
                <Typography variant="caption">Address</Typography>
                <TextField name="address"  variant="outlined" size="small" fullWidth multiline rows={6}
                  value={companyInfo.address || ''} onChange={(e) => handleInputChange(e, 'companyInfo')} />
              </Box>

              <Box sx={{ mb: 1 }}>
                <Typography style={{color:'darkred'}} variant="caption">Environment</Typography>
                <TextField name="environment"  variant="outlined" size="small" fullWidth 
                value={companyInfo.environment || ''} onChange={(e) => handleInputChange(e, 'companyInfo')} />
              </Box>

              <Box sx={{ mb: 1 }}>
                <Typography variant="caption">Home Link</Typography>
                <TextField name="homeLink"  variant="outlined" size="small" fullWidth 
                value={companyInfo.homeLink || ''} onChange={(e) => handleInputChange(e, 'companyInfo')} />
              </Box>
              <Box sx={{ mb: 1 }}>
                <Typography variant="caption">Login Page</Typography>
                <TextField name="loginPage"  variant="outlined" size="small" fullWidth 
                value={companyInfo.loginPage || ''} onChange={(e) => handleInputChange(e, 'companyInfo')} />
              </Box>

              <Box sx={{ mb: 1 }}>
                <Typography variant="caption">Reset Token Endpoint</Typography>
                <TextField name="resetTokenEndpoint"  variant="outlined" size="small" fullWidth value={companyInfo.resetTokenEndpoint || ''} onChange={(e) => handleInputChange(e, 'companyInfo')}/>
              </Box>
              <Box sx={{ mb: 1 }}>
                <Typography variant="caption">Register Token Endpoint</Typography>
                <TextField name="registerTokenEndpoint"   variant="outlined" size="small" fullWidth
                 value={companyInfo.registerTokenEndpoint || ''}
                  onChange={(e) => handleInputChange(e, 'companyInfo')} />
              </Box>
             
              <Box sx={{ mb: 1 }}>
                <Typography variant="caption">Expiration In Minutes</Typography>
                <TextField name="expirationInMinutes"   variant="outlined" size="small" fullWidth type="number"
                 value={companyInfo.expirationInMinutes || ''}
                  onChange={(e) => handleInputChange(e, 'companyInfo')} />
 
              </Box>
              <Box sx={{ mb: 1 }}>
                <Typography variant="caption" sx={{ fontWeight: 'bold',background:'yellow' }}>Google-Api-Key</Typography>
                <TextField name="googleApiKey"   variant="outlined" size="small" fullWidth autoComplete='off' 
                 value={companyInfo.googleApiKey || ''}
                  onChange={(e) => handleInputChange(e, 'companyInfo')} />
 
              </Box>

              <Box sx={{ mb: 1 }}>
                <Typography variant="caption" sx={{ fontWeight: 'bold' ,background:'yellow'}}>Google-Map-ID</Typography>
                <TextField name="googleMapID"   variant="outlined" size="small" fullWidth autoComplete='off'
                 value={companyInfo.googleMapID || ''}
                  onChange={(e) => handleInputChange(e, 'companyInfo')} />
 
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h5" gutterBottom>Email Configuration</Typography>
            <Box display="flex" flexDirection="column">
            <Box sx={{ mb: 2 }}>
                
                <Typography variant="caption">Email Id</Typography>
                <TextField disabled variant="outlined" size="small" 
                fullWidth value={emailConfig.emailId || ''} />

              </Box>
              <Box sx={{ mb: 2 }}>
                <Typography variant="caption">Sender Email</Typography>
                <TextField name="senderEmail"   variant="outlined" size="small" fullWidth
                 value={emailConfig.senderEmail || ''}
                  onChange={(e) => handleInputChange(e, 'emailConfig')} />

              </Box>
              <Box sx={{ mb: 2 }}>
                <Typography variant="caption">Sender Password</Typography>
                <TextField name="senderPassword"   variant="outlined" size="small" fullWidth
                 value={emailConfig.senderPassword || ''}
                  onChange={(e) => handleInputChange(e, 'emailConfig')} />

              </Box>
              <Box sx={{ mb: 2 }}>
                <Typography variant="caption">SMTP Server</Typography>
                <TextField name="smtpServer"   variant="outlined" size="small" fullWidth
                 value={emailConfig.smtpServer || ''}
                  onChange={(e) => handleInputChange(e, 'emailConfig')} />


              </Box>
              <Box sx={{ mb: 2 }}>
                <Typography variant="caption">SMTP Port</Typography>
                <TextField name="smtpPort"  variant="outlined" size="small" fullWidth type="number"
                 value={emailConfig.smtpPort || ''}
                  onChange={(e) => handleInputChange(e, 'emailConfig')} />


              </Box>
              </Box>
       
              
            
          </Paper>
          
          <Box
        sx={{
          width: '95%',
          display: 'flex',
          justifyContent: 'flex-start',
          mt: 1,
        }}
      >
        <Button  disabled={isSaving} variant="contained" startIcon={<SaveIcon />} sx={{ mt: 10, alignSelf: 'center' }}
         onClick={saveConfigurations}>
        Save
      </Button>
      </Box>
          
        </Grid>
      </Grid>

      
    </Box>
  );
};
