import React, { useEffect, useState } from 'react';
import './HelpDocuments.css'; 
import { Get } from "../../Common/WebRequest";  
import { extractFileName, getBaseUrl, getStorageItem } from '../../Common/Utility';
import { apiUrl } from '../../config';
import { Typography } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const HelpDocuments = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [manualPdf, setManualPdf] = useState(null); 
  const [manualAdminPdf, setManualAdminPdf] = useState(null); 
  const [technicalManualPdf, setTechnicalManualPdf] = useState(null);
  
  useEffect(() => {
    (async () => {
      let isAdminVar = await getStorageItem("login", "isAdmin");
      setIsAdmin(isAdminVar );
    })();
  }, [isAdmin]);

  useEffect(() => {
    getHelpDocument();
  }, [isAdmin]); 

  const getHelpDocument = async () => {
    setLoading(true);
    try {
      const response = await Get('user/UserManual');
      if(response.isSuccess){
        setManualPdf(response.result);
      }
      if(isAdmin){
        const responseAdmin = await Get('user/UserManualAdmin');
        if(responseAdmin.isSuccess){
          setManualAdminPdf(responseAdmin.result);
        }
      }
      if(isAdmin){
        const responseTechnicalAdmin = await Get('user/UserTechnicalManual');
        if(responseTechnicalAdmin.isSuccess){
          setTechnicalManualPdf(responseTechnicalAdmin.result);
        }
      }
    } catch (error) {
      console.error('Failed to fetch help documents:', error);
    } finally {
      setLoading(false);
    }
  };

  const OpenLink = (filePath) => {
    var fullPdfPath = getBaseUrl(apiUrl) + "/" + filePath;
    window.open(fullPdfPath, '_blank');
  };

  return (
    <div>
      {loading ? (
        <button className="fetch-button" disabled>
          Loading...
        </button>
      ) : (
        <>
          {manualPdf && (
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '30px', marginBottom: '10px' }}>
              <PictureAsPdfIcon style={{ color: 'red', marginLeft: '10px', marginRight: '10px' }} />
              <Typography
                component="p"
                variant="body1"
                sx={{ 
                  color: 'darkblue', 
                  cursor: 'pointer',
                  '&:hover': {
                    textDecoration: 'underline',
                  }
                }}
                onClick={() => OpenLink(manualPdf)}
              >
                {extractFileName(manualPdf)}
              </Typography>
            </div>
          )}
          {isAdmin && manualAdminPdf && (
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '30px', marginBottom: '10px' }}>
              <PictureAsPdfIcon style={{ color: 'red', marginLeft: '10px', marginRight: '10px' }} />
              <Typography
                component="p"
                variant="body1"
                sx={{ 
                  color: 'darkblue', 
                  cursor: 'pointer',
                  '&:hover': {
                    textDecoration: 'underline',
                  }
                }}
                onClick={() => OpenLink(manualAdminPdf)}
              >
                {extractFileName(manualAdminPdf)}
              </Typography>
            </div>
          )}
        </>
      )}

      {isAdmin && technicalManualPdf && (
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '30px', marginBottom: '10px' }}>
          <PictureAsPdfIcon style={{ color: 'red', marginLeft: '10px', marginRight: '10px' }} />
          <Typography
            component="p"
            variant="body1"
            sx={{ 
              color: 'darkblue', 
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'underline',
              }
            }}
            onClick={() => OpenLink(technicalManualPdf)}
          >
            {extractFileName(technicalManualPdf)}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default HelpDocuments;
