import React, { useState } from 'react';
import { TextField, Button, Typography } from '@mui/material';
import { Put } from '../../../Common/WebRequest';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import PasswordUpdatedDialog from './PasswordUpdatedDialog';

const UpdatePassword = ({ userId,isPasswordMustChange }) => {
  const [error, setError] = useState('');
  const[success,setSuccess]= useState('')
  const [dialogOpen, setDialogOpen] = useState(false);
  const [passwordInfo, setPasswordInfo] = useState({
    userId: userId,
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [validationErrors, setValidationErrors] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswordInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    // Clear validation error for the field being edited
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };

  const handlePasswordSave = async () => {
    if(userId<=0)
    {
      setError("Falsch UserId.")
      return;
    }
    setValidationErrors({});
    setError('');
    setSuccess('')
    // Validate password fields
    const isValid = validatePassword();
    if (!isValid) {
      return;
    }

    console.log('Password updated:', passwordInfo);
    var res = await Put("User/UserPassword",userId, passwordInfo)
    if(res.isSuccess)
    {
        setSuccess("Passwort erfolgreich aktualisiert.")
        if(isPasswordMustChange)
        {
          setDialogOpen(true);
           
        }
    }
    else 
    {
        setError(res.Error)
    }
  };

  const handleClose = () => {
    setDialogOpen(false);
  };
  const validatePassword = () => {
    const { currentPassword, newPassword, confirmPassword } = passwordInfo;
    const currentPasswordValid = !!currentPassword.trim();
    const passwordsMatch = newPassword === confirmPassword;
    const newPasswordValid = newPassword.length >= 6;
    const containsDigit = /\d/.test(newPassword);
    const newPasswordNotSameAsCurrent = newPassword !== currentPassword;
  
    setValidationErrors({
      currentPassword: !currentPasswordValid,
      newPassword: !(newPasswordValid && containsDigit && newPasswordNotSameAsCurrent),
      confirmPassword: !passwordsMatch,
      newPasswordSameAsCurrent: !newPasswordNotSameAsCurrent,
    });
  
    return currentPasswordValid && newPasswordValid && passwordsMatch && newPasswordNotSameAsCurrent;
  };
  

  return (
    <>
    <div  style={{ padding: "50px" }}>
            {success && (
              <Typography variant="body2" sx={{ color: 'green', backgroundColor: '#e8f5e9', padding: '8px', borderRadius: '4px', marginBottom: '16px' }}>
                <CheckCircleIcon sx={{ marginRight: '8px' }} />
                  {success}
                </Typography>
                )}

                {error && (
                <Typography variant="body2" sx={{ color: 'red', backgroundColor: '#ffebee', padding: '8px', borderRadius: '4px', marginBottom: '16px' }}>
                    <ErrorIcon sx={{ marginRight: '8px' }} />
                    {error}
                </Typography>
                )} 

      <TextField
        id="currentPassword"
        autoFocus={isPasswordMustChange }
        name="currentPassword"
        autoComplete="off"
        label="Aktuelles Passwort"
        type="password"
        value={passwordInfo.currentPassword}
        onChange={handleChange}
        required
        fullWidth
        margin="normal"
        error={validationErrors.currentPassword || isPasswordMustChange==="1"}
        helperText={validationErrors.currentPassword && 'Aktuelles Passwort ist erforderlich.'}
      />
        <TextField
        name="newPassword"
        label="Neues Kennwort"
        type="password"
        autoComplete="off"
        value={passwordInfo.newPassword}
        onChange={handleChange}
        required
        fullWidth
        margin="normal"
        error={validationErrors.newPassword || validationErrors.newPasswordSameAsCurrent}
        helperText={

          validationErrors.newPasswordSameAsCurrent 
            ? 'Das neue Passwort darf nicht mit dem aktuellen Passwort übereinstimmen.' 
            :
          validationErrors.newPassword 
            ? 'Passwort: Mindestens 6 Zeichen lang, mit mindestens einer Ziffer.' 
             
            : ''
        }
        inputProps={{ minLength: 6 }}
      />
      <TextField
        name="confirmPassword"
        label="Passwort bestätigen"
        type="password"
        autoComplete="off"
        value={passwordInfo.confirmPassword}
        onChange={handleChange}
        required
        fullWidth
        margin="normal"
        error={validationErrors.confirmPassword}
        helperText={validationErrors.confirmPassword && 'Passwörter stimmen nicht.'}
        inputProps={{ minLength: 6 }}
       
      />
      <Button variant="contained" color="primary" style={{ marginTop: "10px" }} onClick={handlePasswordSave}>
        Speichern
      </Button>
    </div>
    <div>
      <PasswordUpdatedDialog open={dialogOpen} handleClose={handleClose} />
    </div>
    </>
  );
};

export default UpdatePassword;
