import React from "react";
import { Container, Typography, Paper, Button } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Link } from "react-router-dom";

const PasswordResetOk = () => {
  return (
    <Container component="main" maxWidth="xs">
      <Paper
        elevation={3}
        style={{
          padding: "2em",
          marginTop: "8vh",
          textAlign: "center",
          borderRadius: "12px",
        }}
      >
        <Typography variant="h5" gutterBottom color="success">
          Passwort erfolgreich zurückgesetzt
        </Typography>
        <br />
        <CheckCircleOutlineIcon
          fontSize="large"
          style={{
            verticalAlign: "middle",
            marginRight: "8px",
            color: "green",
          }}
        />
        <br />
        <br />
        <Typography paragraph>
          Ihr Passwort wurde erfolgreich zurückgesetzt.
        </Typography>
        <Typography paragraph style={{ fontSize: "1.2rem", lineHeight: "1.6" }}>
          Bitte verwenden Sie Ihr neues Passwort, um sich anzumelden.
        </Typography>
        <br />
        <br />
        <Typography
          paragraph
          style={{ fontStyle: "italic", color: "darkgray" }}
        >
          * Für weitere Fragen wenden Sie sich bitte an den Administrator.
        </Typography>
        <br />
        <br />
        <Button
          component={Link}
          to="/login"
          variant="contained"
          color="primary"
          sx={{
            "&:hover": {
              color: "#fff",
              backgroundColor: "#1976d2",
            },
          }}
        >
          Zur Anmeldung
        </Button>
      </Paper>
    </Container>
  );
};

export default PasswordResetOk;
