import React, { useEffect, useState } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend, Text, LabelList } from 'recharts';
import Typography from "@mui/material/Typography";
import { Paper } from "@mui/material";
 
import "./TopStats.css";
import { getToDay } from "../../../../Common/Utility";
import { Get } from "../../../../Common/WebRequest";
 

const ErrorCriticalityAnalysis = () => {
  const [loading, setLoading] = useState(true);
  const [dataReport, setDataReport] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const today = getToDay();
      const startOfDay = `${today} 00:00`;
      const endOfDay = `${today} 23:59:59`;
      const endpoint = `SystemErrorAnalysis/ErrorCriticality/${startOfDay}/${endOfDay}`;
      try {
        const response = await Get(endpoint);
        if (response && response.result) {
          setDataReport(response.result);
        } else {
          console.error("Failed to fetch error criticality data.");
        }
      } catch (error) {
        console.error("Error fetching error criticality data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (typeof (dataReport.criticality) === "undefined"
    || typeof (dataReport.errorCount) === "undefined"
     || dataReport.criticality === null 
     || dataReport.errorCount === null
    || dataReport.criticality === 0
    ){
    return <div> </div>;
  } 
 
  const RADIAN = Math.PI / 180;
  const dataColor = [
    
    { name: 'Free', value: 3, color: '#2bad4e' },//Green
    { name: 'Low', value: 15, color: '#85e043' },//Green
    { name: 'Medium', value: 58, color: '#f2a529' }, // Med.
    //{ name: 'Hight', value: 26, color: '#f2a529' }, // High
    { name: 'Hight', value: 20, color: '#ff0010' }, // Very H.
    { name: 'Critical', value: 4, color: '#aa0029' }, // Critical
  ];
  
  const cx = 260;
  const cy = 150;
  const iR = 50;
  const oR = 120;
 

  const needle = (value, data, cx, cy, iR, oR, color) => {
    let total = 0;
    data.forEach((v) => {
      total += v.value;
    });
    const ang = 180.0 * (1 - value / total);
    const length = (iR + 2 * oR) / 3;
    const sin = Math.sin(-RADIAN * ang);
    const cos = Math.cos(-RADIAN * ang);
    const r = 5;
    const x0 = cx + 5;
    const y0 = cy + 5;
    const xba = x0 + r * sin;
    const yba = y0 - r * cos;
    const xbb = x0 - r * sin;
    const ybb = y0 + r * cos;
    const xp = x0 + length * cos;
    const yp = y0 + length * sin;
  
    return [
      <circle key="needle-circle" cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
      <path key="needle-path" d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`} stroke="none" fill={color} />,
    ];
  };
  
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip" style={{ backgroundColor: "#fff", padding: "5px", border: "1px solid #ccc" }}>
         <p className="label">Error Count : {dataReport.errorCount}</p> {/* Display errorCount */}
          <p className="value">Criticality: {dataReport.criticality}%</p> {/* Display criticality value */}
        </div>
      );
    }
    return null;
  };
  return (

    <Paper elevation={3} className="top-container">
      <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', color: '#4b4b4b'}}>
      Fehlerquote heute
      </Typography>
      <div className="chart-container">
        <ResponsiveContainer width="100%" height={300}>
          <PieChart  dataKey="value"   >
            <Pie
              dataKey="value"
              startAngle={180}
              endAngle={0}
              data={dataColor}
              label={false}
              cx={cx}
              cy={cy}
              
              innerRadius={iR}
              outerRadius={oR}
              fill="#fff"
              stroke="none"
            >
              {dataColor.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip />} />
            <Legend wrapperStyle={{ fontSize: '15px', paddingBottom:'15px' }} />
            {needle(dataReport.criticality, dataColor, cx, cy, iR, oR, '#1976D2')}
          </PieChart>
        </ResponsiveContainer>
      </div>
    </Paper>
  );
}

export default ErrorCriticalityAnalysis;
