import React, { useEffect, useState } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Typography from "@mui/material/Typography";
 
import { Paper } from "@mui/material";
import "./TopStats.css"; // Make sure this CSS file is set up for responsive design
import { Get } from "../../../../Common/WebRequest";

const PaymentStateSummary = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await Get("PaymentReports/payment-state-summary");
        if (response && response.isSuccess) {
          // Optionally, map the paymentState to a readable format
          const formattedData = response.result.map(item => ({
            ...item,
            paymentState: ` ${item.paymentState}`, // Customize this mapping as needed
          }));
          setData(formattedData);
        } else {
          console.error("Failed to fetch payment state summary.");
        }
      } catch (error) {
        console.error("Error fetching payment state summary:", error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!data.length) {
    return null;
  }
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const payments = payload[0].value.toLocaleString('en-US', { minimumFractionDigits: 0 });
      const amount = payload[1].value.toLocaleString('en-US', { minimumFractionDigits: 0 });
      
      return (
        <div className="custom-tooltip" style={{ backgroundColor: "#fff", padding: "5px", border: "1px solid #ccc" }}>
          <p className="label">{`${payload[0].name} : ${payments}`}</p>
          <p className="label"><strong>{`${payload[1].name} : €${amount}`}</strong></p>

        </div>
      );
    }
    return null;
  };
  
  
  return (
    <Paper elevation={3} className="top-container">
      <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', color: '#4b4b4b'}}>
      Zusammenfassung des Zahlungsstatus
      </Typography>
      <div className="chart-container">
      <ResponsiveContainer width="100%"  height={300}>
        <BarChart data={data} 
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="paymentState" />
          <YAxis yAxisId="left" orientation="left" stroke="#82ca9d" />
          <YAxis yAxisId="right" orientation="right" stroke="#8884d8" />
          <Tooltip content={<CustomTooltip />} />
          <Legend wrapperStyle={{ fontSize: '15px', paddingBottom:'15px' }} />
          <Bar yAxisId="left" dataKey="totalPayments" fill="#82ca9d" name="Total Payments" />
          <Bar yAxisId="right" dataKey="totalAmount" fill="#8884d8" name="Total Amount" unit="€" />
        </BarChart>
      </ResponsiveContainer>
      </div>
    </Paper>
  );
};

export default PaymentStateSummary;
