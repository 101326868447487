import React, { useState } from 'react';
import {  MenuItem, Select,FormControl, Typography } from '@mui/material';
 
import UserPayments from './UserPayments';
import { blue } from '@mui/material/colors';

const WaitingPayments = ({handleButtonClick}) => {
  const [selectedYear, setSelectedYear] =  useState(new Date().getFullYear()); 
  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };  
  return (
    <div>      
       
  

      <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '10px' }}>
        <Typography
          variant="headline"
          component="h3"
          align="left"
          color={blue[500]}
        >
        Wartende Zahlungen
        </Typography>
        <FormControl>
        <select
         style={{ marginLeft: "20px" }}
          className="form-select"
          value={selectedYear}
          onChange={handleYearChange}
          
        >
          {Array.from({ length: 10 }, (_, i) => {
            const year = 2021 + i;
            return (
              <option key={year} value={year}
              style={{
                
                maxWidth: '100px',
                color: year === new Date().getFullYear() ? 'red' : 'blue',
                fontWeight: year === new Date().getFullYear() ? 'bold' : 'normal'
              }}
              >
                {year}
              </option>
            );
          })}
        </select>
      </FormControl>
      </div>
      <br/>
      <UserPayments state={[0]} handleButtonClick={handleButtonClick} currentYear={selectedYear}/>
      
    </div>
  );
};

export default WaitingPayments;
