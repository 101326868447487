import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { GridRowModes, GridActionsCellItem, DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import { useEffect } from "react";
import { Delete, Get, Post, Put } from "../../../../Common/WebRequest";
import DeleteConfirmation from "../../../../components/DeleteConfirmation";
import EditToolbar from "./EditToolbar";
import { getStorageItem, getValue, slice } from "../../../../Common/Utility";
import UserDialog from "../../Management/ControlPanel/UserManagement/UserLogins/UserDialog";
EditToolbar.propTypes = {
  setRowModesModel: PropTypes.func.isRequired,
  setPartnersData: PropTypes.func.isRequired,
  handleAddUpdateUser: PropTypes.func.isRequired,
  fieldToFocus: PropTypes.any,
  exportFileName: PropTypes.any,
};

export default function PartnersGrid() {
  const [error, setError] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [UserId, setUserId] = useState(0);
  const [partnersData, setPartnersData] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [reload, setReload] = useState(false);
  const [lastSavedUserId, setLastSavedUserId] = useState(null);
  const [selectionModel, setSelectionModel] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [userIdCreate, setUserIdCreate] = useState(0);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  let fieldToFocus = "fullName";
  let exportFileName = "Users";

  useEffect(() => {
    if (lastSavedUserId !== null && lastSavedUserId > 0) {
      selectRowByUserId(lastSavedUserId);
      console.log("Selected row : " + lastSavedUserId);
    }
  }, [partnersData]);
  useEffect(() => {
    (async () => {
      let userIdCreateValue = await getStorageItem("login", "userId");
      if (getValue(userIdCreateValue) === 0) {
        setError("User create Id = 0");
        return;
      }
      setUserIdCreate(userIdCreateValue);

      let res = await Get("User");
      let regions = await Get("Region/Permission-Users");

      if (res.isSuccess) {
        setPartnersData(res.result);
        setRegionData(regions.result);
      } else if (res.Status === 404) {
        setError(""); // no results -->  keep empty
      } else {
        setError(res.Status + "/" + res.Error);
      }
    })();
  }, [reload]);

  const [type, setType] = useState(null);

  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);

  const showDeleteModal = (type, userId) => {
    setType(type);
    setUserId(userId);

    setDeleteMessage(
      `Möchten Sie „${
        partnersData.find((x) => x.userId === userId).fullName
      }“ wirklich löschen?`
    );

    setDisplayConfirmationModal(true);
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  //---------
  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;

    //console.log("Cell lost focus / exit  edit ==> ", params, event);
    //console.log("event.target.value :: ******** ", params, event.target.value);
  };

  function selectRowByUserId(userId) {
    if (!partnersData || partnersData.length === 0) {
      return;
    }

    const selectedRow = partnersData.find((r) => r.userId === userId);
    if (selectedRow) {
      handleOnRowClick({ row: selectedRow });
    } else {
      console.log("Error: Row not found for userId:", userId);
    }
  }
  function handleOnRowClick(params) {
    const clickedRow = params.row;
    if (clickedRow) {
      setSelectionModel(clickedRow.userId);

      setLastSavedUserId(0);
    }
  }
  const handleEditClick = (userId) => () => {
    setSuccessMsg("");
    setRowModesModel({
      ...rowModesModel,
      [userId]: { mode: GridRowModes.Edit, fieldToFocus: "fullName" },
    });
  };
  // #### save #######################

  async function DoSaveApi(newRow, updatedRow) {
    let res = "";
    let isSuccess = false;
    setError("");
    setSuccessMsg("");
    setPartnersData(
      partnersData.map((row) =>
        row.userId === newRow.userId ? updatedRow : row
      )
    );
    if (updatedRow.userId === 0) {
      updatedRow.isNew = true;
      updatedRow.userIdCreate = userIdCreate;
      res = await Post("User", updatedRow);
      isSuccess = res.isSuccess;
    } else {
      newRow.userIdCreate = userIdCreate;
      res = await Put("User", updatedRow.userId, newRow);
      isSuccess = res.isSuccess;
    }
    if (isSuccess) {
      let params = {};
      params.row = res.result;
      setReload(!reload);
      handleOnRowClick(params);

      updatedRow.isNew
        ? setSuccessMsg("Der neue Benutzer wurde hinzugefügt.")
        : setSuccessMsg("Der Benutzer wurde aktualisiert.");
    } else {
      setError(res.Status + "/" + res.Error);
      setReload(!reload);
      console.log("Error: ##### ", res.Error);
    }
  }
  const handleDeleteClick = (userId) => () => {
    showDeleteModal("", userId);
  };

  async function doDeleteApi() {
    setError("");
    setSuccessMsg("");
    let res = await Delete("user", UserId);
    if (!res.isSuccess) {
      setError(res.Status + "/" + res.Error);
      console.log(
        "Error:",
        res.Status + "/" + res.Error + "/" + res.ErrorDetail
      );
    } else {
      setSuccessMsg("Die User wurde erfolgreich gelöscht.");
      setReload(!reload);
    }
  }
  const handleAfterDeleteClick = () => {
    setDisplayConfirmationModal(false);
    doDeleteApi();
  };

  const handleCancelClick = (userId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [userId]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = partnersData.find((row) => row.userId === userId);
    if (editedRow.isNew) {
      setPartnersData(partnersData.filter((row) => row.userId !== userId));
    }
  };
  const handleError = (err) => {
    console.log("handleError");

    console.log(err);
    setError(err.message);
  };

  const handleSaveClick = (userId) => async (event) => {
    event.stopPropagation();
    setRowModesModel({
      ...rowModesModel,
      [userId]: { mode: GridRowModes.View },
    });
  };
  async function processRowUpdate(newRow) {
    console.log("new user:", newRow);
    const updatedRow = { ...newRow, isNew: false };
    DoSaveApi(newRow, updatedRow);

    return updatedRow;
  }

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  async function handleOnRowDoubleClick(params, event) {
    if (params.row) {
      setUserId(params.row.userId);
      handleAddUpdateUser(params.row.userId);
    }
  }

  /// Add Update Open Teacher Dialog
  const handleOpenDialog = () => {
    //setReload(!reload);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleSaveUserData = async (userIdSaved) => {
    setReload(!reload);
    console.log("--> user id saved: " + userIdSaved);
    setLastSavedUserId(userIdSaved);
  };
  async function handleAddUpdateUser(userId) {
    setError("");
    setSuccessMsg("");
    if (userId === 0) {
      setUserId(0);
      handleOpenDialog();
    } else if (userId > 0) {
      handleOpenDialog();
    }
  }
  const columns = [
    { field: "userId", headerName: "ID", width: 80, editable: false },

    {
      field: "fullName",
      headerName: "Name *",
      type: "text",
      editable: true,
      flex: 1,
      minWidth: 200,
    },
    {
      field: "street",
      headerName: "Straße",
      type: "text",
      editable: true,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "zip",
      headerName: "PLZ",
      width: 70,
      type: "number",
      editable: true,
      valueFormatter: function (params) {
        if (params.value === 0) return "";
        return "" + params.value;
      },
    },
    {
      field: "regionId",
      headerName: "Region *",
      type: "singleSelect",
      editable: true,
      width: 130,
      getOptionValue: (region) => region?.regionId,
      getOptionLabel: (region) => region?.regionName,
      valueOptions: regionData /* loaded regions from GET API*/,
      valueFormatter: function (params) {
        if (regionData) {
          let region = regionData.find(
            (region) => region.regionId === params.value
          );
          if (typeof region?.regionName !== "undefined")
            return region.regionName;
        }
        // return "";
      },
    },
    {
      field: "iban",
      headerName: "IBAN",
      type: "text",
      editable: true,
      width: 220,
      renderCell: (params) => (
        <div
          style={{
            backgroundColor: "#edf4fb",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            paddingLeft: "8px",
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "phoneNumber",
      headerName: "Telefon",
      type: "text",
      editable: true,
      width: 120,
    },
    {
      field: "email",
      headerName: "E-Mail",
      type: "text",
      editable: true,

      minWidth: 250,
    },
    {
      field: "isActive",
      headerName: "Aktiv",
      width: 50,
      type: "boolean",
      editable: true,
      filterable: true,
    },
    {
      field: "hasLoggedIn",
      headerName: "Logged-In",
      width: 100,
      type: "boolean",
      editable: false,
      filterable: true,
      renderCell: (params) => {
        if (!params.row.hasLoggedIn) {
          return (
            <ReportProblemOutlinedIcon color="error" /> // Yellow or red exclamation for inactive rows
          );
        }
        return <CheckCircleIcon color="success" />; // Green checkmark for valid rows
      },
    },
    {
      field: "passwordChanged",
      headerName: "Pass-Chg",
      width: 100,
      type: "boolean",
      editable: false,
      filterable: true,
      renderCell: (params) => {
        if (!params.row.passwordChanged) {
          return (
            <ReportProblemOutlinedIcon color="error" /> // Yellow or red exclamation for inactive rows
          );
        }
        return <CheckCircleIcon color="success" />; // Green checkmark for valid rows
      },
    },
    {
      field: "Validation",
      headerName: "Valid",
      width: 100,
      type: "boolean",
      filterable: true,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        if (
          !params.row.email ||
          !params.row.iban ||
          params.row.zip <= 0 ||
          !params.row.regionId ||
          !params.row.street ||
          !params.row.fullName
        ) {
          return false;
        }
        return true;
      },
      renderCell: (params) => {
        if (params.value) {
          return <CheckCircleIcon color="success" />;
        } else {
          return <ErrorOutlineIcon color="error" />;
        }
      },
    },

    {
      field: "latitude",
      headerName: "Address-Map",
      width: 100,
      type: "boolean",
      editable: false,
      renderCell: (params) => {
        if (!params.row.longitude || !params.row.latitude) {
          return (
            <ReportProblemOutlinedIcon color="error" /> // Yellow or red exclamation for inactive rows
          );
        } else {
          return <CheckCircleIcon color="success" />;
        }
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        //console.log("partnersData ", partnersData);

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: 800,
        width: "95%",
      }}
    >
      <br></br>
      <h2>Partner</h2>
      <br></br>
      {error && error.length > 0 && !error.includes("No Results") && (
        <div className="alert alert-danger" role="alert">
          {slice(error, 200, "...")}
        </div>
      )}

      {error.length === 0 && successMsg.length > 0 && (
        <div className="alert alert-success" role="alert">
          {slice(successMsg, 100, "...")}
        </div>
      )}
      <UserDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        onSave={handleSaveUserData}
        reload={reload}
        IsForPermission={false}
        UserId={UserId}
      />
      <br></br>
      <Box
      // sx={{
      //   width: "100%",
      //   height: "700px",
      //   "& .Mui-even": {
      //     backgroundColor: "#f3f7ff",
      //   },
      //   "& .Mui-odd": {
      //     backgroundColor: "#EEEEEE",
      //   },
      //   "& .MuiDataGrid-cell:hover": {
      //     backgroundColor: "#80b3ff",
      //   },
      //   "&& .MuiDataGrid-row:hover": {
      //     backgroundColor: "#cce0ff",
      //     /*  "@media (hover: none)": {
      //       backgroundColor: "transparent",
      //     },*/
      //     // color: "red"
      //   },
      // }}
      >
        <div style={{ height: 680, width: "90%" }}>
          <DataGrid
            rowSelection={false}
            getRowClassName={(params) =>
              params.row.userId === selectionModel && !params.row.isActive
                ? "inactive-row-selected"
                : params.row.userId === selectionModel
                ? "Mui-selected"
                : params.row.isAdmin
                ? "admin-row"
                : !params.row.isActive
                ? "inactive-row"
                : ""
            }
            density="compact"
            rows={partnersData}
            getRowId={(row) => row.userId}
            columns={columns}
            editMode="row"
            rowsPerPageOptions={5}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 100, page: 0 },
              },
              filter: {
                filterModel: {
                  items: [
                    { field: "fullName", operator: "contains", value: "" },
                  ],
                },
              },
            }}
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            onRowDoubleClick={handleOnRowDoubleClick}
            onRowClick={handleOnRowClick}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleError}
            sx={{
              cursor: "pointer", // Applies cursor: pointer to the entire DataGrid
            }}
            slots={{
              toolbar: EditToolbar,
            }}
            slotProps={{
              toolbar: {
                setPartnersData,
                setRowModesModel,
                handleAddUpdateUser,
                fieldToFocus,
                exportFileName,
              },
            }}
          />
        </div>
      </Box>

      <DeleteConfirmation
        showModal={displayConfirmationModal}
        confirmModal={handleAfterDeleteClick}
        hideModal={hideConfirmationModal}
        type={type}
        userId={UserId}
        message={deleteMessage}
      />
    </Box>
  );
}
