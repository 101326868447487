// MonthlyTablePage.jsx
import React, { useState } from "react";
import MonthlyTable from "./MonthlyTable";
import { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { blue } from "@mui/material/colors";
import { Box, FormControl, Typography } from "@mui/material";

import {
  getStorageItem,
  getValue,
  setStorageItem,
} from "../../../../Common/Utility";
import DropDownRegion from "../../../../components/DropDown/Region/DropDownRegion";
import { Get } from "../../../../Common/WebRequest";

const MonthlyTablePage = () => {
  const navigate = useNavigate();
  const [reportData, setReportData] = useState([]);
  const [error, setError] = useState("");
  const [regionId, setRegionId] = useState(-1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  let withAll = true;

  const [isReadingFromStorage, setIsReadingFromStorage] = useState(true);

  useEffect(() => {
    async function loadStorageData() {
      try {
        const yearValue = await getStorageItem("payment", "year-monthly-table");
        const regionIdValue = await getStorageItem("payment", "regionId");

        const storedYear = getValue(yearValue);
        let storedRegionId = getValue(regionIdValue);

        // Adjusting storedRegionId if it equals 0
        if (storedRegionId === 0) {
          storedRegionId = -1;
        }

        if (storedYear > 0) setSelectedYear(storedYear);
        setRegionId(storedRegionId);
      } catch (error) {
        setError("Error reading local storage.");
      } finally {
        setIsReadingFromStorage(false);
      }
    }

    loadStorageData();
  }, []);

  useEffect(() => {
    async function generatePaymentReport() {
      if (isReadingFromStorage) return;
      if (selectedYear <= 0) {
        setError(`Year ${selectedYear} is incorrect.`);
        return;
      }

      const res = await Get(
        `Payment/GeneratePaymentReport/${regionId}/${selectedYear}`
      );
      if (res.isSuccess) {
        setReportData(res.result);
      } else {
        if (res.Status === 403) {
          setRegionId(-1);
        } else {
          setError(`${res.Status}/${res.Error}`);
        }
      }
    }

    generatePaymentReport();
  }, [regionId, selectedYear, isReadingFromStorage]);

  async function handleRegionValue(e) {
    setRegionId(parseInt(e.target.value, 10));

    setReportData([]);
    setError("");
    await setStorageItem("payment", "regionId", e.target.value);
  }

  async function handleYearChange(e) {
    const year = parseInt(e.target.value, 10);
    setSelectedYear(year);
    setReportData([]);
    setError("");
    await setStorageItem("payment", "year-monthly-table", year.toString());
  }
  const navigateToViewPaymentPage = (navigationObj) => {
    const { paymentId } = navigationObj;
    navigate(`/view-payment/${paymentId}`);
  };

  return (
    <>
      {error && (
        <Box color="red" padding={2}>
          <Typography variant="body1">{error}</Typography>
        </Box>
      )}
      <br />
      <Box
        display="flex"
        flexDirection="row"
        overflow="auto"
        alignItems="center"
      >
        <Box>
          <Typography
            variant="headline"
            component="h3"
            align="left"
            paddingLeft={"10px"}
            color={blue[500]}
          >
            Bewilligte Zahlungen
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          sx={{ marginRight: 2 }} // Set width for Region
        >
          <Typography
            variant="subtitle1"
            color={blue[500]}
            fontWeight="Bold"
            sx={{ marginLeft: 7, marginRight: 2 }}
          >
            Region
          </Typography>
          <FormControl>
            <DropDownRegion
              permission="Permission-Payments"
              regionID={regionId}
              onChange={handleRegionValue}
              withAll={withAll}
              // onPopulate={handleOnPopulate}
            />
          </FormControl>
        </Box>
        <Box
          display="contents"
          flexDirection="row"
          alignItems="center"
          paddingLeft={10}
          sx={{ marginRight: 7 }} // Set width for Year
        >
          <Typography
            variant="subtitle1"
            color={blue[500]}
            fontWeight="Bold"
            sx={{ marginLeft: 7, marginRight: 2 }}
          >
            Jahr
          </Typography>
          <FormControl>
            <select
              className="form-select"
              value={selectedYear}
              onChange={handleYearChange}
              style={{ maxWidth: "100px" }}
            >
              {/* <option value={0}>@All</option> */}
              {/* Not possible ,since we generate a table with 12-month */}
              {Array.from({ length: 10 }, (_, i) => {
                const year = 2021 + i;
                return (
                  <option
                    key={year}
                    value={year}
                    style={{
                      color:
                        year === new Date().getFullYear() ? "red" : "black",
                      fontWeight:
                        year === new Date().getFullYear() ? "bold" : "normal",
                    }}
                  >
                    {year}
                  </option>
                );
              })}
            </select>
          </FormControl>
        </Box>
      </Box>
      <br />
      {reportData && reportData.length > 0 && reportData[0].monthlyAmounts && (
        <MonthlyTable
          data={reportData}
          navigateToViewPaymentPage={navigateToViewPaymentPage}
          currentRegionId={regionId}
          currentYear={selectedYear}
        />
      )}
    </>
  );
};

export default MonthlyTablePage;
