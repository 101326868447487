import React, { useEffect, useState } from 'react';
import { Button, Container, Paper, Typography } from '@mui/material';
import UpdatePassword from './UpdatePassword'; // Import the UpdatePassword component
import { getStorageItem } from '../../../Common/Utility';

const PasswordMustChanged = () => {
  const [shouldRenderUpdatePassword, setShouldRenderUpdatePassword] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userName, setUserName] = useState(null);
  const[isloading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchStorageItem = async () => {
      try {
        const userNameValue = await getStorageItem("login", "userName");
        const userIdValue = await getStorageItem("login", "userId");
        setUserName(userNameValue);
        console.log('Retrieved storage item:', userNameValue);
        setUserId(userIdValue); // Set your userId here
       // setShouldRenderUpdatePassword(true);
      } catch (error) {
        console.error('Error fetching storage item:', error);
      }
      finally{
        setIsLoading(false);
      }
    };

    fetchStorageItem();
  }, [userId, userName]);

  const handleUpdatePassword = () => {
    //setUserId(userId); // Set your userId here
    setShouldRenderUpdatePassword(true);
  };

  if (shouldRenderUpdatePassword) {
    return (
      <UpdatePassword userId={userId} isPasswordMustChange={true} />
    );
  }
if(isloading){
  return <div>Loading...</div>
}
  return (   
    <Container style={styles.container}>
      <Paper elevation={3} style={styles.paper}>
      <Typography variant="h4" style={styles.welcome}>
        Willkommen, {userName}
      </Typography>
      <Typography variant="body1" style={styles.intro}>
       - Sie müssen Ihr Passwort aktualisieren.       
       
      </Typography>
     
      <Button
        variant="contained"
        color="primary"
        onClick={handleUpdatePassword}
        style={styles.button}
      >
        Passwort aktualisieren
      </Button>
      </Paper>
    </Container>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'left',
    margin:'40px',
    height: '50vh',
    //backgroundColor: '#f7f7f7',
    padding: '20px',
  },
  paper: {
    padding: '20px',
    width: '100%',
    maxWidth: '800px',
    textAlign: 'center',
  },
  welcome: {
    marginBottom: '20px',
    color: '#333',
  },
  intro: {
    marginBottom: '20px',
    color: '#555',
    textAlign: 'left',
    maxWidth: '600px',
  },
  button: {
    padding: '10px 20px',
  },
};

export default PasswordMustChanged;
