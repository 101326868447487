import React, { useState } from "react"; 
import UserPayments from "./UserPayments";
import {   FormControl, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
 
const PaidOrApprovedPayments = ({handleButtonClick }) => {

const [selectedYear, setSelectedYear] =  useState(new Date().getFullYear()); 
const handleYearChange = (event) => {
  setSelectedYear(event.target.value);
  };
//Paied & Approved  {state} = {2} Or = {1}
  return (
    <div>         
      <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '10px' }}>
        <Typography
          variant="headline"
          component="h3"
          align="left"
          color={blue[500]}
        >
          Bewilligte Zahlungen
        </Typography>
        <FormControl>
        <select
          className="form-select"
          value={selectedYear}
          onChange={handleYearChange}
          style={{ marginLeft: "20px" }}
          
        >
          {Array.from({ length: 10 }, (_, i) => {
            const year = 2021 + i;
            return (
              <option key={year} value={year}
              style={{
                
                maxWidth: '100px',
                color: year === new Date().getFullYear() ? 'red' : 'blue',
                fontWeight: year === new Date().getFullYear() ? 'bold' : 'normal'
              }}
              >
                {year}
              </option>
            );
          })}
        </select>
      </FormControl>
      </div>
      <br/>
       
      <UserPayments state={[1,2]}   handleButtonClick={handleButtonClick} currentYear={selectedYear}/>            
    </div>
  );
};

export default PaidOrApprovedPayments;

